import React, { useState, useEffect } from "react";
import { ChevronDown, ChevronUp, Search, Share2 } from "lucide-react";

const shlokas = [
  {
    id: 1,
    slug: "NakaStuti",
    title: "NakaStuti",
    verses: [
      {
        "number": 1,
        "slug": "nakastuti",
        "title": "NakaStuti Verse 1",
        "text": "पांत्वस्मान् पुरुहूत-वैरि-बलवन्-मातंग-माद्यद्-घटा-कुंभोचाद्रि-विपाटनाधिक-पटु-प्रत्येक-वज्रायिताः ।\nश्रीमत्-कंठीरवास्य-प्रतत-सु-नखरा दारिताराति-दूर-प्रध्वस्त-ध्वांत-शांत-प्रवितत-मनसा भाविता नाकि वृन्दैः ॥",
        "meaning": "May the powerful, sharp, thunderbolt-like claws of the divine Kanthirava, which surpass the power of breaking mountains, destroy the enemies, and bring peace to the minds of the celestial beings.",
        "explanation": "This shloka is a prayer for protection and victory over enemies. It glorifies the divine being Kanthirava (likely a reference to a lion or a powerful deity) and invokes his power, comparing his claws to a thunderbolt. The prayer seeks the destruction of darkness and enemies, bringing calm and peace to the minds of celestial beings.",
        "wordByWord": [
          { "sanskrit": "पान्तु", "english": "pantu", "root": "पाति", "meaning": "may protect" },
          { "sanskrit": "अस्मान्", "english": "asman", "root": "अस्मद्", "meaning": "us" },
          { "sanskrit": "पुरुहूत", "english": "puruhuta", "root": "पुरु + हूत", "meaning": "Indra (the one who is invoked by many)" },
          { "sanskrit": "वैरि", "english": "vaira", "root": "वैरिन्", "meaning": "enemy" },
          { "sanskrit": "बलवत्", "english": "balavat", "root": "बल", "meaning": "powerful" },
          { "sanskrit": "मातंग", "english": "matanga", "root": "मातंग", "meaning": "elephant" },
          { "sanskrit": "माद्यद्", "english": "madyad", "root": "मद्", "meaning": "intoxicated" },
          { "sanskrit": "घटा", "english": "ghata", "root": "घटा", "meaning": "pots" },
          { "sanskrit": "कुंभ", "english": "kumbha", "root": "कुंभ", "meaning": "jars" },
          { "sanskrit": "उच्च", "english": "ucca", "root": "उच्च", "meaning": "high" },
          { "sanskrit": "अद्रि", "english": "adri", "root": "अद्रि", "meaning": "mountain" },
          { "sanskrit": "विपाटन", "english": "vipatana", "root": "वि + पाट", "meaning": "tearing apart" },
          { "sanskrit": "अधिक", "english": "adhika", "root": "अधिक", "meaning": "exceeding" },
          { "sanskrit": "पटु", "english": "patu", "root": "पटु", "meaning": "sharp" },
          { "sanskrit": "प्रत्येक", "english": "pratyeka", "root": "प्रति + एक", "meaning": "each" },
          { "sanskrit": "वज्रायिताः", "english": "vajrayitah", "root": "वज्र", "meaning": "like a thunderbolt" },
          { "sanskrit": "श्रीमत्", "english": "shrimat", "root": "श्रीमत्", "meaning": "glorious" },
          { "sanskrit": "कंठीरवास्य", "english": "kanthiravasya", "root": "कंठीरव", "meaning": "Kanthirava (a lion or powerful being)" },
          { "sanskrit": "प्रतत", "english": "pratata", "root": "प्र + तत", "meaning": "extended" },
          { "sanskrit": "सु-नखरा", "english": "su-nakhara", "root": "सु + नख", "meaning": "beautiful claws" },
          { "sanskrit": "दारिता", "english": "darita", "root": "दर्", "meaning": "torn apart" },
          { "sanskrit": "अराति", "english": "arati", "root": "अराति", "meaning": "enemy" },
          { "sanskrit": "दूर", "english": "dura", "root": "दूर", "meaning": "far away" },
          { "sanskrit": "प्रध्वस्त", "english": "pradhvasta", "root": "प्र + ध्वंस", "meaning": "destroyed" },
          { "sanskrit": "ध्वांत", "english": "dhvanta", "root": "ध्वांत", "meaning": "darkness" },
          { "sanskrit": "शांत", "english": "shanta", "root": "शम", "meaning": "calm" },
          { "sanskrit": "प्रवितत", "english": "pravittata", "root": "प्र + वितत", "meaning": "spread" },
          { "sanskrit": "मनसा", "english": "manasa", "root": "मनस्", "meaning": "mind" },
          { "sanskrit": "भाविता", "english": "bhavita", "root": "भू", "meaning": "envisioned" },
          { "sanskrit": "नाकि", "english": "naki", "root": "नक", "meaning": "celestial beings" },
          { "sanskrit": "वृन्दैः", "english": "vrindaih", "root": "वृन्द", "meaning": "groups" }
        ]
      },
      {
        "number": 2,
        "slug": "lakshmi-kant-shloka",
        "title": "NakaStuti Verse 2",
        "text": "लक्ष्मी-कांत् समंततॊऽपि कलयन् नैवेशितुस्ते समं पश्याम्युत्तम-वस्तु दूर-तरतोऽपास्तं रसॊ यॊऽष्टमः ।\nयद्-रॊषॊत्कर-दक्ष-नॆत्र-कुटिल-प्रांतॊत्थिताग्नि-स्फुरत्-खद्यॊतॊपम-विस्फुलिंग-भसिता ब्रह्मॆश-शक्रॊत्कराः ॥",
        "meaning": "I do not see anything equal to you, O Lord of Lakshmi, even though I carefully consider everything. The eighth rasa (emotion) has been rejected as being far inferior. The angry glance from your eyes, emitting sparks like fireflies, has reduced Brahma, Shiva, and Indra to ashes.",
        "explanation": "This shloka praises the supreme power of the Lord, who is the consort of Lakshmi. Despite examining everything, nothing compares to Him. The shloka also depicts the intense anger of the Lord, whose fiery glance destroys even the greatest deities.",
        "wordByWord": [
          { "sanskrit": "लक्ष्मी", "english": "lakshmi", "root": "लक्ष्मी", "meaning": "Goddess Lakshmi" },
          { "sanskrit": "कांत्", "english": "kant", "root": "कांत", "meaning": "husband or beloved" },
          { "sanskrit": "समंततः", "english": "samantatah", "root": "समंतत", "meaning": "everywhere" },
          { "sanskrit": "अपि", "english": "api", "root": "अपि", "meaning": "even" },
          { "sanskrit": "कलयन्", "english": "kalayan", "root": "कला", "meaning": "considering" },
          { "sanskrit": "न", "english": "na", "root": "न", "meaning": "not" },
          { "sanskrit": "एव", "english": "eva", "root": "एव", "meaning": "certainly" },
          { "sanskrit": "ईशितुः", "english": "ishituh", "root": "ईश", "meaning": "of the Lord" },
          { "sanskrit": "ते", "english": "te", "root": "त्वद्", "meaning": "your" },
          { "sanskrit": "समं", "english": "samam", "root": "सम", "meaning": "equal" },
          { "sanskrit": "पश्यामि", "english": "pashyami", "root": "दृश्", "meaning": "I see" },
          { "sanskrit": "उत्तम", "english": "uttama", "root": "उत्तम", "meaning": "highest" },
          { "sanskrit": "वस्तु", "english": "vastu", "root": "वस्तु", "meaning": "thing" },
          { "sanskrit": "दूर", "english": "dura", "root": "दूर", "meaning": "far" },
          { "sanskrit": "तरतः", "english": "taratah", "root": "तर", "meaning": "more" },
          { "sanskrit": "अपास्तं", "english": "apastam", "root": "अप + अस्त", "meaning": "rejected" },
          { "sanskrit": "रसः", "english": "rasah", "root": "रस", "meaning": "essence or emotion" },
          { "sanskrit": "यः", "english": "yah", "root": "य", "meaning": "which" },
          { "sanskrit": "अष्टमः", "english": "ashtamah", "root": "अष्ट", "meaning": "eighth" },
          { "sanskrit": "यद्", "english": "yad", "root": "यद्", "meaning": "which" },
          { "sanskrit": "रोष", "english": "rosha", "root": "रोष", "meaning": "anger" },
          { "sanskrit": "उत्कर", "english": "utkara", "root": "उत्कर", "meaning": "intense" },
          { "sanskrit": "दक्ष", "english": "daksha", "root": "दक्ष", "meaning": "right" },
          { "sanskrit": "नेत्र", "english": "netra", "root": "नेत्र", "meaning": "eye" },
          { "sanskrit": "कुटिल", "english": "kutila", "root": "कुटिल", "meaning": "crooked" },
          { "sanskrit": "प्रांत", "english": "pranta", "root": "प्रांत", "meaning": "corner" },
          { "sanskrit": "उत्थित", "english": "utthita", "root": "उत्था", "meaning": "emerged" },
          { "sanskrit": "अग्नि", "english": "agni", "root": "अग्नि", "meaning": "fire" },
          { "sanskrit": "स्फुरत्", "english": "sphurat", "root": "स्फुर्", "meaning": "sparking" },
          { "sanskrit": "खद्योत", "english": "khadyota", "root": "खद्योत", "meaning": "firefly" },
          { "sanskrit": "उपम", "english": "upama", "root": "उपम", "meaning": "like" },
          { "sanskrit": "विस्फुलिंग", "english": "visphulinga", "root": "विस्फुलिंग", "meaning": "spark" },
          { "sanskrit": "भसिता", "english": "bhasita", "root": "भस्म", "meaning": "reduced to ashes" },
          { "sanskrit": "ब्रह्म", "english": "brahma", "root": "ब्रह्मा", "meaning": "Brahma" },
          { "sanskrit": "ईश", "english": "isha", "root": "ईश", "meaning": "Shiva" },
          { "sanskrit": "शक्र", "english": "shakra", "root": "शक्र", "meaning": "Indra" },
          { "sanskrit": "उत्कराः", "english": "utkarah", "root": "उत्कर", "meaning": "group" }
        ]
      }]

  },
  {
    id: 2,
    slug: 'poojyaya-raghavendraya-shloka',
    title: 'Raghavendra Stotra',
    verses: [{
      "number": 1,
      "slug": "poojyaya-raghavendraya-shloka",
      "title": "Raghavendra Stotra - I",
      "text": "पूज्याय राघवेन्द्राय सत्यधर्मरताय च ।\nभजतां कल्पवृक्षाय नमतां कामधेनवे ॥",
      "meaning": "Salutations to Raghavendra, who is worshipped, devoted to truth and righteousness, like a wish-fulfilling tree for those who worship him, and like the divine cow Kamadhenu for those who bow to him.",
      "explanation": "This shloka is a prayer to Raghavendra Swami, a revered saint. It extols his virtues, comparing him to the Kalpavriksha (a mythical wish-fulfilling tree) and Kamadhenu (the divine cow that grants all desires) for those who worship and bow to him.",
      "wordByWord": [
        { "sanskrit": "पूज्याय", "english": "poojyaya", "root": "पूज्य", "meaning": "to the revered one" },
        { "sanskrit": "राघवेन्द्राय", "english": "raghavendraya", "root": "राघवेन्द्र", "meaning": "to Raghavendra" },
        { "sanskrit": "सत्य", "english": "satya", "root": "सत्य", "meaning": "truth" },
        { "sanskrit": "धर्म", "english": "dharma", "root": "धर्म", "meaning": "righteousness" },
        { "sanskrit": "रताय", "english": "rataya", "root": "रत", "meaning": "engaged in" },
        { "sanskrit": "च", "english": "cha", "root": "च", "meaning": "and" },
        { "sanskrit": "भजतां", "english": "bhajataam", "root": "भज्", "meaning": "to those who worship" },
        { "sanskrit": "कल्पवृक्षाय", "english": "kalpavrikshaya", "root": "कल्पवृक्ष", "meaning": "like the wish-fulfilling tree" },
        { "sanskrit": "नमतां", "english": "namataam", "root": "नम", "meaning": "to those who bow" },
        { "sanskrit": "कामधेनवे", "english": "kamadhenuve", "root": "कामधेनु", "meaning": "like the divine cow Kamadhenu" }
      ]
    }, {
      "number": 2,
      "slug": "durvadi-dhvanta-ravaye-shloka",
      "title": "Raghavendra Stotra - II",
      "text": "दुर्वादि ध्वान्त रवये वैष्णवेन्दीवरेन्दवे ।\nश्री राघवेन्द्र गुरवे नमोऽत्यन्त दयालवे ॥",
      "meaning": "Salutations to Guru Raghavendra, who is like the sun dispelling the darkness of ignorance, the moon among the Vaishnavas, and extremely compassionate.",
      "explanation": "This shloka praises Raghavendra Swami as the one who removes ignorance (compared to the sun removing darkness) and as a revered figure among Vaishnavas, shining like the moon. It also highlights his boundless compassion.",
      "wordByWord": [
        { "sanskrit": "दुर्वादि", "english": "durvadi", "root": "दुर्वादि", "meaning": "evil or harmful speech" },
        { "sanskrit": "ध्वान्त", "english": "dhvanta", "root": "ध्वान्त", "meaning": "darkness" },
        { "sanskrit": "रवये", "english": "ravaye", "root": "रवि", "meaning": "to the sun" },
        { "sanskrit": "वैष्णव", "english": "vaishnava", "root": "वैष्णव", "meaning": "related to Vishnu or Vaishnavas" },
        { "sanskrit": "इन्दीवर", "english": "indivara", "root": "इन्दीवर", "meaning": "lotus or best" },
        { "sanskrit": "इन्दवे", "english": "indave", "root": "इन्दु", "meaning": "to the moon" },
        { "sanskrit": "श्री", "english": "sri", "root": "श्री", "meaning": "holy or respected" },
        { "sanskrit": "राघवेन्द्र", "english": "raghavendra", "root": "राघवेन्द्र", "meaning": "Raghavendra" },
        { "sanskrit": "गुरवे", "english": "gurave", "root": "गुरु", "meaning": "to the guru" },
        { "sanskrit": "नमः", "english": "namah", "root": "नम", "meaning": "salutation" },
        { "sanskrit": "अत्यन्त", "english": "atyanta", "root": "अत्यन्त", "meaning": "extremely" },
        { "sanskrit": "दयालवे", "english": "dayalave", "root": "दयालु", "meaning": "compassionate" }
      ]
    }

    ]
  },
  {
    "id": 3,
    "slug": "om-sahanavavatu-shloka",
    "title": "Shanti Mantra - SahanaVavatu",
    verses: [{
      number: 1,
      slug: "om-sahanavavatu-shloka",
      title: "Shanti Mantra - Sahanavavatu",
      "text": "ॐ सहनाववतु । सह नौ भुनक्तु । सह वीर्यं करवावहै । तेजस्वि नावधीतमस्तु मा विद्विषावहै । ॐ शान्तिः शान्तिः शान्तिः ॥",
      "meaning": "Om, may He protect us both. May He nourish us both. May we work together with great energy. May our study be vigorous and fruitful. May we not hate each other. Om, peace, peace, peace.",
      "explanation": "This Shanti Mantra is a prayer for unity, protection, nourishment, strength, and peaceful coexistence. It is often chanted before the beginning of a study session or spiritual practice to invoke harmony and focus.",
      "wordByWord": [
        { "sanskrit": "ॐ", "english": "om", "root": "ॐ", "meaning": "the sacred sound" },
        { "sanskrit": "सहनाववतु", "english": "sahanavavatu", "root": "सह + नाव + अवतु", "meaning": "may He protect us both" },
        { "sanskrit": "सह", "english": "saha", "root": "सह", "meaning": "together" },
        { "sanskrit": "नौ", "english": "nau", "root": "नौ", "meaning": "us both" },
        { "sanskrit": "भुनक्तु", "english": "bhunaktu", "root": "भुज्", "meaning": "may He nourish" },
        { "sanskrit": "सह", "english": "saha", "root": "सह", "meaning": "together" },
        { "sanskrit": "वीर्यं", "english": "viryam", "root": "वीर्य", "meaning": "strength, energy" },
        { "sanskrit": "करवावहै", "english": "karavavahai", "root": "कृ", "meaning": "may we work" },
        { "sanskrit": "तेजस्वि", "english": "tejasvi", "root": "तेजस्", "meaning": "brilliance, vigor" },
        { "sanskrit": "न", "english": "na", "root": "न", "meaning": "our" },
        { "sanskrit": "अवधीतम्", "english": "avadhitam", "root": "अव + धी", "meaning": "study" },
        { "sanskrit": "अस्तु", "english": "astu", "root": "अस्", "meaning": "let it be" },
        { "sanskrit": "मा", "english": "ma", "root": "मा", "meaning": "may not" },
        { "sanskrit": "विद्विषावहै", "english": "vidvishavahai", "root": "विद्विष्", "meaning": "we hate each other" },
        { "sanskrit": "ॐ", "english": "om", "root": "ॐ", "meaning": "the sacred sound" },
        { "sanskrit": "शान्तिः", "english": "shantih", "root": "शान्ति", "meaning": "peace" }
      ]
    }]

  }, {
    "number": 4,
    "slug": "vyasathirtha-shloka",
    "title": "VyasaThirtha Shloka",
    verses: [{
      number: 1,
      title: "Arthikalpita Kalpoyam",
      "text": "अर्थिकल्पित कल्पोऽयं प्रत्यर्थि गजकेसरी । व्यासतीर्थ गुरुभोर्यर्थ अस्मदिष्टार्थ सिद्धये ॥",
      "slug": "arthikalpita-kalpoyam",
      "meaning": "This verse metaphorically describes Vyasa Tirtha as a lion among elephants, symbolizing his prowess in debates and spiritual knowledge. It is a prayer seeking the fulfillment of one's desires through his grace.",
      "explanation": "In this verse, Vyasa Tirtha, a prominent figure in the Dvaita tradition, is praised for his intellectual and spiritual strength. The prayer asks for the fulfillment of the devotee's desires through the blessings of Vyasa Tirtha, who is compared to a powerful lion capable of overcoming any challenge.",
      "wordByWord": [
        { "sanskrit": "अर्थिकल्पित", "english": "arthikalpita", "root": "अर्थि + कल्पित", "meaning": "conceived by necessity" },
        { "sanskrit": "कल्पः", "english": "kalpah", "root": "कल्प", "meaning": "method or way" },
        { "sanskrit": "अयम्", "english": "ayam", "root": "अयम्", "meaning": "this" },
        { "sanskrit": "प्रत्यर्थि", "english": "pratyarthi", "root": "प्रत् + अर्थि", "meaning": "opponent" },
        { "sanskrit": "गजकेसरी", "english": "gajakesari", "root": "गज + केसरी", "meaning": "lion among elephants" },
        { "sanskrit": "व्यासतीर्थ", "english": "vyasatirtha", "root": "व्यास + तीर्थ", "meaning": "Vyasa Tirtha" },
        { "sanskrit": "गुरुभोरि", "english": "gurubhoh", "root": "गुरु + भोरि", "meaning": "by the great guru" },
        { "sanskrit": "अर्थ", "english": "artha", "root": "अर्थ", "meaning": "for the purpose" },
        { "sanskrit": "अस्मद्", "english": "asmad", "root": "अस्मद्", "meaning": "our" },
        { "sanskrit": "इष्टार्थ", "english": "ishtartha", "root": "इष्ट + अर्थ", "meaning": "desired objective" },
        { "sanskrit": "सिद्धये", "english": "siddhaye", "root": "सिद्धि", "meaning": "attainment" }
      ]
    }],
  },{
    "number": 5,
    "slug": "maha-mrityunjaya-mantra",
    "title": "Maha Mrityunjaya Mantra",
    "verses": [
        {
            "number": 1,
            "title": "Maha Mrityunjaya Mantra",
            "text": "ॐ त्र्यम्बकं यजामहे\nसुगन्धिं पुष्टिवर्धनम् ।\nउर्वारुकमिव बन्धनान्\nमृत्योर्मुक्षीय मामृतात् ॥",
            "slug": "maha-mrityunjaya",
            "meaning": "We meditate on the three-eyed one (Lord Shiva), who is fragrant and nourishes all beings. May he liberate us from the bondage of worldly attachments, like a ripe cucumber is freed from its stem, and deliver us from death to immortality.",
            "explanation": "This verse is a powerful prayer to Lord Shiva, seeking his grace for liberation from the cycle of birth and death. According to Madhwa-Shastra, this mantra emphasizes the surrender to the supreme power for spiritual liberation, recognizing Lord Shiva as a powerful deity in the service of Vishnu.",
            "wordByWord": [
                { "sanskrit": "ॐ", "english": "Om", "root": "ॐ", "meaning": "the sacred sound" },
                { "sanskrit": "त्र्यम्बकं", "english": "tryambakam", "root": "त्रि + अम्बक", "meaning": "three-eyed one" },
                { "sanskrit": "यजामहे", "english": "yajamahe", "root": "यज्", "meaning": "we worship or meditate" },
                { "sanskrit": "सुगन्धिं", "english": "sugandhim", "root": "सु + गन्ध", "meaning": "fragrant" },
                { "sanskrit": "पुष्टिवर्धनम्", "english": "pushtivardhanam", "root": "पुष्टि + वर्धन", "meaning": "one who nourishes or increases well-being" },
                { "sanskrit": "उर्वारुकम्", "english": "urvarukam", "root": "उर्वा + अरुक", "meaning": "like a cucumber" },
                { "sanskrit": "इव", "english": "iva", "root": "इव", "meaning": "like, as" },
                { "sanskrit": "बन्धनात्", "english": "bandhanat", "root": "बन्धन", "meaning": "from bondage" },
                { "sanskrit": "मृत्योः", "english": "mrityuh", "root": "मृत्यु", "meaning": "from death" },
                { "sanskrit": "मुक्षीय", "english": "mukshiya", "root": "मुक्ति", "meaning": "may you free us" },
                { "sanskrit": "मा", "english": "ma", "root": "मा", "meaning": "not" },
                { "sanskrit": "अमृतात्", "english": "amritat", "root": "अमृत", "meaning": "from immortality" }
            ]
        }
    ]
},{
  "number": 6,
  "slug": "guru-smaran-shloka",
  "title": "Guru Smaran Shloka",
  "verses": [
      {
          "number": 1,
          "title": "Aapada Mouli Paryantam",
          "text": "आपदामौली पर्यन्तम् गुरूणामाकृतिं स्मरेत् । तेन विघ्नाः प्रणश्यन्ति सिध्यन्ति च मनोरथाः ॥",
          "slug": "aapada-mouli-paryantam",
          "meaning": "One should meditate on the form of the Guru from head to toe. By doing so, all obstacles are destroyed, and one's desires are fulfilled.",
          "explanation": "This verse emphasizes the importance of remembering and meditating upon the Guru's form in its entirety. According to Madhwa-Shastra, the Guru is a representative of the divine, and through devoted contemplation of the Guru, a disciple can overcome all challenges and achieve their desired goals. The Guru's grace is considered powerful in removing obstacles and granting success in endeavors.",
          "wordByWord": [
              { "sanskrit": "आपदामौली", "english": "aapadamouli", "root": "आपद् + मौलि", "meaning": "from foot to head" },
              { "sanskrit": "पर्यन्तम्", "english": "paryantam", "root": "पर्यन्त", "meaning": "up to the limit" },
              { "sanskrit": "गुरूणाम्", "english": "gurunaam", "root": "गुरु", "meaning": "of the Gurus" },
              { "sanskrit": "आकृतिम्", "english": "aakritim", "root": "आकृति", "meaning": "form" },
              { "sanskrit": "स्मरेत्", "english": "smaret", "root": "स्मृ", "meaning": "should remember" },
              { "sanskrit": "तेन", "english": "tena", "root": "तेन", "meaning": "by that" },
              { "sanskrit": "विघ्नाः", "english": "vighnah", "root": "विघ्न", "meaning": "obstacles" },
              { "sanskrit": "प्रणश्यन्ति", "english": "pranashyanti", "root": "प्र + नश्", "meaning": "are destroyed" },
              { "sanskrit": "सिध्यन्ति", "english": "sidhyanti", "root": "सिद्धि", "meaning": "are accomplished" },
              { "sanskrit": "च", "english": "cha", "root": "च", "meaning": "and" },
              { "sanskrit": "मनोरथाः", "english": "manorathah", "root": "मनस् + रथ", "meaning": "desires" }
          ]
      }
  ]
},{
  "number": 7,
  "slug": "madhva-philosophy-shloka",
  "title": "Prameya shloka",
  "verses": [
      {
          "number": 1,
          "title": "Prameya shloka",
          "text": "श्रीमन्मध्वमते हरिः परतरः सत्यं जगत्तत्त्वतो\nभेदो जीवगणा हरेरनुचराः नीचोच्चभावं गताः।\nमुक्तिर्नैजसुखानुभूतिरमला भक्तिश्च तत्साधनम्\nह्यक्षादित्रितयं प्रमाणमखिलाम्नायैकवेद्यो हरिः ॥",
          "slug": "srimad-madhva-mata",
          "meaning": "In the revered Madhva tradition, Hari (Vishnu) is supreme. The world is real, and the differences between the soul and the Supreme are true. The souls, followers of Hari, exist in varied states of spiritual evolution. Liberation is the pure experience of one's inherent bliss, and devotion is the means to attain it. The triad of perception (Pratyaksha), inference (Anumana), and scripture (Shabda) are the valid means of knowledge, and Hari is the sole subject of all the Vedas.",
          "explanation": "This verse encapsulates the core principles of Madhva philosophy, a Dvaita Vedanta school. It emphasizes the supremacy of Lord Vishnu (Hari) and the reality of the universe and the differences between the individual soul and God. The verse also highlights the hierarchical nature of souls, the importance of devotion as the means to liberation, and the triad of pramanas (sources of knowledge). The Vedas, according to Madhva, primarily reveal the glory of Hari.",
          "wordByWord": [
              { "sanskrit": "श्रीमत्", "english": "shrimat", "root": "श्री", "meaning": "revered" },
              { "sanskrit": "मध्वमते", "english": "madhvamate", "root": "मध्व + मत", "meaning": "in the doctrine of Madhva" },
              { "sanskrit": "हरिः", "english": "harih", "root": "हरि", "meaning": "Hari (Vishnu)" },
              { "sanskrit": "परतरः", "english": "paratarah", "root": "पर", "meaning": "supreme" },
              { "sanskrit": "सत्यं", "english": "satyam", "root": "सत्य", "meaning": "real" },
              { "sanskrit": "जगत्", "english": "jagat", "root": "जगत्", "meaning": "world" },
              { "sanskrit": "तत्त्वतः", "english": "tattvatah", "root": "तत्त्व", "meaning": "in essence" },
              { "sanskrit": "भेदः", "english": "bhedah", "root": "भेद", "meaning": "difference" },
              { "sanskrit": "जीवगणाः", "english": "jivaganah", "root": "जीव + गण", "meaning": "souls" },
              { "sanskrit": "हरेः", "english": "hareh", "root": "हरि", "meaning": "of Hari" },
              { "sanskrit": "अनुचराः", "english": "anucharah", "root": "अनु + चर", "meaning": "followers" },
              { "sanskrit": "नीचोच्च", "english": "nichocca", "root": "नीच + उच्च", "meaning": "low and high" },
              { "sanskrit": "भावं", "english": "bhavam", "root": "भाव", "meaning": "state" },
              { "sanskrit": "गताः", "english": "gataah", "root": "गति", "meaning": "attained" },
              { "sanskrit": "मुक्तिः", "english": "muktih", "root": "मुक्ति", "meaning": "liberation" },
              { "sanskrit": "नैजसुख", "english": "naijasukha", "root": "निज + सुख", "meaning": "inherent bliss" },
              { "sanskrit": "अनुभूतिः", "english": "anubhuti", "root": "अनुभव", "meaning": "experience" },
              { "sanskrit": "अमला", "english": "amala", "root": "अमल", "meaning": "pure" },
              { "sanskrit": "भक्तिः", "english": "bhaktih", "root": "भक्ति", "meaning": "devotion" },
              { "sanskrit": "च", "english": "cha", "root": "च", "meaning": "and" },
              { "sanskrit": "तत्", "english": "tat", "root": "तत्", "meaning": "that" },
              { "sanskrit": "साधनम्", "english": "sadhanam", "root": "साधन", "meaning": "means" },
              { "sanskrit": "ह्यक्षादि", "english": "hyakshadi", "root": "ह्य + अक्ष", "meaning": "perception, etc." },
              { "sanskrit": "त्रितयं", "english": "tritayam", "root": "त्रि + त", "meaning": "triad" },
              { "sanskrit": "प्रमाणम्", "english": "pramanam", "root": "प्रमाण", "meaning": "means of knowledge" },
              { "sanskrit": "अखिलाम्नाय", "english": "akhilamnaya", "root": "अखिल + अम्नाय", "meaning": "all the Vedas" },
              { "sanskrit": "एक", "english": "eka", "root": "एक", "meaning": "one" },
              { "sanskrit": "वेद्यः", "english": "vedyah", "root": "विद्य", "meaning": "known" },
              { "sanskrit": "हरिः", "english": "harih", "root": "हरि", "meaning": "Hari (Vishnu)" }
          ]
      }
  ]
},{
  "number": 8,
  "slug": "vadiraja-guru-shloka",
  "title": "Vadiraja Guru Shloka",
  "verses": [
      {
          "number": 1,
          "title": "Tapo Vidya Viraktyadi",
          "text": "तपो विद्या विरक्त्यादि सद्गुणो घण्टाकर्ण: श्री वादिराज गुरून् वन्दे हयग्रीव दयाश्रयम् ॥",
          "slug": "tapo-vidya-viraktyadi",
          "meaning": "I bow to the revered Guru Vadiraja, who is adorned with virtues like austerity, knowledge, and detachment. He is the one who has taken refuge in the mercy of Lord Hayagreeva.",
          "explanation": "This verse is a salutation to Vadiraja Swami, a significant saint in the Madhva tradition. It praises him for his exemplary qualities such as austerity, knowledge, and detachment. The verse also acknowledges his deep devotion to Lord Hayagreeva, an incarnation of Vishnu, who is the source of wisdom and mercy. Through this shloka, the devotee expresses reverence and seeks blessings from the Guru who embodies these divine qualities.",
          "wordByWord": [
              { "sanskrit": "तपः", "english": "tapah", "root": "तपस्", "meaning": "austerity" },
              { "sanskrit": "विद्या", "english": "vidya", "root": "विद्या", "meaning": "knowledge" },
              { "sanskrit": "विरक्त्यादि", "english": "viraktyadi", "root": "विरक्ति + आदि", "meaning": "detachment, etc." },
              { "sanskrit": "सद्गुणः", "english": "sadgunah", "root": "सत् + गुण", "meaning": "good virtues" },
              { "sanskrit": "घण्टाकर्णः", "english": "ghantakarnah", "root": "घण्टा + कर्ण", "meaning": "one with bell-shaped ears (a reference to a demon subdued by Vadiraja)" },
              { "sanskrit": "श्री", "english": "shri", "root": "श्री", "meaning": "revered" },
              { "sanskrit": "वादिराज", "english": "vadiraja", "root": "वदि + राज", "meaning": "Vadiraja (name of the Guru)" },
              { "sanskrit": "गुरून्", "english": "gurun", "root": "गुरु", "meaning": "Gurus" },
              { "sanskrit": "वन्दे", "english": "vande", "root": "वन्द्", "meaning": "I bow" },
              { "sanskrit": "हयग्रीव", "english": "hayagreeva", "root": "हयग्रीव", "meaning": "Hayagreeva (an incarnation of Vishnu)" },
              { "sanskrit": "दयाश्रयम्", "english": "dayashrayam", "root": "दया + आश्रय", "meaning": "one who has taken refuge in mercy" }
          ]
      }
  ]
},{
  "number": 10,
  "slug": "eka-sloki-ramayanam",
  "title": "Eka sloki Ramayanam",
  "verses": [
      {
          "number": 1,
          "title": "Eka Sloki Ramayanam",
          "text": "आदो राम तपोवनानु गमनं, हत्वा मृगं काञ्चनम् ।\nवैदेही हरणं, जटायुः मरणं, सुग्रीव सम्भाषणम् ।\nवालि निग्रहणं, समुद्र तरणं, लङ्का पुरीं दहनम् ।\nपश्चात् रावण कुम्भकर्ण मथनं, एतदिति रामायणम् ॥",
          "slug": "aadho-rama-thapo-vananu-gamanam",
          "meaning": "This verse summarizes the key events of the Ramayana, including Rama's journey to the forest, the killing of the golden deer, the abduction of Sita, Jatayu's death, conversations with Sugriva, the defeat of Vali, crossing the ocean, burning the city of Lanka, and the defeat of Ravana and Kumbhakarna.",
          "explanation": "The verse concisely encapsulates the major episodes in the Ramayana, highlighting Rama's trials, battles, and ultimate victory over evil forces, symbolized by Ravana and Kumbhakarna.",
          "wordByWord": [
              { "sanskrit": "आदौ", "english": "aadau", "root": "आदौ", "meaning": "at the beginning" },
              { "sanskrit": "राम", "english": "Rama", "root": "राम", "meaning": "Rama" },
              { "sanskrit": "तपोवनानु", "english": "tapovananu", "root": "तपोवन + अनु", "meaning": "towards the forest for penance" },
              { "sanskrit": "गमनं", "english": "gamanam", "root": "गमन", "meaning": "journey" },
              { "sanskrit": "हत्वा", "english": "hatva", "root": "हन्", "meaning": "killing" },
              { "sanskrit": "मृगं", "english": "mrugam", "root": "मृग", "meaning": "deer" },
              { "sanskrit": "काञ्चनम्", "english": "kanchanam", "root": "काञ्चन", "meaning": "golden" },
              { "sanskrit": "वैदेही", "english": "vaidehi", "root": "वैदेही", "meaning": "Sita" },
              { "sanskrit": "हरणं", "english": "haranam", "root": "हरण", "meaning": "abduction" },
              { "sanskrit": "जटायुः", "english": "jatayuh", "root": "जटायुः", "meaning": "Jatayu" },
              { "sanskrit": "मरणं", "english": "maranam", "root": "मरण", "meaning": "death" },
              { "sanskrit": "सुग्रीव", "english": "sugriva", "root": "सुग्रीव", "meaning": "Sugriva" },
              { "sanskrit": "सम्भाषणम्", "english": "sambhashanam", "root": "सम्भाषण", "meaning": "conversation" },
              { "sanskrit": "वालि", "english": "vali", "root": "वालि", "meaning": "Vali" },
              { "sanskrit": "निग्रहणं", "english": "nigrahanam", "root": "निग्रहण", "meaning": "subjugation" },
              { "sanskrit": "समुद्र", "english": "samudra", "root": "समुद्र", "meaning": "ocean" },
              { "sanskrit": "तरणं", "english": "taranam", "root": "तरण", "meaning": "crossing" },
              { "sanskrit": "लङ्का", "english": "lanka", "root": "लङ्का", "meaning": "Lanka" },
              { "sanskrit": "पुरीं", "english": "purim", "root": "पुरी", "meaning": "city" },
              { "sanskrit": "दहनम्", "english": "dahanam", "root": "दहन", "meaning": "burning" },
              { "sanskrit": "पश्चात्", "english": "paschat", "root": "पश्चात्", "meaning": "afterwards" },
              { "sanskrit": "रावण", "english": "ravana", "root": "रावण", "meaning": "Ravana" },
              { "sanskrit": "कुम्भकर्ण", "english": "kumbhakarna", "root": "कुम्भकर्ण", "meaning": "Kumbhakarna" },
              { "sanskrit": "मथनं", "english": "madhanam", "root": "मथन", "meaning": "churning" },
              { "sanskrit": "एतदिति", "english": "etatiti", "root": "एतत् + इति", "meaning": "thus" },
              { "sanskrit": "रामायणम्", "english": "ramayanam", "root": "रामायण", "meaning": "Ramayana" }
          ]
      }
  ]
}, {
  "number": 11,
  "slug": "hanuman-prayer-shlokas",
  "title": "Hanuman Prayer Shlokas",
  "verses": [
      {
          "number": 1,
          "title": "Manojavam Maruta Tulya Vegam",
          "text": "मनोजवम् मारुत तुल्य वेगम्, जितेन्द्रियम् बुद्धिमताम् वरिष्ठम् ।\nवातात्मजम् वानर यूथ मुख्यम्, श्रीराम दूतम् शिरसा नमामि ॥",
          "slug": "manojavam-maruta-tulya-vegam",
          "meaning": "I bow my head to Hanuman, who is as swift as the mind and the wind, who has controlled his senses, who is the most intelligent, the son of the wind god, and the chief of the monkey army. He is the divine messenger of Lord Rama.",
          "explanation": "This verse is a salutation to Hanuman, praising his qualities such as speed, intelligence, and devotion. It acknowledges his role as the divine messenger of Lord Rama and the leader of the vanaras (monkeys).",
          "wordByWord": [
              { "sanskrit": "मनोजवम्", "english": "manojavam", "root": "मनस् + जव", "meaning": "as swift as the mind" },
              { "sanskrit": "मारुत", "english": "maruta", "root": "मारुत", "meaning": "wind" },
              { "sanskrit": "तुल्य", "english": "tulya", "root": "तुल्य", "meaning": "equal to" },
              { "sanskrit": "वेगम्", "english": "vegam", "root": "वेग", "meaning": "speed" },
              { "sanskrit": "जितेन्द्रियम्", "english": "jitendriyam", "root": "जित + इन्द्रिय", "meaning": "one who has controlled his senses" },
              { "sanskrit": "बुद्धिमताम्", "english": "buddhimatam", "root": "बुद्धिमत", "meaning": "among the wise" },
              { "sanskrit": "वरिष्ठम्", "english": "varishtham", "root": "वरिष्ठ", "meaning": "the greatest" },
              { "sanskrit": "वातात्मजम्", "english": "vaataatmajam", "root": "वात + आत्मज", "meaning": "son of the wind god" },
              { "sanskrit": "वानर", "english": "vaanara", "root": "वानर", "meaning": "monkey" },
              { "sanskrit": "यूथ", "english": "yootha", "root": "यूथ", "meaning": "army" },
              { "sanskrit": "मुख्यम्", "english": "mukhyam", "root": "मुख्य", "meaning": "chief" },
              { "sanskrit": "श्रीराम", "english": "shree-raama", "root": "श्री + राम", "meaning": "Lord Rama" },
              { "sanskrit": "दूतम्", "english": "dootam", "root": "दूत", "meaning": "messenger" },
              { "sanskrit": "शिरसा", "english": "shirasaa", "root": "शिरस", "meaning": "with my head" },
              { "sanskrit": "नमामि", "english": "namaami", "root": "नमस्", "meaning": "I bow" }
          ]
      },
      {
          "number": 2,
          "title": "Buddhir Balam Yashodhairyam",
          "text": "बुद्धिर्बलं यशोधैर्यं निर्भयत्वम् अरोगता ।\nअजाड्यं वाक्पटुत्वं च हनुमत्-स्मरणात् भवेत् ॥",
          "slug": "buddhir-balam-yashodhairyam",
          "meaning": "By remembering Hanuman, one gains wisdom, strength, fame, courage, fearlessness, health, agility, and eloquence in speech.",
          "explanation": "This verse emphasizes the benefits of meditating on Hanuman, suggesting that his blessings can bestow various virtues like intelligence, strength, courage, fearlessness, and eloquence.",
          "wordByWord": [
              { "sanskrit": "बुद्धिः", "english": "buddhih", "root": "बुद्धि", "meaning": "intelligence" },
              { "sanskrit": "बलं", "english": "balam", "root": "बल", "meaning": "strength" },
              { "sanskrit": "यशः", "english": "yashah", "root": "यश", "meaning": "fame" },
              { "sanskrit": "धैर्यं", "english": "dhairyam", "root": "धैर्य", "meaning": "courage" },
              { "sanskrit": "निर्भयत्वम्", "english": "nirbhayatvam", "root": "निर्भय", "meaning": "fearlessness" },
              { "sanskrit": "अरोगता", "english": "arogata", "root": "अरोगता", "meaning": "health" },
              { "sanskrit": "अजाड्यम्", "english": "ajaadyam", "root": "अजाड्य", "meaning": "agility" },
              { "sanskrit": "वाक्पटुत्वं", "english": "vaakpatutvam", "root": "वाक् + पटु", "meaning": "eloquence in speech" },
              { "sanskrit": "च", "english": "cha", "root": "च", "meaning": "and" },
              { "sanskrit": "हनुमत्", "english": "hanumat", "root": "हनुमान्", "meaning": "Hanuman" },
              { "sanskrit": "स्मरणात्", "english": "smaranaat", "root": "स्मरण", "meaning": "by remembering" },
              { "sanskrit": "भवेत्", "english": "bhavet", "root": "भू", "meaning": "becomes" }
          ]
      }
  ]
},{
  "number": 12,
  "slug": "purnamadah-purnamidam-shanti-mantra",
  "title": "Shanti Mantra - Purnamadah Purnamidam",
  "verses": [
      {
          "number": 1,
          "title": "Purnamadah Purnamidam",
          "text": "ॐ पूर्णमदः पूर्णमिदम् पूर्णात् पूर्णमुदच्यते ।\nपूर्णस्य पूर्णमादाय पूर्णमेवावशिष्यते ॥\nॐ शान्तिः शान्तिः शान्तिः ॥",
          "slug": "purnamadah-purnamidam",
          "meaning": "Om, That is complete, This is complete, from the Complete, the Complete emerges. Taking the Complete from the Complete, the Complete remains. Om, Peace, Peace, Peace.",
          "explanation": "This Shanti Mantra is a profound expression of the concept of completeness in the universe. It emphasizes the idea that the whole (Brahman) remains whole even when it manifests into the world. Despite any apparent division or creation, the original completeness is never diminished.",
          "wordByWord": [
              { "sanskrit": "ॐ", "english": "om", "root": "ॐ", "meaning": "primordial sound" },
              { "sanskrit": "पूर्णम्", "english": "purnam", "root": "पूर्ण", "meaning": "complete" },
              { "sanskrit": "अदः", "english": "adah", "root": "अदः", "meaning": "that" },
              { "sanskrit": "पूर्णम्", "english": "purnam", "root": "पूर्ण", "meaning": "complete" },
              { "sanskrit": "इदम्", "english": "idam", "root": "इदम्", "meaning": "this" },
              { "sanskrit": "पूर्णात्", "english": "purnat", "root": "पूर्ण", "meaning": "from the complete" },
              { "sanskrit": "पूर्णम्", "english": "purnam", "root": "पूर्ण", "meaning": "complete" },
              { "sanskrit": "उदच्यते", "english": "udachyate", "root": "उद् + अच्य", "meaning": "emerges" },
              { "sanskrit": "पूर्णस्य", "english": "purnasya", "root": "पूर्ण", "meaning": "of the complete" },
              { "sanskrit": "पूर्णम्", "english": "purnam", "root": "पूर्ण", "meaning": "complete" },
              { "sanskrit": "आदाय", "english": "aadaaya", "root": "आ + दा", "meaning": "taking" },
              { "sanskrit": "पूर्णम्", "english": "purnam", "root": "पूर्ण", "meaning": "complete" },
              { "sanskrit": "एव", "english": "eva", "root": "एव", "meaning": "indeed" },
              { "sanskrit": "अवशिष्यते", "english": "avashishyate", "root": "अव + शिष्", "meaning": "remains" },
              { "sanskrit": "शान्तिः", "english": "shantih", "root": "शान्ति", "meaning": "peace" },
              { "sanskrit": "शान्तिः", "english": "shantih", "root": "शान्ति", "meaning": "peace" },
              { "sanskrit": "शान्तिः", "english": "shantih", "root": "शान्ति", "meaning": "peace" }
          ]
      }
  ]
}, {
  "number": 14,
  "slug": "sham-no-mitrah-shanti-mantra-madhwa",
  "title": "Shanti Mantra - Sham No Mitrah",
  "verses": [
    {
      "number": 1,
      "title": "Sham No Mitrah",
      "text": "ॐ शं नो मित्रः शं वरुणः।\nशं नो भवत्वर्यमा।\nशं न इन्द्रो बृहस्पतिः।\nशं नो विष्णुरुरुक्रमः।\nनमो ब्रह्मणे।\nनमस्ते वायो।\nत्वमेव प्रत्यक्षं ब्रह्मासि।\nत्वामेव प्रत्यक्षं ब्रह्म वदिष्यामि।\nऋतं वदिष्यामि।\nसत्यं वदिष्यामि।\nतन्मामवतु।\nतद्वक्तारमवतु।\nअवतु माम्।\nअवतु वक्तारम्।\nॐ शान्तिः शान्तिः शान्तिः॥",
      "slug": "sham-no-mitrah-madhwa",
      "meaning": "May Mitra, Varuna, Aryaman, Indra, Brihaspati, and Vishnu, the Supreme Lord who takes great strides, bestow auspiciousness upon us. Salutations to Brahma (the creator). Salutations to you, O Vayu (the wind god and father of Hanuman). You are the visible manifestation of Brahman (Vishnu). I shall proclaim you as the perceptible Brahman. I shall speak the eternal law. I shall speak the absolute truth. May that protect me. May that protect the speaker. May it protect me. May it protect the speaker. Om peace, peace, peace.",
      "explanation": "According to Madhwa philosophy, this Shanti Mantra is a prayer invoking various deities, who are seen as distinct entities subservient to Vishnu, the Supreme Being. The mantra emphasizes the supremacy of Vishnu (referred to here as Urukrama) and the importance of truth and righteousness. In Dvaita Vedanta, Vayu is considered a principal deity, second only to Vishnu, which explains his special mention. The threefold repetition of 'peace' at the end signifies peace in the physical, mental, and spiritual realms, all of which are distinct in Madhwa's dualistic philosophy.",
      "wordByWord": [
        { "sanskrit": "ॐ", "english": "Om", "root": "ॐ", "meaning": "The primordial sound representing Vishnu" },
        { "sanskrit": "शं", "english": "Sham", "root": "शं", "meaning": "Auspiciousness, a blessing from the Supreme" },
        { "sanskrit": "नो", "english": "No", "root": "नः", "meaning": "To us, the individual souls (jivas)" },
        { "sanskrit": "मित्रः", "english": "Mitrah", "root": "मित्र", "meaning": "Mitra, a Vedic deity subordinate to Vishnu" },
        { "sanskrit": "शं", "english": "Sham", "root": "शं", "meaning": "Auspiciousness" },
        { "sanskrit": "वरुणः", "english": "Varunah", "root": "वरुण", "meaning": "Varuna, the deity of water, subordinate to Vishnu" },
        { "sanskrit": "शं", "english": "Sham", "root": "शं", "meaning": "Auspiciousness" },
        { "sanskrit": "नो", "english": "No", "root": "नः", "meaning": "To us" },
        { "sanskrit": "भवत्व्", "english": "Bhavatv", "root": "भू", "meaning": "May become, by the grace of Vishnu" },
        { "sanskrit": "अर्यमा", "english": "Aryama", "root": "अर्यमन्", "meaning": "Aryaman, a Vedic deity subordinate to Vishnu" },
        { "sanskrit": "शं", "english": "Sham", "root": "शं", "meaning": "Auspiciousness" },
        { "sanskrit": "नः", "english": "Nah", "root": "नः", "meaning": "To us" },
        { "sanskrit": "इन्द्रो", "english": "Indro", "root": "इन्द्र", "meaning": "Indra, the king of devas, subordinate to Vishnu" },
        { "sanskrit": "बृहस्पतिः", "english": "Brihaspatih", "root": "बृहस्पति", "meaning": "Brihaspati, the guru of the devas, subordinate to Vishnu" },
        { "sanskrit": "शं", "english": "Sham", "root": "शं", "meaning": "Auspiciousness" },
        { "sanskrit": "नो", "english": "No", "root": "नः", "meaning": "To us" },
        { "sanskrit": "विष्णुः", "english": "Vishnuh", "root": "विष्णु", "meaning": "Vishnu, the Supreme Being" },
        { "sanskrit": "उरुक्रमः", "english": "Urukramah", "root": "उरुक्रम", "meaning": "One who takes great strides, referring to Vishnu's Vamana avatar" },
        { "sanskrit": "नमो", "english": "Namo", "root": "नमस्", "meaning": "Salutations" },
        { "sanskrit": "ब्रह्मणे", "english": "Brahmane", "root": "ब्रह्मन्", "meaning": "To Brahma, the creator god subordinate to Vishnu" },
        { "sanskrit": "नमस्ते", "english": "Namaste", "root": "नमस्", "meaning": "Salutations to you" },
        { "sanskrit": "वायो", "english": "Vayo", "root": "वायु", "meaning": "O Vayu, the wind god, second only to Vishnu in the Madhwa hierarchy" },
        { "sanskrit": "त्वमेव", "english": "Tvameva", "root": "त्वम् + एव", "meaning": "You alone" },
        { "sanskrit": "प्रत्यक्षं", "english": "Pratyaksham", "root": "प्रत्यक्ष", "meaning": "Visible, perceptible manifestation" },
        { "sanskrit": "ब्रह्मासि", "english": "Brahmasi", "root": "ब्रह्मन् + असि", "meaning": "You are Brahman (Vishnu)" },
        { "sanskrit": "त्वामेव", "english": "Tvameva", "root": "त्वाम् + एव", "meaning": "You alone" },
        { "sanskrit": "प्रत्यक्षं", "english": "Pratyaksham", "root": "प्रत्यक्ष", "meaning": "Visible, perceptible" },
        { "sanskrit": "ब्रह्म", "english": "Brahma", "root": "ब्रह्मन्", "meaning": "Brahman (Vishnu)" },
        { "sanskrit": "वदिष्यामि", "english": "Vadishyami", "root": "वद्", "meaning": "I shall proclaim" },
        { "sanskrit": "ऋतं", "english": "Ritam", "root": "ऋत", "meaning": "The eternal law established by Vishnu" },
        { "sanskrit": "वदिष्यामि", "english": "Vadishyami", "root": "वद्", "meaning": "I shall speak" },
        { "sanskrit": "सत्यं", "english": "Satyam", "root": "सत्य", "meaning": "The absolute truth, which is Vishnu Himself" },
        { "sanskrit": "वदिष्यामि", "english": "Vadishyami", "root": "वद्", "meaning": "I shall speak" },
        { "sanskrit": "तन्माम्", "english": "Tanmam", "root": "तत् + माम्", "meaning": "That me" },
        { "sanskrit": "अवतु", "english": "Avatu", "root": "अव्", "meaning": "May protect" },
        { "sanskrit": "तद्", "english": "Tad", "root": "तत्", "meaning": "That" },
        { "sanskrit": "वक्तारम्", "english": "Vaktaram", "root": "वक्तृ", "meaning": "The speaker" },
        { "sanskrit": "अवतु", "english": "Avatu", "root": "अव्", "meaning": "May protect" },
        { "sanskrit": "अवतु", "english": "Avatu", "root": "अव्", "meaning": "May protect" },
        { "sanskrit": "माम्", "english": "Mam", "root": "अहम्", "meaning": "Me, the individual soul distinct from Vishnu" },
        { "sanskrit": "अवतु", "english": "Avatu", "root": "अव्", "meaning": "May protect" },
        { "sanskrit": "वक्तारम्", "english": "Vaktaram", "root": "वक्तृ", "meaning": "The speaker" },
        { "sanskrit": "ॐ", "english": "Om", "root": "ॐ", "meaning": "The primordial sound representing Vishnu" },
        { "sanskrit": "शान्तिः", "english": "Shantih", "root": "शान्ति", "meaning": "Peace in the physical realm" },
        { "sanskrit": "शान्तिः", "english": "Shantih", "root": "शान्ति", "meaning": "Peace in the mental realm" },
        { "sanskrit": "शान्तिः", "english": "Shantih", "root": "शान्ति", "meaning": "Peace in the spiritual realm" }
      ]
    }
  ]
}, {
  "number": 15,
  "slug": "bhadram-karnebhih-shanti-mantra-madhwa",
  "title": "Shanti Mantra - Bhadram Karnebhih",
  "verses": [
    {
      "number": 1,
      "title": "Bhadram Karnebhih",
      "text": "ॐ भद्रं कर्णेभिः श्रुणुयाम देवाः।\nभद्रं पश्येमाक्षभिर्यजत्राः\nस्थिरैरङ्गैस्तुष्टुवाग्ंसस्तनूभिः।\nव्यशेम देवहितम् यदायुः।\nस्वस्ति न इन्द्रो वृद्धश्रवाः।\nस्वस्ति नः पूषा विश्ववेदाः।\nस्वस्ति नस्तार्क्ष्यो अरिष्टनेमिः।\nस्वस्ति नो बृहस्पतिर्दधातु॥\nॐ शान्तिः शान्तिः शान्तिः॥",
      "slug": "bhadram-karnebhih-madhwa",
      "meaning": "Om. O Devas, may we hear auspiciousness with our ears. O worshipful ones, may we see auspiciousness with our eyes. With steady limbs and bodies, may we who praise you attain the life allotted by the devas. May Indra of growing fame grant us well-being. May Pushan, the all-knowing, grant us well-being. May Tarksya (Garuda) of uninjured rim grant us well-being. May Brihaspati grant us well-being. Om. Peace, peace, peace.",
      "explanation": "In Madhwa philosophy, this mantra is understood as a prayer to Vishnu, the Supreme Being, through various devas who are considered His subordinates. The mantra seeks blessings for the proper use of our senses and faculties to perceive and understand the divine truth. It emphasizes the distinction between the individual soul (jiva) and the divine, a key tenet of Dvaita Vedanta. The repeated requests for 'svasti' (well-being) from different deities are seen as appeals to various aspects or representatives of Vishnu's power. The mantra concludes with a threefold invocation of peace, representing harmony in the physical, mental, and spiritual realms, which are considered distinct in Madhwa's dualistic philosophy.",
      "wordByWord": [
        { "sanskrit": "ॐ", "english": "Om", "root": "ॐ", "meaning": "The primordial sound representing Vishnu" },
        { "sanskrit": "भद्रं", "english": "Bhadram", "root": "भद्र", "meaning": "Auspicious, blessed by Vishnu" },
        { "sanskrit": "कर्णेभिः", "english": "Karnebhih", "root": "कर्ण", "meaning": "With ears, faculties given by Vishnu" },
        { "sanskrit": "श्रुणुयाम", "english": "Shrunuyama", "root": "श्रु", "meaning": "May we hear, perceive the divine truth" },
        { "sanskrit": "देवाः", "english": "Devah", "root": "देव", "meaning": "O Devas, celestial beings subordinate to Vishnu" },
        { "sanskrit": "भद्रं", "english": "Bhadram", "root": "भद्र", "meaning": "Auspicious, blessed by Vishnu" },
        { "sanskrit": "पश्येम", "english": "Pashyema", "root": "दृश्", "meaning": "May we see, perceive the divine form" },
        { "sanskrit": "अक्षभिः", "english": "Akshabhir", "root": "अक्षि", "meaning": "With eyes, faculties given by Vishnu" },
        { "sanskrit": "यजत्राः", "english": "Yajatrah", "root": "यज्", "meaning": "O worshipful ones, worthy of worship but inferior to Vishnu" },
        { "sanskrit": "स्थिरैः", "english": "Sthiraih", "root": "स्थिर", "meaning": "With steady, firm" },
        { "sanskrit": "अङ्गैः", "english": "Angaih", "root": "अङ्ग", "meaning": "With limbs, parts of the body" },
        { "sanskrit": "तुष्टुवांसः", "english": "Tushtuvamsah", "root": "स्तु", "meaning": "Praising, offering devotion" },
        { "sanskrit": "तनूभिः", "english": "Tanubhih", "root": "तनु", "meaning": "With bodies, vessels for the soul" },
        { "sanskrit": "व्यशेम", "english": "Vyashema", "root": "व्यश्", "meaning": "May we attain, reach" },
        { "sanskrit": "देवहितम्", "english": "Devahitam", "root": "देव + हित", "meaning": "Ordained by the devas, ultimately by Vishnu" },
        { "sanskrit": "यत्", "english": "Yat", "root": "यद्", "meaning": "Which" },
        { "sanskrit": "आयुः", "english": "Ayuh", "root": "आयुस्", "meaning": "Life span, allotted by Vishnu" },
        { "sanskrit": "स्वस्ति", "english": "Svasti", "root": "स्वस्ति", "meaning": "Well-being, prosperity" },
        { "sanskrit": "नः", "english": "Nah", "root": "अस्मद्", "meaning": "To us, the individual souls" },
        { "sanskrit": "इन्द्रो", "english": "Indro", "root": "इन्द्र", "meaning": "Indra, king of devas, subordinate to Vishnu" },
        { "sanskrit": "वृद्धश्रवाः", "english": "Vriddha-shravah", "root": "वृद्ध + श्रवस्", "meaning": "Of growing fame" },
        { "sanskrit": "स्वस्ति", "english": "Svasti", "root": "स्वस्ति", "meaning": "Well-being, prosperity" },
        { "sanskrit": "नः", "english": "Nah", "root": "अस्मद्", "meaning": "To us, the individual souls" },
        { "sanskrit": "पूषा", "english": "Pusha", "root": "पूषन्", "meaning": "Pushan, a Vedic deity of prosperity" },
        { "sanskrit": "विश्ववेदाः", "english": "Vishva-vedah", "root": "विश्व + वेद", "meaning": "All-knowing, omniscient aspect of Vishnu" },
        { "sanskrit": "स्वस्ति", "english": "Svasti", "root": "स्वस्ति", "meaning": "Well-being, prosperity" },
        { "sanskrit": "नः", "english": "Nah", "root": "अस्मद्", "meaning": "To us, the individual souls" },
        { "sanskrit": "तार्क्ष्यो", "english": "Tarkshyo", "root": "तार्क्ष्य", "meaning": "Tarksya (Garuda), Vishnu's vehicle" },
        { "sanskrit": "अरिष्टनेमिः", "english": "Arishta-nemih", "root": "अरिष्ट + नेमि", "meaning": "Of uninjured rim, invincible" },
        { "sanskrit": "स्वस्ति", "english": "Svasti", "root": "स्वस्ति", "meaning": "Well-being, prosperity" },
        { "sanskrit": "नः", "english": "Nah", "root": "अस्मद्", "meaning": "To us, the individual souls" },
        { "sanskrit": "बृहस्पतिः", "english": "Brihaspatih", "root": "बृहस्पति", "meaning": "Brihaspati, teacher of the gods" },
        { "sanskrit": "दधातु", "english": "Dadhatu", "root": "धा", "meaning": "May grant, bestow" },
        { "sanskrit": "ॐ", "english": "Om", "root": "ॐ", "meaning": "The primordial sound representing Vishnu" },
        { "sanskrit": "शान्तिः", "english": "Shantih", "root": "शान्ति", "meaning": "Peace in the physical realm" },
        { "sanskrit": "शान्तिः", "english": "Shantih", "root": "शान्ति", "meaning": "Peace in the mental realm" },
        { "sanskrit": "शान्तिः", "english": "Shantih", "root": "शान्ति", "meaning": "Peace in the spiritual realm" }
      ]
    }
  ]
}, {
  "number": 16,
  "slug": "ugram-viram-maha-vishnum",
  "title": "Narasimha Shloka (Ugram Viram)",
  "verses": [
      {
          "number": 1,
          "title": "Ugram Viram Maha-Vishnum",
          "text": "उग्रं वीरं महाविष्णुं ज्वलन्तं सर्वतोमुखम्।\nनृसिंहं भीषणं भद्रं मृत्युर् मृत्युम् नमाम्यहम्॥",
          "slug": "ugram-viram-maha-vishnum",
          "meaning": "I bow to Lord Narasimha, the fierce and heroic form of Maha Vishnu, who is radiant and faces all directions. He is the terrifying destroyer of evil, the auspicious one, and the death of death.",
          "explanation": "In Madhwa-Shastra, this shloka glorifies Lord Narasimha, the fourth avatar of Lord Vishnu, as the protector and destroyer of evil. His fierce form symbolizes the Lord's power to protect his devotees and annihilate the wicked. The verse underscores the belief that even death fears Narasimha, making Him the ultimate refuge for those seeking liberation and protection.",
          "wordByWord": [
              { "sanskrit": "उग्रं", "english": "Ugram", "root": "उग्र", "meaning": "fierce" },
              { "sanskrit": "वीरं", "english": "Viram", "root": "वीर", "meaning": "heroic" },
              { "sanskrit": "महाविष्णुं", "english": "Maha-Vishnum", "root": "महा + विष्णु", "meaning": "great Vishnu" },
              { "sanskrit": "ज्वलन्तं", "english": "Jvalantam", "root": "ज्वलन्त", "meaning": "radiant" },
              { "sanskrit": "सर्वतोमुखम्", "english": "Sarvato-mukham", "root": "सर्वत: + मुख", "meaning": "facing all directions" },
              { "sanskrit": "नृसिंहं", "english": "Nrisimham", "root": "नृ + सिंह", "meaning": "man-lion" },
              { "sanskrit": "भीषणं", "english": "Bhishanam", "root": "भीषण", "meaning": "terrifying" },
              { "sanskrit": "भद्रं", "english": "Bhadram", "root": "भद्र", "meaning": "auspicious" },
              { "sanskrit": "मृत्युर्", "english": "Mrityur", "root": "मृत्यु", "meaning": "death" },
              { "sanskrit": "मृत्युम्", "english": "Mrityum", "root": "मृत्यु", "meaning": "of death" },
              { "sanskrit": "नमामि", "english": "Namami", "root": "नम", "meaning": "I bow" },
              { "sanskrit": "अहम्", "english": "Aham", "root": "अहम्", "meaning": "I" }
          ]
      }
  ]
},{
  "id": 17,
  "slug": "chathu-sloki-bagavatha",
  "title": "Chathu sloki bagavatha",
  "verses": [{
      "number": 1,
      "slug": "chathu-sloki-bagavatha",
      "title": "The Essence of the Supreme Self",
      "text": "अहम् एव असम् एव अग्रं नान्यत् यत् सत् असत् परम्।\nपश्चात् अहम् यत् एतच् च योऽवशिष्येत सोऽस्मि अहम्।\nऋतेऽर्थम् यत् प्रतियेत न प्रतियेत चात्मनि तद् विद्यत्।\nआत्मनो मयम् यथाभासो यथा तमः यथा महान्ति भूतानि भूतेषु चाचेषु अनु प्रविष्टानि अप्रविष्टानि तथा तेसु न तेसु अहम्।\nएतवद् एव जिज्ञास्यम् तत्त्व जिज्ञासुन आत्मनः।\nअन्वय-व्यतिरेकाभ्याम् यत् स्यात् सर्वत्र सर्वदा।",
      "meaning": "I am the beginning, the end, and everything in between. There is nothing beyond me, neither existence nor non-existence. I am the ultimate reality, the one who remains even after everything else has perished. What appears and disappears in the mind, that is not me. I am the essence of everything, like the light and darkness, like the vast expanse of existence, like the subtle pervading presence in all beings and non-beings. I am both within and beyond them. This is the essence of self-knowledge, to understand the reality of the Self through its connection and disconnection with everything else, in all times and places.",
      "explanation": "This shloka beautifully articulates the essence of Madhva's Dvaita Vedanta philosophy. It emphasizes the absolute distinction between the Supreme Self (Krishna) and everything else.  It highlights the eternal, unchanging, and independent nature of God, while affirming His omnipresence and involvement in the universe. The shloka emphasizes the importance of understanding the true nature of the Self (atman) by contemplating its relationship with the Supreme Self.",
      "wordByWord": [
          {
              "sanskrit": "अहम्",
              "english": "aham",
              "root": "अस्",
              "meaning": "I"
          },
          {
              "sanskrit": "एव",
              "english": "eva",
              "root": "एव",
              "meaning": "indeed"
          },
          {
              "sanskrit": "असम्",
              "english": "asam",
              "root": "अस्",
              "meaning": "not (being)"
          },
          {
              "sanskrit": "एव",
              "english": "eva",
              "root": "एव",
              "meaning": "indeed"
          },
          {
              "sanskrit": "अग्रम्",
              "english": "agram",
              "root": "अग्र",
              "meaning": "beginning"
          },
          {
              "sanskrit": "नान्यत्",
              "english": "nanyat",
              "root": "न + अन्य",
              "meaning": "not other"
          },
          {
              "sanskrit": "यत्",
              "english": "yat",
              "root": "यत्",
              "meaning": "that which"
          },
          {
              "sanskrit": "सत्",
              "english": "sat",
              "root": "सत्",
              "meaning": "being, existence"
          },
          {
              "sanskrit": "असत्",
              "english": "asat",
              "root": "असत्",
              "meaning": "non-being, non-existence"
          },
          {
              "sanskrit": "परम्",
              "english": "param",
              "root": "पर",
              "meaning": "beyond, supreme"
          },
          {
              "sanskrit": "पश्चात्",
              "english": "paschat",
              "root": "पश्च",
              "meaning": "after, behind"
          },
          {
              "sanskrit": "अहम्",
              "english": "aham",
              "root": "अस्",
              "meaning": "I"
          },
          {
              "sanskrit": "यत्",
              "english": "yat",
              "root": "यत्",
              "meaning": "that which"
          },
          {
              "sanskrit": "एतत्",
              "english": "etat",
              "root": "एतत्",
              "meaning": "this"
          },
          {
              "sanskrit": "च",
              "english": "ca",
              "root": "च",
              "meaning": "and"
          },
          {
              "sanskrit": "योऽवशिष्येत",
              "english": "yo'vasisyeta",
              "root": "अव + शिष",
              "meaning": "who will remain"
          },
          {
              "sanskrit": "सोऽस्मि",
              "english": "so'smi",
              "root": "अस्",
              "meaning": "that am I"
          },
          {
              "sanskrit": "अहम्",
              "english": "aham",
              "root": "अस्",
              "meaning": "I"
          },
          {
              "sanskrit": "ऋतेऽर्थम्",
              "english": "rite'rtham",
              "root": "ऋते + अर्थ",
              "meaning": "without purpose"
          },
          {
              "sanskrit": "यत्",
              "english": "yat",
              "root": "यत्",
              "meaning": "that which"
          },
          {
              "sanskrit": "प्रतियेत",
              "english": "pratiyeta",
              "root": "प्रति + ई",
              "meaning": "appears, manifests"
          },
          {
              "sanskrit": "न",
              "english": "na",
              "root": "न",
              "meaning": "not"
          },
          {
              "sanskrit": "प्रतियेत",
              "english": "pratiyeta",
              "root": "प्रति + ई",
              "meaning": "appears, manifests"
          },
          {
              "sanskrit": "च",
              "english": "ca",
              "root": "च",
              "meaning": "and"
          },
          {
              "sanskrit": "आत्मनि",
              "english": "atmani",
              "root": "आत्मन्",
              "meaning": "in the mind, in the Self"
          },
          {
              "sanskrit": "तद्",
              "english": "tad",
              "root": "तद्",
              "meaning": "that"
          },
          {
              "sanskrit": "विद्यत्",
              "english": "vidyat",
              "root": "विद्",
              "meaning": "know"
          },
          {
              "sanskrit": "आत्मनो",
              "english": "atmano",
              "root": "आत्मन्",
              "meaning": "of the Self"
          },
          {
              "sanskrit": "मयम्",
              "english": "mayam",
              "root": "मय",
              "meaning": "by me, from me"
          },
          {
              "sanskrit": "यथाभासो",
              "english": "yathabhaso",
              "root": "यथा + भास",
              "meaning": "like the light"
          },
          {
              "sanskrit": "यथा",
              "english": "yatha",
              "root": "यथा",
              "meaning": "like"
          },
          {
              "sanskrit": "तमः",
              "english": "tamas",
              "root": "तमस्",
              "meaning": "darkness"
          },
          {
              "sanskrit": "यथा",
              "english": "yatha",
              "root": "यथा",
              "meaning": "like"
          },
          {
              "sanskrit": "महान्ति",
              "english": "mahanti",
              "root": "महत्",
              "meaning": "great, vast"
          },
          {
              "sanskrit": "भूतानि",
              "english": "bhutani",
              "root": "भू",
              "meaning": "beings, entities"
          },
          {
              "sanskrit": "भूतेषु",
              "english": "bhuteshu",
              "root": "भू",
              "meaning": "in beings"
          },
          {
              "sanskrit": "चाचेषु",
              "english": "chaceshu",
              "root": "अच + ईष",
              "meaning": "and in non-beings"
          },
          {
              "sanskrit": "अनु",
              "english": "anu",
              "root": "अनु",
              "meaning": "after, according to"
          },
          {
              "sanskrit": "प्रविष्टानि",
              "english": "pravistani",
              "root": "प्र + विश्",
              "meaning": "entered, pervaded"
          },
          {
              "sanskrit": "अप्रविष्टानि",
              "english": "apravistani",
              "root": "अ + प्र + विश्",
              "meaning": "not entered, not pervaded"
          },
          {
              "sanskrit": "तथा",
              "english": "tatha",
              "root": "तथा",
              "meaning": "thus, so"
          },
          {
              "sanskrit": "तेसु",
              "english": "tesu",
              "root": "तद्",
              "meaning": "in them"
          },
          {
              "sanskrit": "न",
              "english": "na",
              "root": "न",
              "meaning": "not"
          },
          {
              "sanskrit": "तेसु",
              "english": "tesu",
              "root": "तद्",
              "meaning": "in them"
          },
          {
              "sanskrit": "अहम्",
              "english": "aham",
              "root": "अस्",
              "meaning": "I"
          },
          {
              "sanskrit": "एतवद्",
              "english": "etavad",
              "root": "एतत् + वद्",
              "meaning": "this much"
          },
          {
              "sanskrit": "एव",
              "english": "eva",
              "root": "एव",
              "meaning": "indeed"
          },
          {
              "sanskrit": "जिज्ञास्यम्",
              "english": "jijnasyam",
              "root": "जिज्ञास",
              "meaning": "to be investigated, to be known"
          },
          {
              "sanskrit": "तत्त्व",
              "english": "tattva",
              "root": "तत् + त्व",
              "meaning": "reality, essence"
          },
          {
              "sanskrit": "जिज्ञासुनः",
              "english": "jijnasunah",
              "root": "जिज्ञास",
              "meaning": "one who seeks knowledge"
          },
          {
              "sanskrit": "आत्मनः",
              "english": "atmanah",
              "root": "आत्मन्",
              "meaning": "of the Self"
          },
          {
              "sanskrit": "अन्वय-",
              "english": "anvaya-",
              "root": "अनु + ई",
              "meaning": "connection, association"
          },
          {
              "sanskrit": "व्यतिरेकाभ्याम्",
              "english": "vyatirekabhyam",
              "root": "वि + तिर + ई",
              "meaning": "disconnection, separation"
          },
          {
              "sanskrit": "यत्",
              "english": "yat",
              "root": "यत्",
              "meaning": "that which"
          },
          {
              "sanskrit": "स्यात्",
              "english": "syat",
              "root": "अस्",
              "meaning": "is"
          },
          {
              "sanskrit": "सर्वत्र",
              "english": "sarvatra",
              "root": "सर्व + त्र",
              "meaning": "everywhere"
          },
          {
              "sanskrit": "सर्वदा",
              "english": "sarvada",
              "root": "सर्व + दा",
              "meaning": "always"
          }
      ]
  }]
},{
  "id": 18,
  "slug": "nasadiya-sukta",
  "title": "Nasadiya Sukta - Hymn of Creation",
  "verses": [
      {
          "number": 1,
          "slug": "nasad-asit",
          "title": "उत्पत्ति (Origin)",
          "text": "नासदासीन्नो सदासीत्तदानीं नासीद्रजो नो व्योमा परो यत् । किमावरीवः कुह कस्य शर्मन्नम्भः किमासीद्गहनं गभीरम् ॥",
          "meaning": "In the beginning, there was neither non-existence nor existence, neither the realm of space nor the sky beyond. What was there? Where? In whose protection? Was there water, unfathomably deep?",
          "explanation": "This verse highlights the transcendence of the Supreme Being, Vishnu, who is beyond the dualities of existence and non-existence. The inquiry into the state of the cosmos before creation reflects the mystery of the divine origin, where even the concept of existence was not separate from the Supreme.",
          "wordByWord": [
              { "sanskrit": "न", "english": "na", "root": "न", "meaning": "not" },
              { "sanskrit": "असत्", "english": "asat", "root": "असत्", "meaning": "non-existence" },
              { "sanskrit": "असित्", "english": "asit", "root": "अस्ति", "meaning": "was" },
              { "sanskrit": "सत्", "english": "sat", "root": "सत्", "meaning": "existence" },
              { "sanskrit": "असित्", "english": "asit", "root": "अस्ति", "meaning": "was" },
              { "sanskrit": "तदानीं", "english": "tadanim", "root": "तदानीं", "meaning": "then" },
              { "sanskrit": "न", "english": "na", "root": "न", "meaning": "not" },
              { "sanskrit": "असित्", "english": "asit", "root": "अस्ति", "meaning": "was" },
              { "sanskrit": "रजो", "english": "rajo", "root": "रजस्", "meaning": "air" },
              { "sanskrit": "न", "english": "na", "root": "न", "meaning": "not" },
              { "sanskrit": "व्योम", "english": "vyoma", "root": "व्योमन्", "meaning": "sky" },
              { "sanskrit": "परो", "english": "paro", "root": "पर", "meaning": "beyond" },
              { "sanskrit": "यत्", "english": "yat", "root": "यत्", "meaning": "that which" },
              { "sanskrit": "किम", "english": "kim", "root": "किम्", "meaning": "what" },
              { "sanskrit": "आवरीवः", "english": "avarivah", "root": "आवरण", "meaning": "covering" },
              { "sanskrit": "कुह", "english": "kuha", "root": "कुह", "meaning": "where" },
              { "sanskrit": "कस्य", "english": "kasya", "root": "कस्य", "meaning": "whose" },
              { "sanskrit": "शर्म", "english": "sharma", "root": "शर्म", "meaning": "shelter" },
              { "sanskrit": "अम्भः", "english": "ambhah", "root": "अम्भस्", "meaning": "water" },
              { "sanskrit": "किम", "english": "kim", "root": "किम्", "meaning": "what" },
              { "sanskrit": "असित्", "english": "asit", "root": "अस्ति", "meaning": "was" },
              { "sanskrit": "गहनं", "english": "gahanam", "root": "गहन", "meaning": "deep" },
              { "sanskrit": "गभीरम्", "english": "gabhiram", "root": "गभीर", "meaning": "profound" }
          ]
      },
      {
          "number": 2,
          "slug": "mrityur-asit",
          "title": "आदिम (Primordial)",
          "text": "न मृत्युरासीदमृतं न तर्हि न रात्र्या अह्न आसीत्प्रकेतः । आनीदवातं स्वधया तदेकं तस्माद्धान्यन्न परः किञ्चनास ॥",
          "meaning": "There was neither death nor immortality then. There was no indication of night or day. That One breathed without breath, by its own nature. Other than that, there was nothing beyond.",
          "explanation": "This verse delves into the state of the cosmos before creation, where concepts like death, immortality, day, and night did not exist. The Supreme Being, Vishnu, existed independently, self-sustained, and without the need for breath, emphasizing His absolute and unparalleled nature.",
          "wordByWord": [
              { "sanskrit": "न", "english": "na", "root": "न", "meaning": "not" },
              { "sanskrit": "मृत्युः", "english": "mrityuh", "root": "मृत्यु", "meaning": "death" },
              { "sanskrit": "असित्", "english": "asit", "root": "अस्ति", "meaning": "was" },
              { "sanskrit": "अमृतं", "english": "amritam", "root": "अमृत", "meaning": "immortality" },
              { "sanskrit": "न", "english": "na", "root": "न", "meaning": "not" },
              { "sanskrit": "तर्हि", "english": "tarhi", "root": "तर्हि", "meaning": "then" },
              { "sanskrit": "न", "english": "na", "root": "न", "meaning": "not" },
              { "sanskrit": "रात्र्या", "english": "ratrya", "root": "रात्रि", "meaning": "night" },
              { "sanskrit": "अह्न", "english": "ahnah", "root": "अहन्", "meaning": "day" },
              { "sanskrit": "आसीत्", "english": "asit", "root": "अस्ति", "meaning": "was" },
              { "sanskrit": "प्रकेतः", "english": "praketa", "root": "प्रकेत", "meaning": "indication" },
              { "sanskrit": "आनीत्", "english": "anit", "root": "आनित्", "meaning": "breathed" },
              { "sanskrit": "अवातं", "english": "avatam", "root": "अवात", "meaning": "without air" },
              { "sanskrit": "स्वधया", "english": "svadhaya", "root": "स्वधा", "meaning": "by own nature" },
              { "sanskrit": "तदेकं", "english": "tadekam", "root": "तत् + एक", "meaning": "that One" },
              { "sanskrit": "तस्मात्", "english": "tasmat", "root": "तस्मात्", "meaning": "from that" },
              { "sanskrit": "ध", "english": "dhan", "root": "ध", "meaning": "no" },
              { "sanskrit": "अन्यन्", "english": "anyan", "root": "अन्य", "meaning": "anything else" },
              { "sanskrit": "न", "english": "na", "root": "न", "meaning": "not" },
              { "sanskrit": "परः", "english": "parah", "root": "पर", "meaning": "beyond" },
              { "sanskrit": "किञ्चन", "english": "kinchana", "root": "किञ्चन", "meaning": "anything" },
              { "sanskrit": "असित्", "english": "asit", "root": "अस्ति", "meaning": "was" }
          ]
      },
      {
          "number": 3,
          "slug": "tam-asit",
          "title": "अन्धकार (Obscurity)",
          "text": "तम आसीत्तमसा गूहळमग्रे प्रकेतं सलिलं सर्वाऽइदम् । तुच्छ्येनाभ्वपिहितं यदासीत्तपसस्तन्महिनाजायतैकम् ॥",
          "meaning": "Darkness was hidden by darkness in the beginning. All this was an undifferentiated expanse of water. The life force that was covered by the void was born through the power of austerity (tapas).",
          "explanation": "This verse describes the primordial state as an expanse of darkness, where even the life force was hidden. The creative force or life was born out of austerity, emphasizing the role of divine will and effort in the process of creation, aligning with the concept that the Supreme Being, Vishnu, is the source of all creation.",
          "wordByWord": [
              { "sanskrit": "तम", "english": "tam", "root": "तमस्", "meaning": "darkness" },
              { "sanskrit": "आसीत्", "english": "asit", "root": "अस्ति", "meaning": "was" },
              { "sanskrit": "तमसा", "english": "tamasa", "root": "तमस्", "meaning": "by darkness" },
              { "sanskrit": "गूहळम्", "english": "guhalam", "root": "गूहळ", "meaning": "hidden" },
              { "sanskrit": "अग्रे", "english": "agre", "root": "अग्रे", "meaning": "in the beginning" },
              { "sanskrit": "प्रकेतं", "english": "praketam", "root": "प्रकेत", "meaning": "an expanse" },
              { "sanskrit": "सलिलं", "english": "salilam", "root": "सलिल", "meaning": "water" },
              { "sanskrit": "सर्वा", "english": "sarva", "root": "सर्व", "meaning": "all" },
              { "sanskrit": "इदम्", "english": "idam", "root": "इदम्", "meaning": "this" },
              { "sanskrit": "तुच्छ्येन", "english": "tuchyena", "root": "तुच्छ", "meaning": "by void" },
              { "sanskrit": "अभ्व", "english": "abhva", "root": "अभ्व", "meaning": "life force" },
              { "sanskrit": "अपिहितं", "english": "apihitam", "root": "अपिहित", "meaning": "covered" },
              { "sanskrit": "यदासीत्", "english": "yadasit", "root": "यत् + अस्ति", "meaning": "that was" },
              { "sanskrit": "तपस", "english": "tapas", "root": "तपस्", "meaning": "austerity" },
              { "sanskrit": "महिन", "english": "mahin", "root": "महिन्", "meaning": "power" },
              { "sanskrit": "आयात", "english": "ayat", "root": "आयाति", "meaning": "was born" },
              { "sanskrit": "एकम्", "english": "ekam", "root": "एक", "meaning": "One" }
          ]
      },
      {
          "number": 4,
          "slug": "kamas-tadagre",
          "title": "कामना (Desire)",
          "text": "कामस्तदग्रे समवर्तताधि मनसो रेतः प्रथमं यदासीत् । सतो बन्धुमसति निरविन्दन्हृदि प्रतीष्या कवयो मनीषा ॥",
          "meaning": "In the beginning, desire (Kama) arose, which was the first seed of the mind. Poets, having searched in their hearts with wisdom, discovered the bond of existence in non-existence.",
          "explanation": "This verse reflects the concept of divine will and desire as the first cause of creation. The poets, symbolic of wise beings, recognize the link between existence and non-existence, understanding that the Supreme Being, Vishnu, is the source of all that exists and doesn't exist.",
          "wordByWord": [
              { "sanskrit": "काम", "english": "kama", "root": "काम", "meaning": "desire" },
              { "sanskrit": "तद", "english": "tad", "root": "तद्", "meaning": "then" },
              { "sanskrit": "अग्रे", "english": "agre", "root": "अग्रे", "meaning": "in the beginning" },
              { "sanskrit": "समवर्तत", "english": "samavartata", "root": "समवर्तते", "meaning": "arose" },
              { "sanskrit": "अधि", "english": "adhi", "root": "अधि", "meaning": "above" },
              { "sanskrit": "मनसो", "english": "manaso", "root": "मनस्", "meaning": "of the mind" },
              { "sanskrit": "रेतस्", "english": "retas", "root": "रेतस्", "meaning": "seed" },
              { "sanskrit": "प्रथमं", "english": "prathamam", "root": "प्रथम्", "meaning": "first" },
              { "sanskrit": "यद", "english": "yad", "root": "यत्", "meaning": "that which" },
              { "sanskrit": "आसीत्", "english": "asit", "root": "अस्ति", "meaning": "was" },
              { "sanskrit": "सतो", "english": "sato", "root": "सत्", "meaning": "of existence" },
              { "sanskrit": "बन्धुम्", "english": "bandhum", "root": "बन्धु", "meaning": "bond" },
              { "sanskrit": "असति", "english": "asati", "root": "असत्", "meaning": "in non-existence" },
              { "sanskrit": "निरविन्दन्", "english": "niravindan", "root": "निर्विन्दति", "meaning": "discovered" },
              { "sanskrit": "हृदि", "english": "hridi", "root": "हृद्", "meaning": "in the heart" },
              { "sanskrit": "प्रतीष्या", "english": "pratisya", "root": "प्रतीष्या", "meaning": "wisdom" },
              { "sanskrit": "कवयो", "english": "kavayo", "root": "कवि", "meaning": "poets" },
              { "sanskrit": "मनीषा", "english": "manisha", "root": "मनीषा", "meaning": "wisdom" }
          ]
      },
      {
          "number": 5,
          "slug": "tirashcino-vitato",
          "title": "विश्वीय (Cosmic)",
          "text": "तिरश्चीनो विततो रश्मिरेषामधः स्विदासीदुपरि स्विदासीत् । रेतोधा आसन्महिमान आसन्त्स्वधा अवस्तात्प्रयतिः परस्तात् ॥",
          "meaning": "Their cord was extended across. Was there below? Was there above? There were seed-bearers, mighty powers. Energy was below, impulse above.",
          "explanation": "This verse reflects the process of creation, where the cosmic forces are being organized. The 'cord' signifies the link between the divine (above) and the manifest world (below). The mention of seed-bearers and mighty powers emphasizes the divine potency of Vishnu in creating and sustaining the universe.",
          "wordByWord": [
              { "sanskrit": "तिरश्चीनः", "english": "tirashcinah", "root": "तिरश्चिन", "meaning": "across" },
              { "sanskrit": "विततः", "english": "vitatah", "root": "वितत", "meaning": "extended" },
              { "sanskrit": "रश्मिः", "english": "rashmih", "root": "रश्मि", "meaning": "cord" },
              { "sanskrit": "एषां", "english": "esham", "root": "एषाम्", "meaning": "of these" },
              { "sanskrit": "अधः", "english": "adhah", "root": "अधस्", "meaning": "below" },
              { "sanskrit": "स्विद", "english": "svid", "root": "स्विद्", "meaning": "perhaps" },
              { "sanskrit": "आसीत्", "english": "asit", "root": "अस्ति", "meaning": "was" },
              { "sanskrit": "उपरि", "english": "upari", "root": "उपरि", "meaning": "above" },
              { "sanskrit": "स्विद", "english": "svid", "root": "स्विद्", "meaning": "perhaps" },
              { "sanskrit": "आसीत्", "english": "asit", "root": "अस्ति", "meaning": "was" },
              { "sanskrit": "रेतोधा", "english": "retodha", "root": "रेतोधा", "meaning": "seed-bearers" },
              { "sanskrit": "आसन", "english": "asan", "root": "आसन्", "meaning": "were" },
              { "sanskrit": "महिमानः", "english": "mahimanah", "root": "महिमा", "meaning": "mighty powers" },
              { "sanskrit": "आसन", "english": "asan", "root": "आसन्", "meaning": "were" },
              { "sanskrit": "स्वधा", "english": "svadha", "root": "स्वधा", "meaning": "energy" },
              { "sanskrit": "अवस्तात्", "english": "avastat", "root": "अवस्तात्", "meaning": "below" },
              { "sanskrit": "प्रयतिः", "english": "prayatih", "root": "प्रयतिः", "meaning": "impulse" },
              { "sanskrit": "परस्तात्", "english": "parastat", "root": "परस्तात्", "meaning": "above" }
          ]
      },
      {
          "number": 6,
          "slug": "ko-adhya",
          "title": "सृष्टि (Creation)",
          "text": "को अद्धा वेद क इह प्र वोचत्कुत आजाता कुत इयं विसृष्टिः । अर्वाग्देवा अस्य विसर्जनेनाथा को वेद यत आबभूव ॥",
          "meaning": "Who truly knows? Who can declare it? From where was it born? From where came this creation? The gods came afterwards, with the creation of this universe. Who then knows from where it has arisen?",
          "explanation": "This verse expresses the mystery of creation, where even the gods, who came after creation, are unsure of the origin. It emphasizes the incomprehensibility of the Supreme Being, Vishnu, whose actions and nature are beyond the grasp of even the divine entities.",
          "wordByWord": [
              { "sanskrit": "कः", "english": "kah", "root": "कः", "meaning": "who" },
              { "sanskrit": "अद्धा", "english": "addha", "root": "अद्धा", "meaning": "truly" },
              { "sanskrit": "वेद", "english": "veda", "root": "वेद", "meaning": "knows" },
              { "sanskrit": "कः", "english": "kah", "root": "कः", "meaning": "who" },
              { "sanskrit": "इह", "english": "iha", "root": "इह", "meaning": "here" },
              { "sanskrit": "प्र", "english": "pra", "root": "प्र", "meaning": "forth" },
              { "sanskrit": "वोचत्", "english": "vocat", "root": "वच्", "meaning": "can declare" },
              { "sanskrit": "कुतः", "english": "kutah", "root": "कुतस्", "meaning": "from where" },
              { "sanskrit": "आजाताः", "english": "ajatah", "root": "आजात", "meaning": "born" },
              { "sanskrit": "कुतः", "english": "kutah", "root": "कुतस्", "meaning": "from where" },
              { "sanskrit": "इयम्", "english": "iyam", "root": "इयम्", "meaning": "this" },
              { "sanskrit": "विसृष्टिः", "english": "visristih", "root": "विसृष्टि", "meaning": "creation" },
              { "sanskrit": "अर्वाक्", "english": "arvak", "root": "अर्वाक्", "meaning": "afterwards" },
              { "sanskrit": "देवाः", "english": "devah", "root": "देव", "meaning": "gods" },
              { "sanskrit": "अस्य", "english": "asya", "root": "अस्य", "meaning": "of this" },
              { "sanskrit": "विसर्जने", "english": "visarjane", "root": "विसर्जन", "meaning": "creation" },
              { "sanskrit": "अथा", "english": "atha", "root": "अथ", "meaning": "after" },
              { "sanskrit": "कः", "english": "kah", "root": "कः", "meaning": "who" },
              { "sanskrit": "वेद", "english": "veda", "root": "वेद", "meaning": "knows" },
              { "sanskrit": "यत्", "english": "yat", "root": "यत्", "meaning": "from which" },
              { "sanskrit": "आबभूव", "english": "ababhuva", "root": "आबभूव", "meaning": "has arisen" }
          ]
      },
      {
          "number": 7,
          "slug": "iyam-visrishtir",
          "title": "अधीक्षक (Overseer)",
          "text": "इयं विसृष्टिर्यत आबभूव यदि वा दधे यदि वा न । यो अस्याध्यक्षः परमे व्योमन्त्सो अङ्ग वेद यदि वा न वेद ॥",
          "meaning": "This creation, from where it has arisen—perhaps it formed itself, or perhaps it did not. The one who looks down on it, in the highest heaven, He alone knows—or perhaps He does not know.",
          "explanation": "This verse captures the profound mystery of creation, where even the Supreme Being, Vishnu, who resides in the highest heaven, may or may not know the origin of creation. This paradox highlights the limitless and incomprehensible nature of the Supreme, transcending all understanding.",
          "wordByWord": [
              { "sanskrit": "इयम्", "english": "iyam", "root": "इयम्", "meaning": "this" },
              { "sanskrit": "विसृष्टिः", "english": "visristih", "root": "विसृष्टि", "meaning": "creation" },
              { "sanskrit": "यत्", "english": "yat", "root": "यत्", "meaning": "from which" },
              { "sanskrit": "आबभूव", "english": "ababhuva", "root": "आबभूव", "meaning": "has arisen" },
              { "sanskrit": "यदि", "english": "yadi", "root": "यदि", "meaning": "perhaps" },
              { "sanskrit": "वा", "english": "va", "root": "वा", "meaning": "or" },
              { "sanskrit": "दधे", "english": "dadhe", "root": "दधाति", "meaning": "formed" },
              { "sanskrit": "यदि", "english": "yadi", "root": "यदि", "meaning": "perhaps" },
              { "sanskrit": "वा", "english": "va", "root": "वा", "meaning": "or" },
              { "sanskrit": "न", "english": "na", "root": "न", "meaning": "not" },
              { "sanskrit": "यो", "english": "yah", "root": "यः", "meaning": "who" },
              { "sanskrit": "अस्य", "english": "asya", "root": "अस्य", "meaning": "of this" },
              { "sanskrit": "अध्यक्षः", "english": "adhyakshah", "root": "अध्यक्ष", "meaning": "overseer" },
              { "sanskrit": "परमे", "english": "parame", "root": "परम", "meaning": "highest" },
              { "sanskrit": "व्योमन्", "english": "vyoman", "root": "व्योमन्", "meaning": "heaven" },
              { "sanskrit": "सः", "english": "sah", "root": "सः", "meaning": "he" },
              { "sanskrit": "अङ्ग", "english": "anga", "root": "अङ्ग", "meaning": "alone" },
              { "sanskrit": "वेद", "english": "veda", "root": "वेद", "meaning": "knows" },
              { "sanskrit": "यदि", "english": "yadi", "root": "यदि", "meaning": "perhaps" },
              { "sanskrit": "वा", "english": "va", "root": "वा", "meaning": "or" },
              { "sanskrit": "न", "english": "na", "root": "न", "meaning": "not" },
              { "sanskrit": "वेद", "english": "veda", "root": "वेद", "meaning": "knows" }
          ]
      }
  ]
},{
  "id": 19,
  "slug": "narayana-paripurna-guna",
  "title": "Narayana Paripurna Gunarnava",
  "verses": [
      {
          "number": 1,
          "slug": "narayana-verse",
          "title": "Narayana Paripurna Gunarnava",
          "text": "नारायणाय परिपूर्णगुणार्णवाय विश्वोदयस्थितिलयोन्नियतिप्रदाय । ज्ञानप्रदाय विबुधासुरसौख्यदुःखसत्कारणाय वितताय नमो नमस्ते ॥ १॥",
          "meaning": "I bow to Narayana, who is the ocean of perfect qualities, who is the cause of the creation, sustenance, and dissolution of the universe. He is the giver of knowledge, the cause of joy and sorrow for both gods and demons, and the source of all order and control.",
          "explanation": "This verse praises Lord Narayana as the supreme being, full of infinite and perfect attributes. He is responsible for the creation, sustenance, and dissolution of the universe, controlling everything according to His will. Narayana is also the source of knowledge and wisdom, as well as the one who causes happiness and suffering in the material world for both gods (Devas) and demons (Asuras), highlighting His role as the ultimate ruler of the cosmos.",
          "wordByWord": [
              {
                  "sanskrit": "नारायणाय",
                  "english": "Narayanaaya",
                  "root": "नारायण",
                  "meaning": "To Narayana"
              },
              {
                  "sanskrit": "परिपूर्णगुणार्णवाय",
                  "english": "paripurna-guna-arnavaya",
                  "root": "परिपूर्ण + गुण + अर्णव",
                  "meaning": "to the one who is the ocean of perfect qualities"
              },
              {
                  "sanskrit": "विश्वोदय-स्थितिलयो-न्नियतिप्रदाय",
                  "english": "vishvodayasthitilayonniyati-pradaya",
                  "root": "विश्व + उदय + स्थिति + लय + नियति + प्रद",
                  "meaning": "giver of creation, sustenance, and dissolution of the universe"
              },
              {
                  "sanskrit": "ज्ञानप्रदाय",
                  "english": "jnanapradaya",
                  "root": "ज्ञान + प्रद",
                  "meaning": "giver of knowledge"
              },
              {
                  "sanskrit": "विबुधासुरसौख्यदुःख",
                  "english": "vibudha-asura-saukhyaduhkha",
                  "root": "विबुध + असुर + सौख्य + दुःख",
                  "meaning": "the giver of joy and sorrow to gods and demons"
              },
              {
                  "sanskrit": "सत्कारणाय",
                  "english": "satkaranaya",
                  "root": "सत् + कारण",
                  "meaning": "the ultimate cause"
              },
              {
                  "sanskrit": "वितताय",
                  "english": "vitataya",
                  "root": "वितत",
                  "meaning": "the all-pervading one"
              },
              {
                  "sanskrit": "नमो नमस्ते",
                  "english": "namo namaste",
                  "root": "नमः",
                  "meaning": "I bow to you again and again"
              }
          ]
      }
  ]
}, {
  "id": 20,
  "slug": "yam-brahma-varuna-shloka",
  "title": "Yam Brahma Varunendra",
  "verses": [
      {
          "number": 1,
          "slug": "yam-brahma-varuna",
          "title": "Yam Brahma Varunendra",
          "text": "यं ब्रह्मा वरुणेन्द्र-रुद्र- मरुतः स्तुन्वन्ति दिव्यैः स्तवैः।\nवेदैः साङ्गपदक्रमोपनिषदैर्गायन्ति यं सामगा:।\nध्यानावस्थित-तद्गतेन मनसा पश्यन्ति यं योगिनो।\nयस्यान्तं न विदुः सुरासुरगणा: देवाय तस्मै नमः॥",
          "meaning": "I bow to that Supreme Divine, whom Brahma, Varuna, Indra, Rudra, and the Maruts praise with divine hymns. Whom the singers of the Sama Veda glorify through the verses of the Vedas and the Upanishads. Whom the yogis, in their deep meditative state, behold with their focused minds. And whose limits neither the gods nor the demons can comprehend.",
          "explanation": "This shloka is a profound praise of the Supreme Divine, acknowledging His infinite and incomprehensible nature. Brahma, Varuna, Indra, and Rudra—all great deities in Hindu tradition—sing praises of this Supreme Being with divine hymns. The Sama singers glorify Him through Vedic chants, and the yogis, in a meditative state, realize Him with their mind absorbed in divine contemplation. Even the gods and demons are unable to fathom the full extent of His glory.",
          "wordByWord": [
              {
                  "sanskrit": "यं",
                  "english": "yam",
                  "root": "यः",
                  "meaning": "whom"
              },
              {
                  "sanskrit": "ब्रह्मा",
                  "english": "brahma",
                  "root": "ब्रह्मा",
                  "meaning": "Brahma"
              },
              {
                  "sanskrit": "वरुणेन्द्र",
                  "english": "varuna-indra",
                  "root": "वरुण + इन्द्र",
                  "meaning": "Varuna and Indra"
              },
              {
                  "sanskrit": "रुद्र",
                  "english": "rudra",
                  "root": "रुद्र",
                  "meaning": "Rudra"
              },
              {
                  "sanskrit": "मरुतः",
                  "english": "marutah",
                  "root": "मरुत",
                  "meaning": "Maruts"
              },
              {
                  "sanskrit": "स्तुन्वन्ति",
                  "english": "stunvanti",
                  "root": "स्तु",
                  "meaning": "praise"
              },
              {
                  "sanskrit": "दिव्यैः",
                  "english": "divyaih",
                  "root": "दिव्य",
                  "meaning": "divine"
              },
              {
                  "sanskrit": "स्तवैः",
                  "english": "stavaih",
                  "root": "स्तव",
                  "meaning": "hymns"
              },
              {
                  "sanskrit": "वेदैः",
                  "english": "vedaih",
                  "root": "वेद",
                  "meaning": "by the Vedas"
              },
              {
                  "sanskrit": "साङ्ग",
                  "english": "sanga",
                  "root": "साङ्ग",
                  "meaning": "with accessories"
              },
              {
                  "sanskrit": "पदक्रम",
                  "english": "pada-krama",
                  "root": "पद + क्रम",
                  "meaning": "with the sequence of words"
              },
              {
                  "sanskrit": "उपनिषदैः",
                  "english": "upanishadaih",
                  "root": "उपनिषद",
                  "meaning": "with the Upanishads"
              },
              {
                  "sanskrit": "गायन्ति",
                  "english": "gayanti",
                  "root": "ग",
                  "meaning": "sing"
              },
              {
                  "sanskrit": "सामगा:",
                  "english": "samagah",
                  "root": "साम + ग",
                  "meaning": "singers of Sama Veda"
              },
              {
                  "sanskrit": "ध्यान",
                  "english": "dhyana",
                  "root": "ध्यान",
                  "meaning": "meditation"
              },
              {
                  "sanskrit": "आवस्थित",
                  "english": "avasthita",
                  "root": "आवस्थित",
                  "meaning": "situated"
              },
              {
                  "sanskrit": "तद्गतेन",
                  "english": "tad-gatena",
                  "root": "तत् + गत",
                  "meaning": "absorbed in that"
              },
              {
                  "sanskrit": "मनसा",
                  "english": "manasa",
                  "root": "मन",
                  "meaning": "by the mind"
              },
              {
                  "sanskrit": "पश्यन्ति",
                  "english": "pashyanti",
                  "root": "पश्",
                  "meaning": "see"
              },
              {
                  "sanskrit": "योगिनः",
                  "english": "yoginah",
                  "root": "योगिन",
                  "meaning": "yogis"
              },
              {
                  "sanskrit": "यस्यान्तं",
                  "english": "yasya-antam",
                  "root": "यस्मात् + अन्त",
                  "meaning": "whose end"
              },
              {
                  "sanskrit": "न",
                  "english": "na",
                  "root": "न",
                  "meaning": "not"
              },
              {
                  "sanskrit": "विदुः",
                  "english": "viduh",
                  "root": "विद्",
                  "meaning": "know"
              },
              {
                  "sanskrit": "सुरासुरगणा:",
                  "english": "sura-asura-gana",
                  "root": "सुर + असुर + गण",
                  "meaning": "the gods and demons"
              },
              {
                  "sanskrit": "देवाय",
                  "english": "devaya",
                  "root": "देव",
                  "meaning": "to the divine"
              },
              {
                  "sanskrit": "तस्मै",
                  "english": "tasmai",
                  "root": "तस्मै",
                  "meaning": "to him"
              },
              {
                  "sanskrit": "नमः",
                  "english": "namah",
                  "root": "नम",
                  "meaning": "I bow"
              }
          ]
      }
  ]
}, {
  "id": 21,
  "slug": "yo-antha-pravishya-shloka",
  "title": "Yo Antha Pravishya Shloka",
  "verses": [
      {
          "number": 1,
          "slug": "yo-antha-pravishya",
          "title": "Salutation to the All-Pervading Lord",
          "text": "योऽन्तः प्रविश्य मम वाचमिमां प्रसुप्तां संजीवयत्यखिलशक्तिधरः स्वधाम्ना।\nअन्यांश्च हस्तचरणश्रवणत्वगादीन्प्राणान्नमो भगवते पुरुषाय तुभ्यम्॥",
          "meaning": "I bow to the Supreme Purusha, who, entering within, awakens my dormant speech and enlivens my senses—my hands, feet, ears, skin, and all organs of perception—by His inherent, all-encompassing power.",
          "explanation": "In this shloka, the devotee (Dhruva) expresses deep gratitude towards the Supreme Being, the Purusha, who is the source of all life and activity. The Lord, by His divine will, enlivens not only the speech but also all the organs of action and perception, thereby making it possible for living beings to experience and act in the world. The shloka acknowledges the Lord’s omnipresence and His role in sustaining all beings.",
          "wordByWord": [
              {
                  "sanskrit": "यः",
                  "english": "yah",
                  "root": "यः",
                  "meaning": "who"
              },
              {
                  "sanskrit": "अन्तः",
                  "english": "antah",
                  "root": "अन्तः",
                  "meaning": "within"
              },
              {
                  "sanskrit": "प्रविश्य",
                  "english": "pravishya",
                  "root": "प्रविश्",
                  "meaning": "entering"
              },
              {
                  "sanskrit": "मम",
                  "english": "mama",
                  "root": "मम",
                  "meaning": "my"
              },
              {
                  "sanskrit": "वाचम्",
                  "english": "vacham",
                  "root": "वाक्",
                  "meaning": "speech"
              },
              {
                  "sanskrit": "इमाम्",
                  "english": "imam",
                  "root": "इम",
                  "meaning": "this"
              },
              {
                  "sanskrit": "प्रसुप्ताम्",
                  "english": "prasuptam",
                  "root": "प्रसुप्त",
                  "meaning": "dormant"
              },
              {
                  "sanskrit": "संजीवयति",
                  "english": "sanjeevayati",
                  "root": "संजीव",
                  "meaning": "enlivens"
              },
              {
                  "sanskrit": "अखिलशक्तिधरः",
                  "english": "akhila-shakti-dhara",
                  "root": "अखिल + शक्ति + धर",
                  "meaning": "the possessor of all powers"
              },
              {
                  "sanskrit": "स्वधाम्ना",
                  "english": "swa-dhamna",
                  "root": "स्व + धाम",
                  "meaning": "by His own inherent power"
              },
              {
                  "sanskrit": "अन्यान्",
                  "english": "anyan",
                  "root": "अन्य",
                  "meaning": "others"
              },
              {
                  "sanskrit": "हस्तचरण",
                  "english": "hasta-charana",
                  "root": "हस्त + चरण",
                  "meaning": "hands and feet"
              },
              {
                  "sanskrit": "श्रवण",
                  "english": "shravana",
                  "root": "श्रवण",
                  "meaning": "ears"
              },
              {
                  "sanskrit": "त्वक",
                  "english": "tvak",
                  "root": "त्वच्",
                  "meaning": "skin"
              },
              {
                  "sanskrit": "आदीन्",
                  "english": "adeen",
                  "root": "आदि",
                  "meaning": "and others"
              },
              {
                  "sanskrit": "प्राणान्",
                  "english": "pranan",
                  "root": "प्राण",
                  "meaning": "vital energies"
              },
              {
                  "sanskrit": "नमः",
                  "english": "namah",
                  "root": "नम",
                  "meaning": "I bow"
              },
              {
                  "sanskrit": "भगवते",
                  "english": "bhagavate",
                  "root": "भगवत्",
                  "meaning": "to the Blessed Lord"
              },
              {
                  "sanskrit": "पुरुषाय",
                  "english": "purushaya",
                  "root": "पुरुष",
                  "meaning": "to the Supreme Purusha"
              },
              {
                  "sanskrit": "तुभ्यम्",
                  "english": "tubhyam",
                  "root": "त्वम्",
                  "meaning": "to you"
              }
          ]
      }
  ]
}, {
  "id": 22,
  "slug": "dharma-vignana-vairagya-shloka",
  "title": "Dharma, Vijnana, and Vairagya Shloka",
  "verses": [
      {
          "number": 1,
          "slug": "dharma-vignana-vairagya",
          "title": "Salutation to Anandatheertha",
          "text": "धर्मविज्ञानवैराग्यपरमऐश्वर्यशालिनः । आनन्दतीर्थभगवत्पादान्वन्दे निरन्तरम् ॥ अभ्रमं भङ्गरहितमजडं विमलं सदा । आनन्दतीर्थमतुलं भजे तापत्रयापहम् ॥",
          "meaning": "I offer my continuous salutations to Bhagavathpada Anandatheertha, who is adorned with supreme virtues of Dharma, knowledge, dispassion, and great lordship. I worship the incomparable Anandatheertha, who is free from delusion, without any faults, always pure, and who dispels the threefold miseries (physical, mental, and spiritual sufferings).",
          "explanation": "In this verse, Madhvacharya (Anandatheertha) is praised for his embodiment of Dharma (righteousness), Vijnana (knowledge), Vairagya (dispassion), and supreme Aishwarya (lordship). He is described as being without flaws, unmoved by delusion, and pure in nature. The verse highlights that worshipping Anandatheertha leads to the alleviation of the threefold sufferings that humans endure: physical, mental, and spiritual pain.",
          "wordByWord": [
              {
                  "sanskrit": "धर्म",
                  "english": "dharma",
                  "root": "धर्म",
                  "meaning": "righteousness"
              },
              {
                  "sanskrit": "विज्ञान",
                  "english": "vijnana",
                  "root": "विज्ञान",
                  "meaning": "knowledge"
              },
              {
                  "sanskrit": "वैराग्य",
                  "english": "vairagya",
                  "root": "वैराग्य",
                  "meaning": "dispassion"
              },
              {
                  "sanskrit": "परम",
                  "english": "parama",
                  "root": "परम्",
                  "meaning": "supreme"
              },
              {
                  "sanskrit": "ऐश्वर्य",
                  "english": "aishwarya",
                  "root": "ऐश्वर्य",
                  "meaning": "lordship"
              },
              {
                  "sanskrit": "शालिनः",
                  "english": "shalinaha",
                  "root": "शालिन",
                  "meaning": "endowed with"
              },
              {
                  "sanskrit": "आनन्दतीर्थ",
                  "english": "Anandatheertha",
                  "root": "आनन्द + तीर्थ",
                  "meaning": "Anandatheertha (Madhvacharya)"
              },
              {
                  "sanskrit": "भगवत्पाद",
                  "english": "Bhagavathpada",
                  "root": "भगवत् + पाद",
                  "meaning": "the feet of the blessed one"
              },
              {
                  "sanskrit": "वन्दे",
                  "english": "vande",
                  "root": "वन्द्",
                  "meaning": "I salute"
              },
              {
                  "sanskrit": "निरन्तरम्",
                  "english": "nirantharam",
                  "root": "निरन्तर",
                  "meaning": "continuously"
              },
              {
                  "sanskrit": "अभ्रमं",
                  "english": "abhramam",
                  "root": "अ + भ्रम",
                  "meaning": "without delusion"
              },
              {
                  "sanskrit": "भङ्गरहितम्",
                  "english": "bhangarahitam",
                  "root": "भङ्ग + रहित",
                  "meaning": "without any faults"
              },
              {
                  "sanskrit": "अजडं",
                  "english": "ajadam",
                  "root": "अ + जड",
                  "meaning": "without dullness"
              },
              {
                  "sanskrit": "विमलं",
                  "english": "vimalam",
                  "root": "विमल",
                  "meaning": "pure"
              },
              {
                  "sanskrit": "सदा",
                  "english": "sada",
                  "root": "सदा",
                  "meaning": "always"
              },
              {
                  "sanskrit": "तापत्रयापहम्",
                  "english": "tapatraya-apaham",
                  "root": "ताप + त्रय + अपह",
                  "meaning": "remover of the threefold suffering"
              }
          ]
      }
  ]
}, {
  "id": 23,
  "slug": "vyasaya-bhava-nashaya-shloka",
  "title": "Vyasaya Bhava Nashaya",
  "verses": [
      {
          "number": 1,
          "slug": "vyasaya-bhava-nashaya",
          "title": "Salutation to Vyasa and Madhwa",
          "text": "व्यासाय भवनाशाय श्रीशाय गुणराशये । हृद्याय शुद्धविद्याय मध्वाय च नमो नमः ॥",
          "meaning": "Salutations to Vyasa, who destroys worldly existence, to the Lord of Lakshmi (Shri), who is a treasure of virtues, to the one who is dear to the heart, who embodies pure knowledge, and to Madhvacharya. I offer my salutations again and again.",
          "explanation": "This verse expresses reverence for Vyasa, who is seen as the remover of ignorance and the cycle of birth and death. It also honors Vishnu (Sri), the Lord of Lakshmi, who is full of countless divine qualities. Finally, it pays tribute to Madhvacharya, who is the embodiment of pure knowledge and is dear to the heart of the devotees. By saluting these great beings, the devotee seeks their blessings for spiritual progress.",
          "wordByWord": [
              {
                  "sanskrit": "व्यासाय",
                  "english": "vyasaya",
                  "root": "व्यास",
                  "meaning": "to Vyasa"
              },
              {
                  "sanskrit": "भवनाशाय",
                  "english": "bhava-nashaya",
                  "root": "भव + नाश",
                  "meaning": "destroyer of worldly existence"
              },
              {
                  "sanskrit": "श्रीशाय",
                  "english": "shrishaya",
                  "root": "श्री + ईश",
                  "meaning": "Lord of Lakshmi"
              },
              {
                  "sanskrit": "गुणराशये",
                  "english": "guna-rashaye",
                  "root": "गुण + राशि",
                  "meaning": "treasure of virtues"
              },
              {
                  "sanskrit": "हृद्याय",
                  "english": "hridayaya",
                  "root": "हृदय",
                  "meaning": "dear to the heart"
              },
              {
                  "sanskrit": "शुद्धविद्याय",
                  "english": "shuddha-vidyaya",
                  "root": "शुद्ध + विद्या",
                  "meaning": "embodiment of pure knowledge"
              },
              {
                  "sanskrit": "मध्वाय",
                  "english": "madhvaya",
                  "root": "मध्व",
                  "meaning": "to Madhvacharya"
              },
              {
                  "sanskrit": "नमो",
                  "english": "namo",
                  "root": "नमः",
                  "meaning": "salutations"
              },
              {
                  "sanskrit": "नमः",
                  "english": "namaha",
                  "root": "नमः",
                  "meaning": "salutations"
              }
          ]
      }
  ]
},{
  "id": 24,
  "slug": "yad-hitamtava-bhaktaanaam-shloka",
  "title": "Yad Hitam Tava Bhaktaanaam",
  "verses": [
      {
          "number": 1,
          "slug": "yad-hitamtava-bhaktaanaam",
          "title": "Prayer to Narasimha for Devotee's Well-being",
          "text": "यद्धितम् तव भक्तानाम् | अस्माकम् नृहरे हरे | तदाशु कार्यम् कार्यज्ञ | प्रळयार्कायुतप्रभ ||",
          "meaning": "O Narasimha, Lord Hari! Please swiftly accomplish whatever is beneficial for your devotees and us. You, who are all-knowing, possess the brilliance of countless suns at the time of destruction.",
          "explanation": "This verse is a humble prayer to Lord Narasimha, asking Him to act swiftly in the best interest of His devotees. It acknowledges His infinite knowledge and His terrifyingly powerful form, which shines as bright as millions of suns at the time of the cosmic dissolution. The devotee seeks protection and guidance from the Lord, confident in His ability to bring about what is best.",
          "wordByWord": [
              {
                  "sanskrit": "यद्धितम्",
                  "english": "yad-hitam",
                  "root": "यद् + हित",
                  "meaning": "whatever is beneficial"
              },
              {
                  "sanskrit": "तव",
                  "english": "tava",
                  "root": "तव",
                  "meaning": "your"
              },
              {
                  "sanskrit": "भक्तानाम्",
                  "english": "bhaktaanaam",
                  "root": "भक्त",
                  "meaning": "for the devotees"
              },
              {
                  "sanskrit": "अस्माकम्",
                  "english": "asmaakam",
                  "root": "अस्माकम्",
                  "meaning": "for us"
              },
              {
                  "sanskrit": "नृहरे",
                  "english": "nrhare",
                  "root": "नृहरि",
                  "meaning": "O Narasimha (man-lion form of Vishnu)"
              },
              {
                  "sanskrit": "हरे",
                  "english": "hare",
                  "root": "हरि",
                  "meaning": "O Hari (Vishnu)"
              },
              {
                  "sanskrit": "तदाशु",
                  "english": "tad-ashu",
                  "root": "तत् + आशु",
                  "meaning": "swiftly"
              },
              {
                  "sanskrit": "कार्यम्",
                  "english": "kaaryam",
                  "root": "कार्य",
                  "meaning": "accomplish"
              },
              {
                  "sanskrit": "कार्यज्ञ",
                  "english": "kaaryajna",
                  "root": "कार्य + ज्ञ",
                  "meaning": "knower of actions (all-knowing)"
              },
              {
                  "sanskrit": "प्रळयार्कायुतप्रभ",
                  "english": "pralayarkayuta-prabha",
                  "root": "प्रळय + अर्क + आयुत + प्रभ",
                  "meaning": "brilliance of countless suns at the time of destruction"
              }
          ]
      }
  ]
}, {
  "id": 25,
  "slug": "shrimad-madhva-mate-shloka",
  "title": "Prameya shloka",
  "verses": [
      {
          "number": 1,
          "slug": "shrimad-madhva-mate",
          "title": "Essence of Madhva's Philosophy",
          "text": "श्रीमन्मध्वमते हरिः परतरः सत्यं जगत्तत्त्वतो। भेदो जीवगणा हरेरनुचराः नीचोच्चभावं गताः। मुक्तिर्नैजसुखानुभूतिरमला भक्तिश्च तत्साधनम्। ह्यक्षादित्रितयं प्रमाणमखिलाम्नायैकवेद्यो हरिः॥",
          "meaning": "In the revered Madhva philosophy, Hari (Vishnu) is the Supreme Being, the world is real and exists in its true essence. There is a distinct difference between the individual souls and Hari, with the souls taking varied statuses, high and low. Liberation is the pure experience of one’s innate bliss, and devotion is the means to attain it. Perception, inference, and verbal testimony are the three sources of knowledge, and Hari is known through the entirety of scriptural teachings.",
          "explanation": "This verse summarizes the key principles of Madhvacharya's Dvaita (Dualism) philosophy. It asserts that Lord Hari is the supreme reality, higher than all others, and the world is real, not an illusion. There is an inherent distinction (bheda) between the soul (jiva) and the Supreme Lord, and the jivas occupy different statuses. Liberation is understood as experiencing one’s inherent bliss, and devotion is the method to achieve it. The verse also mentions the three pramanas (sources of knowledge)—perception, inference, and scriptural testimony—and emphasizes that Hari is understood through the scriptural revelations (Amnaya).",
          "wordByWord": [
              {
                  "sanskrit": "श्रीमन्मध्वमते",
                  "english": "shrimad-madhva-mate",
                  "root": "श्रीमत् + मध्व + मते",
                  "meaning": "in the revered Madhva philosophy"
              },
              {
                  "sanskrit": "हरिः",
                  "english": "harih",
                  "root": "हरि",
                  "meaning": "Lord Hari (Vishnu)"
              },
              {
                  "sanskrit": "परतरः",
                  "english": "paratarah",
                  "root": "पर + तर",
                  "meaning": "the Supreme"
              },
              {
                  "sanskrit": "सत्यं",
                  "english": "satyam",
                  "root": "सत्य",
                  "meaning": "true"
              },
              {
                  "sanskrit": "जगत्",
                  "english": "jagat",
                  "root": "जगत्",
                  "meaning": "the world"
              },
              {
                  "sanskrit": "तत्त्वतः",
                  "english": "tattvatah",
                  "root": "तत्त्व",
                  "meaning": "in essence"
              },
              {
                  "sanskrit": "भेदः",
                  "english": "bhedah",
                  "root": "भेद",
                  "meaning": "difference"
              },
              {
                  "sanskrit": "जीवगणाः",
                  "english": "jivaganaah",
                  "root": "जीव + गण",
                  "meaning": "the group of souls"
              },
              {
                  "sanskrit": "हरेः",
                  "english": "hareh",
                  "root": "हरे",
                  "meaning": "of Hari"
              },
              {
                  "sanskrit": "अनुचराः",
                  "english": "anucharah",
                  "root": "अनुचर",
                  "meaning": "servants or followers"
              },
              {
                  "sanskrit": "नीचोच्चभावं",
                  "english": "neechocchabhavam",
                  "root": "नीच + उच्च + भाव",
                  "meaning": "in high and low status"
              },
              {
                  "sanskrit": "गताः",
                  "english": "gataah",
                  "root": "गम",
                  "meaning": "having attained"
              },
              {
                  "sanskrit": "मुक्तिः",
                  "english": "muktih",
                  "root": "मुक्ति",
                  "meaning": "liberation"
              },
              {
                  "sanskrit": "नैजसुख",
                  "english": "naija-sukha",
                  "root": "नैज + सुख",
                  "meaning": "one's own bliss"
              },
              {
                  "sanskrit": "अनुभूतिः",
                  "english": "anubhutih",
                  "root": "अनुभूति",
                  "meaning": "experience"
              },
              {
                  "sanskrit": "अमला",
                  "english": "amala",
                  "root": "अमल",
                  "meaning": "pure"
              },
              {
                  "sanskrit": "भक्तिः",
                  "english": "bhaktih",
                  "root": "भक्ति",
                  "meaning": "devotion"
              },
              {
                  "sanskrit": "तत्साधनम्",
                  "english": "tat-sadhanam",
                  "root": "तत् + साधन",
                  "meaning": "the means for that"
              },
              {
                  "sanskrit": "ह्यक्षादित्रितयं",
                  "english": "hyakshaditritayam",
                  "root": "ह्यक्ष + आदि + त्रितय",
                  "meaning": "perception, inference, and verbal testimony"
              },
              {
                  "sanskrit": "प्रमाणम्",
                  "english": "pramanam",
                  "root": "प्रमाण",
                  "meaning": "means of knowledge"
              },
              {
                  "sanskrit": "अखिलाम्नाय",
                  "english": "akhilaamnaya",
                  "root": "अखिल + आम्नाय",
                  "meaning": "entirety of the scriptures"
              },
              {
                  "sanskrit": "वेद्यः",
                  "english": "vedyah",
                  "root": "विद्",
                  "meaning": "to be known"
              },
              {
                  "sanskrit": "हरिः",
                  "english": "harih",
                  "root": "हरि",
                  "meaning": "Lord Hari"
              }
          ]
      }
  ]
}, {
  "id": 26,
  "slug": "urdhva-mulo-vakshakha",
  "title": "Urdhva Moolo Avak Shakha",
  "verses": [
      {
          "number": 1,
          "slug": "urdhva-mulo-vakshakha",
          "title": "Urdhva Moolo Avak Shakha",
          "text": "ऊर्ध्वमूलोऽवाक्शाख एषोऽश्वत्थः सनातनः।\nतदेव शुक्रं तद्ब्रह्म तदेवामृतमुच्यते।\nतस्मिंल्लोकाः श्रिताः सर्वे तदु नात्येति कश्चन॥",
          "meaning": "This Ashvattha tree has its roots upwards and its branches downwards; it is eternal. That (which sustains it) is pure, it is Brahman, and it is called immortal. All the worlds rest in it, and no one can transcend it.",
          "explanation": "The verse speaks of the Ashvattha tree, a metaphor for the material universe. The roots represent the divine, which is eternal and pure (Brahman), and from it the material world emanates. The branches symbolize the manifested world. The verse emphasizes that everything rests in the eternal Brahman, which is the source of life and the ultimate reality. No one can surpass or transcend this truth, as it is the foundation of existence.",
          "wordByWord": [
              {
                  "sanskrit": "ऊर्ध्वमूलः",
                  "english": "urdhva-mulah",
                  "root": "ऊर्ध्व + मूल",
                  "meaning": "with its roots upward"
              },
              {
                  "sanskrit": "अवाक्शाखः",
                  "english": "avak-shakha",
                  "root": "अवाक् + शाख",
                  "meaning": "branches downward"
              },
              {
                  "sanskrit": "एषः",
                  "english": "eshah",
                  "root": "एष",
                  "meaning": "this"
              },
              {
                  "sanskrit": "अश्वत्थः",
                  "english": "ashvatthah",
                  "root": "अश्वत्थ",
                  "meaning": "Ashvattha (peepal tree, representing the material world)"
              },
              {
                  "sanskrit": "सनातनः",
                  "english": "sanatanah",
                  "root": "सनातन",
                  "meaning": "eternal"
              },
              {
                  "sanskrit": "तत्",
                  "english": "tat",
                  "root": "तत्",
                  "meaning": "that"
              },
              {
                  "sanskrit": "एव",
                  "english": "eva",
                  "root": "एव",
                  "meaning": "indeed"
              },
              {
                  "sanskrit": "शुक्रं",
                  "english": "shukram",
                  "root": "शुक्र",
                  "meaning": "pure"
              },
              {
                  "sanskrit": "तत्",
                  "english": "tat",
                  "root": "तत्",
                  "meaning": "that"
              },
              {
                  "sanskrit": "ब्रह्म",
                  "english": "brahma",
                  "root": "ब्रह्म",
                  "meaning": "Brahman (the ultimate reality)"
              },
              {
                  "sanskrit": "तत्",
                  "english": "tat",
                  "root": "तत्",
                  "meaning": "that"
              },
              {
                  "sanskrit": "एव",
                  "english": "eva",
                  "root": "एव",
                  "meaning": "indeed"
              },
              {
                  "sanskrit": "अमृतम्",
                  "english": "amritam",
                  "root": "अमृत",
                  "meaning": "immortal"
              },
              {
                  "sanskrit": "उच्यते",
                  "english": "uchyate",
                  "root": "उच्",
                  "meaning": "is called"
              },
              {
                  "sanskrit": "तस्मिन्",
                  "english": "tasmin",
                  "root": "तस्मिन्",
                  "meaning": "in it"
              },
              {
                  "sanskrit": "लोकाः",
                  "english": "lokah",
                  "root": "लोक",
                  "meaning": "worlds"
              },
              {
                  "sanskrit": "श्रिताः",
                  "english": "shritah",
                  "root": "श्रि",
                  "meaning": "rest upon"
              },
              {
                  "sanskrit": "सर्वे",
                  "english": "sarve",
                  "root": "सर्व",
                  "meaning": "all"
              },
              {
                  "sanskrit": "तत्",
                  "english": "tat",
                  "root": "तत्",
                  "meaning": "that"
              },
              {
                  "sanskrit": "न",
                  "english": "na",
                  "root": "न",
                  "meaning": "not"
              },
              {
                  "sanskrit": "अत्येति",
                  "english": "atyeti",
                  "root": "अति + इ",
                  "meaning": "transcend"
              },
              {
                  "sanskrit": "कश्चन",
                  "english": "kashchana",
                  "root": "कश्चन",
                  "meaning": "anyone"
              }
          ]
      }
  ]
}, {
  "id": 27,
  "slug": "janmadyasya-yatah",
  "title": "Invocation to the Supreme Truth",
  "verses": [{
      "number": 1,
      "slug": "janmadyasya-yatah",
      "title": "Janmady Asya Yatah",
      "text": "जन्माद्यस्य यतोऽन्वयादितरतः चार्थेष्वभिज्ञः स्वराट्\nतेने ब्रह्म हृदा य आदिकवये मुह्यन्ति यत् सूरयः ।\nतेजोवारिमृदां यथा विनिमयो यत्र त्रिसर्गोऽमृषा\nधाम्ना स्वेन सदा निरस्तकुहकं सत्यं परं धीमहि ।",
      "meaning": "We meditate upon the Supreme Truth, from whom the universe originates, who is all-knowing and independent. He imparted Vedic knowledge to the first sage, and even the wisest are confounded by His mysteries. The material world, consisting of fire, water, and earth, exists by His will and is a real creation. His light dispels all delusion, and we meditate upon that eternal Truth, free from deception.",
      "explanation": "In Madhvacharya’s Dvaita philosophy, this verse affirms the real and eternal distinction between God (Hari) and the universe. Vishnu is the independent, omniscient creator, preserver, and destroyer of the universe. The world is not illusory but a true creation that reflects the divine will. The three elements of creation (fire, water, and earth) are governed by His power. The Supreme Truth, free from all deception and imperfections, guides the universe and illuminates the reality beyond material existence.",
      "wordByWord": [{
              "sanskrit": "जन्मादि",
              "english": "janma-adi",
              "root": "जन्म + आदि",
              "meaning": "originating from creation"
          },
          {
              "sanskrit": "अस्य",
              "english": "asya",
              "root": "अस्य",
              "meaning": "of this universe"
          },
          {
              "sanskrit": "यतः",
              "english": "yatah",
              "root": "यत:",
              "meaning": "from whom"
          },
          {
              "sanskrit": "अन्वयात्",
              "english": "anvayat",
              "root": "अन्वय",
              "meaning": "direct cause"
          },
          {
              "sanskrit": "इतरतः",
              "english": "itaratah",
              "root": "इत:",
              "meaning": "indirect cause"
          },
          {
              "sanskrit": "अर्थेषु",
              "english": "artheshu",
              "root": "अर्थ",
              "meaning": "in all matters"
          },
          {
              "sanskrit": "अभिज्ञः",
              "english": "abhijnah",
              "root": "अभिज्ञ",
              "meaning": "fully knowledgeable"
          },
          {
              "sanskrit": "स्वराट्",
              "english": "svarat",
              "root": "स्वराट",
              "meaning": "independent ruler"
          },
          {
              "sanskrit": "तेन",
              "english": "tena",
              "root": "तेन",
              "meaning": "by Him"
          },
          {
              "sanskrit": "ब्रह्म",
              "english": "brahma",
              "root": "ब्रह्म",
              "meaning": "Vedic knowledge"
          },
          {
              "sanskrit": "हृदा",
              "english": "hrida",
              "root": "हृद",
              "meaning": "by the heart"
          },
          {
              "sanskrit": "आदि कवये",
              "english": "adi-kavaye",
              "root": "आदि + कवि",
              "meaning": "to the first sage"
          },
          {
              "sanskrit": "मुह्यन्ति",
              "english": "muhyanti",
              "root": "मुह्",
              "meaning": "are confounded"
          },
          {
              "sanskrit": "सूरयः",
              "english": "surayah",
              "root": "सूर",
              "meaning": "wise people"
          },
          {
              "sanskrit": "तेजः",
              "english": "tejah",
              "root": "तेजः",
              "meaning": "light"
          },
          {
              "sanskrit": "वारि",
              "english": "vari",
              "root": "वारि",
              "meaning": "water"
          },
          {
              "sanskrit": "मृदा",
              "english": "mrida",
              "root": "मृद्",
              "meaning": "earth"
          },
          {
              "sanskrit": "त्रिसर्ग",
              "english": "trisarga",
              "root": "त्रि + सर्ग",
              "meaning": "the three elements of creation"
          },
          {
              "sanskrit": "धाम्ना",
              "english": "dhamna",
              "root": "धाम",
              "meaning": "by His light"
          },
          {
              "sanskrit": "स्वेन",
              "english": "svena",
              "root": "स्व",
              "meaning": "His own"
          },
          {
              "sanskrit": "निरस्त",
              "english": "nirasta",
              "root": "निरस्त",
              "meaning": "removed"
          },
          {
              "sanskrit": "कुहकं",
              "english": "kuhakam",
              "root": "कुहक",
              "meaning": "deception"
          },
          {
              "sanskrit": "सत्यं",
              "english": "satyam",
              "root": "सत्य",
              "meaning": "truth"
          }
      ]
  }]
}, {
  "id": 28,
  "slug": "sri-manyu-sukta",
  "title": "Śrī Manyu Sūkta",
  "verses": [
      {
          "number": 1,
          "slug": "verse-1",
          "title": "Invocation to Manyu",
          "text": "यस्ते मन्योऽविधद् वज्र सायक सहो ओजः पुष्यति विश्वमानुषक ।\nसायाम दासमार्यन्त्वया युजा सहस्कृतेन सहसा सहस्वता ॥ १ ॥",
          "meaning": "O Manyu (Divine Wrath), whose thunderbolt-like arrows pierce, whose strength and energy uphold all beings; with you as our ally, endowed with abundant vigor and might, may we conquer both the noble and the ignoble.",
          "explanation": "In this verse, the devotee invokes Manyu, personified as Divine Wrath or righteous anger, a form of Lord Narayana according to Madhwa philosophy. The verse praises Manyu's invincible power that sustains the universe and seeks His assistance in overcoming both internal and external adversaries. Aligning with Madhwa-Shastra principles, it emphasizes complete reliance on the Supreme Being for spiritual victory and moral righteousness.",
          "wordByWord": [
              {
                  "sanskrit": "यः",
                  "english": "He who",
                  "root": "यद्",
                  "meaning": "which; who"
              },
              {
                  "sanskrit": "ते",
                  "english": "your",
                  "root": "त्वद्",
                  "meaning": "you"
              },
              {
                  "sanskrit": "मन्यः",
                  "english": "O Manyu",
                  "root": "मन्यु",
                  "meaning": "Divine Wrath; a deity"
              },
              {
                  "sanskrit": "अविधत्",
                  "english": "has hurled",
                  "root": "अविध्",
                  "meaning": "to pierce; to hurl"
              },
              {
                  "sanskrit": "वज्र",
                  "english": "thunderbolt",
                  "root": "वज्र",
                  "meaning": "thunderbolt; weapon"
              },
              {
                  "sanskrit": "सायक",
                  "english": "arrows",
                  "root": "सायक",
                  "meaning": "arrow"
              },
              {
                  "sanskrit": "सहो",
                  "english": "strength",
                  "root": "सहस्",
                  "meaning": "might; strength"
              },
              {
                  "sanskrit": "ओजः",
                  "english": "vigor",
                  "root": "ओजस्",
                  "meaning": "energy; vigor"
              },
              {
                  "sanskrit": "पुष्यति",
                  "english": "nourishes",
                  "root": "पुष्",
                  "meaning": "to nourish; to strengthen"
              },
              {
                  "sanskrit": "विश्व",
                  "english": "the universe",
                  "root": "विश्व",
                  "meaning": "all; universe"
              },
              {
                  "sanskrit": "मानुषक",
                  "english": "beings",
                  "root": "मानुष",
                  "meaning": "human; being"
              },
              {
                  "sanskrit": "सायाम",
                  "english": "may we conquer",
                  "root": "सा",
                  "meaning": "to conquer"
              },
              {
                  "sanskrit": "दास",
                  "english": "servants",
                  "root": "दास",
                  "meaning": "servant; slave"
              },
              {
                  "sanskrit": "आर्यन्",
                  "english": "noble ones",
                  "root": "आर्य",
                  "meaning": "noble; honorable"
              },
              {
                  "sanskrit": "त्वया",
                  "english": "with you",
                  "root": "त्वद्",
                  "meaning": "you"
              },
              {
                  "sanskrit": "युजा",
                  "english": "as ally",
                  "root": "युज्",
                  "meaning": "to join; to yoke"
              },
              {
                  "sanskrit": "सहस्कृतेन",
                  "english": "endowed with might",
                  "root": "सहस्-कृत",
                  "meaning": "made powerful"
              },
              {
                  "sanskrit": "सहसा",
                  "english": "with vigor",
                  "root": "सहस्",
                  "meaning": "vigor; strength"
              },
              {
                  "sanskrit": "सहस्वता",
                  "english": "with strength",
                  "root": "सहस्",
                  "meaning": "strength"
              }
          ]
      },
      {
          "number": 2,
          "slug": "verse-2",
          "title": "Manyu as Divine Manifestation",
          "text": "मन्युरिन्द्रो मन्युरेवास देवो मन्युर् होतावरुणो जातवेदाः ।\nमन्युं विष इळते मानुषीर्याः पाहि नो मन्यो तपसा सजोषाः ॥ २ ॥",
          "meaning": "Manyu is Indra; Manyu indeed is the divine deity; Manyu is the Hotā priest, Varuṇa, and Jātavedā (Agni). People who seek prosperity worship Manyu. O Manyu, united with your fervor, protect us.",
          "explanation": "This verse identifies Manyu with various deities like Indra, Varuṇa, and Agni, signifying the non-dual nature of the Supreme Being in Madhwa philosophy. It reflects the belief that all divine functions are manifestations of Lord Narayana. The devotee seeks protection and guidance from Manyu, recognizing Him as the ultimate source of strength and wisdom.",
          "wordByWord": [
              {
                  "sanskrit": "मन्युः",
                  "english": "Manyu",
                  "root": "मन्यु",
                  "meaning": "Divine Wrath; deity"
              },
              {
                  "sanskrit": "इन्द्रः",
                  "english": "is Indra",
                  "root": "इन्द्र",
                  "meaning": "Indra; king of gods"
              },
              {
                  "sanskrit": "मन्युः",
                  "english": "Manyu",
                  "root": "मन्यु",
                  "meaning": "Divine Wrath"
              },
              {
                  "sanskrit": "एव",
                  "english": "indeed",
                  "root": "एव",
                  "meaning": "indeed; certainly"
              },
              {
                  "sanskrit": "अस",
                  "english": "is",
                  "root": "अस्",
                  "meaning": "to be"
              },
              {
                  "sanskrit": "देवः",
                  "english": "the deity",
                  "root": "देव",
                  "meaning": "god; deity"
              },
              {
                  "sanskrit": "मन्युः",
                  "english": "Manyu",
                  "root": "मन्यु",
                  "meaning": "Divine Wrath"
              },
              {
                  "sanskrit": "होता",
                  "english": "Hotā priest",
                  "root": "होता",
                  "meaning": "priest who invokes gods"
              },
              {
                  "sanskrit": "वरुणः",
                  "english": "Varuṇa",
                  "root": "वरुण",
                  "meaning": "god of water"
              },
              {
                  "sanskrit": "जातवेदाः",
                  "english": "Jātavedā (Agni)",
                  "root": "जातवेदा",
                  "meaning": "all-knowing; Agni"
              },
              {
                  "sanskrit": "मन्यम्",
                  "english": "Manyu",
                  "root": "मन्यु",
                  "meaning": "Divine Wrath"
              },
              {
                  "sanskrit": "विष",
                  "english": "people",
                  "root": "विष्",
                  "meaning": "people; subjects"
              },
              {
                  "sanskrit": "इळते",
                  "english": "worship",
                  "root": "इड्",
                  "meaning": "to praise; to worship"
              },
              {
                  "sanskrit": "मानुषीः",
                  "english": "seekers",
                  "root": "मानुष",
                  "meaning": "human"
              },
              {
                  "sanskrit": "याः",
                  "english": "who",
                  "root": "यद्",
                  "meaning": "which; who"
              },
              {
                  "sanskrit": "पाहि",
                  "english": "protect",
                  "root": "पा",
                  "meaning": "to protect"
              },
              {
                  "sanskrit": "नः",
                  "english": "us",
                  "root": "अस्मद्",
                  "meaning": "we; us"
              },
              {
                  "sanskrit": "मन्यः",
                  "english": "O Manyu",
                  "root": "मन्यु",
                  "meaning": "Divine Wrath"
              },
              {
                  "sanskrit": "तपसा",
                  "english": "with fervor",
                  "root": "तपस्",
                  "meaning": "austerity; fervor"
              },
              {
                  "sanskrit": "सजोषाः",
                  "english": "united",
                  "root": "स-जोष",
                  "meaning": "together; united"
              }
          ]
      },
      {
          "number": 3,
          "slug": "verse-3",
          "title": "Request for Victory",
          "text": "अभीहि मन्यो तवसस्तवीयान् तपसा युजा विजहि शत्रून् ।\nअमित्रहा वृत्रहा दस्युहा च विश्वा वसून्याभरा त्वन्नः ॥ ३ ॥",
          "meaning": "O Manyu, mightier than the mighty, approach with your fervor and destroy our enemies. Slayer of foes, vanquisher of obstacles and demons, bestow upon us all treasures through your grace.",
          "explanation": "The devotee calls upon Manyu to annihilate all forms of negativity and obstacles, both external enemies and internal vices. In line with Madhwa philosophy, this reflects the soul's plea to the Supreme Being for liberation from ignorance and the bestowal of divine knowledge and blessings.",
          "wordByWord": [
              {
                  "sanskrit": "अभीहि",
                  "english": "approach",
                  "root": "अभि-इ",
                  "meaning": "to come near"
              },
              {
                  "sanskrit": "मन्यः",
                  "english": "O Manyu",
                  "root": "मन्यु",
                  "meaning": "Divine Wrath"
              },
              {
                  "sanskrit": "तवसः",
                  "english": "of the mighty",
                  "root": "तवस्",
                  "meaning": "strong; mighty"
              },
              {
                  "sanskrit": "तवीयान्",
                  "english": "mightier",
                  "root": "तवीयस्",
                  "meaning": "more powerful"
              },
              {
                  "sanskrit": "तपसा",
                  "english": "with fervor",
                  "root": "तपस्",
                  "meaning": "austerity; fervor"
              },
              {
                  "sanskrit": "युजा",
                  "english": "with union",
                  "root": "युज्",
                  "meaning": "to join; to yoke"
              },
              {
                  "sanskrit": "विजहि",
                  "english": "destroy",
                  "root": "वि-जहि",
                  "meaning": "to destroy"
              },
              {
                  "sanskrit": "शत्रून्",
                  "english": "enemies",
                  "root": "शत्रु",
                  "meaning": "enemy"
              },
              {
                  "sanskrit": "अमित्रहा",
                  "english": "slayer of foes",
                  "root": "अमित्र-हा",
                  "meaning": "destroyer of enemies"
              },
              {
                  "sanskrit": "वृत्रहा",
                  "english": "slayer of Vṛtra",
                  "root": "वृत्र-हा",
                  "meaning": "destroyer of obstacles"
              },
              {
                  "sanskrit": "दस्युहा",
                  "english": "slayer of demons",
                  "root": "दस्यु-हा",
                  "meaning": "destroyer of demons"
              },
              {
                  "sanskrit": "च",
                  "english": "and",
                  "root": "च",
                  "meaning": "and"
              },
              {
                  "sanskrit": "विश्वा",
                  "english": "all",
                  "root": "विश्व",
                  "meaning": "all; universe"
              },
              {
                  "sanskrit": "वसूनि",
                  "english": "treasures",
                  "root": "वसु",
                  "meaning": "wealth; treasure"
              },
              {
                  "sanskrit": "आभरा",
                  "english": "bestow",
                  "root": "आ-भृ",
                  "meaning": "to bring; to bestow"
              },
              {
                  "sanskrit": "त्वन्",
                  "english": "your",
                  "root": "त्वद्",
                  "meaning": "you"
              },
              {
                  "sanskrit": "नः",
                  "english": "us",
                  "root": "अस्मद्",
                  "meaning": "we; us"
              }
          ]
      },
      {
          "number": 4,
          "slug": "verse-4",
          "title": "Acknowledging Manyu's Power",
          "text": "त्वं हि मन्यो अभिभूत्योजाः स्वयम्भूर्भामो अभिमातिषाहः ।\nविश्वचर्षणिः सहुरिः सहावानस्मास्वोजः पृथनासु धेहि ॥ ४ ॥",
          "meaning": "You indeed, O Manyu, are overpowering and mighty, self-existent, shining, destroyer of foes. O powerful one, O victorious one, bestow strength upon us in battles.",
          "explanation": "This verse extols Manyu's inherent and self-manifested power, recognizing Him as the ultimate source of victory and brilliance. In Madhwa philosophy, this emphasizes the soul's dependence on the Supreme Being's grace for overcoming challenges and achieving spiritual success.",
          "wordByWord": [
              {
                  "sanskrit": "त्वम्",
                  "english": "You",
                  "root": "त्वद्",
                  "meaning": "you"
              },
              {
                  "sanskrit": "हि",
                  "english": "indeed",
                  "root": "हि",
                  "meaning": "indeed; because"
              },
              {
                  "sanskrit": "मन्यः",
                  "english": "O Manyu",
                  "root": "मन्यु",
                  "meaning": "Divine Wrath"
              },
              {
                  "sanskrit": "अभिभूति",
                  "english": "overpowering",
                  "root": "अभि-भू",
                  "meaning": "to overcome"
              },
              {
                  "sanskrit": "ओजाः",
                  "english": "mighty",
                  "root": "ओजस्",
                  "meaning": "vigor; strength"
              },
              {
                  "sanskrit": "स्वयम्भूः",
                  "english": "self-existent",
                  "root": "स्वयम्-भू",
                  "meaning": "self-manifested"
              },
              {
                  "sanskrit": "भामः",
                  "english": "shining",
                  "root": "भा",
                  "meaning": "to shine"
              },
              {
                  "sanskrit": "अभिमातिषाहः",
                  "english": "destroyer of foes",
                  "root": "अभिमाति-शाह",
                  "meaning": "crusher of enemies"
              },
              {
                  "sanskrit": "विश्वचर्षणिः",
                  "english": "lord of all beings",
                  "root": "विश्व-चर्-षणि",
                  "meaning": "all-moving beings"
              },
              {
                  "sanskrit": "सहुरिः",
                  "english": "powerful one",
                  "root": "सहुरि",
                  "meaning": "mighty"
              },
              {
                  "sanskrit": "सहावान्",
                  "english": "victorious one",
                  "root": "सह-वान्",
                  "meaning": "possessing strength"
              },
              {
                  "sanskrit": "अस्मासु",
                  "english": "upon us",
                  "root": "अस्मद्",
                  "meaning": "we; us"
              },
              {
                  "sanskrit": "ओजः",
                  "english": "strength",
                  "root": "ओजस्",
                  "meaning": "vigor; energy"
              },
              {
                  "sanskrit": "पृथनासु",
                  "english": "in battles",
                  "root": "पृथना",
                  "meaning": "battle; war"
              },
              {
                  "sanskrit": "धेहि",
                  "english": "bestow",
                  "root": "धा",
                  "meaning": "to place; to give"
              }
          ]
      },
      {
          "number": 5,
          "slug": "verse-5",
          "title": "Seeking Empowerment",
          "text": "अभागः सन्नप परेतो अस्मि तव क्रत्वा तविषस्य प्रचेतः ।\nतं त्वा मन्यो अकृतुर् जिहीलाहं स्वातनूर्बलदेयाय मेहि ॥ ५ ॥",
          "meaning": "Being unfortunate and devoid of vitality, I am aware of your powerful will. O Manyu, I seek you earnestly; come to grant strength to your devotee.",
          "explanation": "The devotee expresses humility, acknowledging personal limitations and seeking Manyu's grace for empowerment. In Madhwa-Shastra, this reflects the soul's dependence on God for spiritual strength and progress, recognizing that true power comes from the Divine.",
          "wordByWord": [
              {
                  "sanskrit": "अभागः",
                  "english": "unfortunate",
                  "root": "अ-भाग",
                  "meaning": "without fortune"
              },
              {
                  "sanskrit": "सन्",
                  "english": "being",
                  "root": "अस्",
                  "meaning": "to be"
              },
              {
                  "sanskrit": "अप",
                  "english": "without",
                  "root": "अप",
                  "meaning": "away; without"
              },
              {
                  "sanskrit": "परेतः",
                  "english": "devoid of vitality",
                  "root": "पर-इत",
                  "meaning": "gone beyond"
              },
              {
                  "sanskrit": "अस्मि",
                  "english": "I am",
                  "root": "अस्",
                  "meaning": "to be"
              },
              {
                  "sanskrit": "तव",
                  "english": "your",
                  "root": "त्वद्",
                  "meaning": "you"
              },
              {
                  "sanskrit": "क्रत्वा",
                  "english": "by will",
                  "root": "क्रत्",
                  "meaning": "will; action"
              },
              {
                  "sanskrit": "तविषस्य",
                  "english": "of power",
                  "root": "तविषि",
                  "meaning": "energy; power"
              },
              {
                  "sanskrit": "प्रचेतः",
                  "english": "aware",
                  "root": "प्र-चेतस्",
                  "meaning": "conscious; aware"
              },
              {
                  "sanskrit": "तम्",
                  "english": "that",
                  "root": "तद्",
                  "meaning": "that"
              },
              {
                  "sanskrit": "त्वा",
                  "english": "you",
                  "root": "त्वद्",
                  "meaning": "you"
              },
              {
                  "sanskrit": "मन्यः",
                  "english": "O Manyu",
                  "root": "मन्यु",
                  "meaning": "Divine Wrath"
              },
              {
                  "sanskrit": "अकृतुः",
                  "english": "unaccomplished",
                  "root": "अ-कृतु",
                  "meaning": "without action"
              },
              {
                  "sanskrit": "जिहीलाहम्",
                  "english": "I seek",
                  "root": "जिहीळ",
                  "meaning": "to desire"
              },
              {
                  "sanskrit": "स्व",
                  "english": "own",
                  "root": "स्व",
                  "meaning": "own"
              },
              {
                  "sanskrit": "आतनुः",
                  "english": "body",
                  "root": "आतनु",
                  "meaning": "body"
              },
              {
                  "sanskrit": "बलदेयाय",
                  "english": "to grant strength",
                  "root": "बल-देय",
                  "meaning": "to give strength"
              },
              {
                  "sanskrit": "मेहि",
                  "english": "come",
                  "root": "मा-इ",
                  "meaning": "to come"
              }
          ]
      },
      {
          "number": 6,
          "slug": "verse-6",
          "title": "Calling for Assistance",
          "text": "अयन्ते अस्म्युप मे ह्यर्वाङ् प्रतीची नः सहुरे विश्वधायः ।\nमन्यो वज्रिन्नभि मामाव वृत्र्स्वहनावा दस्यून् ऋत बोध्यापेः ॥ ६ ॥",
          "meaning": "Here I am, coming towards you, O mighty one who sustains all. O Manyu wielding the thunderbolt, protect me; destroy the demons. Awaken the truth for us.",
          "explanation": "The devotee approaches Manyu with reverence, seeking protection and the destruction of negative forces. In Madhwa philosophy, this represents the soul's journey towards God, seeking enlightenment and the removal of ignorance through divine intervention.",
          "wordByWord": [
              {
                  "sanskrit": "अयम्",
                  "english": "this",
                  "root": "इदम्",
                  "meaning": "this"
              },
              {
                  "sanskrit": "ते",
                  "english": "to you",
                  "root": "त्वद्",
                  "meaning": "you"
              },
              {
                  "sanskrit": "अस्मि",
                  "english": "I am",
                  "root": "अस्",
                  "meaning": "to be"
              },
              {
                  "sanskrit": "उप",
                  "english": "near",
                  "root": "उप",
                  "meaning": "near; towards"
              },
              {
                  "sanskrit": "मे",
                  "english": "me",
                  "root": "मद्",
                  "meaning": "I; me"
              },
              {
                  "sanskrit": "हि",
                  "english": "indeed",
                  "root": "हि",
                  "meaning": "indeed; because"
              },
              {
                  "sanskrit": "अर्वाक्",
                  "english": "coming",
                  "root": "अर्वाक्",
                  "meaning": "approaching"
              },
              {
                  "sanskrit": "प्रतीची",
                  "english": "towards",
                  "root": "प्रतीच्",
                  "meaning": "towards"
              },
              {
                  "sanskrit": "नः",
                  "english": "us",
                  "root": "अस्मद्",
                  "meaning": "we; us"
              },
              {
                  "sanskrit": "सहुरे",
                  "english": "O mighty one",
                  "root": "सहुरि",
                  "meaning": "mighty"
              },
              {
                  "sanskrit": "विश्वधायः",
                  "english": "sustainer of all",
                  "root": "विश्व-धाय",
                  "meaning": "upholder of universe"
              },
              {
                  "sanskrit": "मन्यः",
                  "english": "O Manyu",
                  "root": "मन्यु",
                  "meaning": "Divine Wrath"
              },
              {
                  "sanskrit": "वज्रिन्",
                  "english": "wielder of thunderbolt",
                  "root": "वज्रिन्",
                  "meaning": "holding thunderbolt"
              },
              {
                  "sanskrit": "अभि",
                  "english": "protect",
                  "root": "अभि",
                  "meaning": "towards; protect"
              },
              {
                  "sanskrit": "माम्",
                  "english": "me",
                  "root": "मद्",
                  "meaning": "I; me"
              },
              {
                  "sanskrit": "आ",
                  "english": "come",
                  "root": "आ",
                  "meaning": "towards; come"
              },
              {
                  "sanskrit": "वृत्र्स्व",
                  "english": "destroy demons",
                  "root": "वृत्र्-स्व",
                  "meaning": "slay obstacles"
              },
              {
                  "sanskrit": "हनावा",
                  "english": "kill",
                  "root": "हन",
                  "meaning": "to kill"
              },
              {
                  "sanskrit": "दस्यून्",
                  "english": "demons",
                  "root": "दस्यु",
                  "meaning": "demon; thief"
              },
              {
                  "sanskrit": "ऋत",
                  "english": "truth",
                  "root": "ऋत",
                  "meaning": "truth; order"
              },
              {
                  "sanskrit": "बोधि",
                  "english": "awaken",
                  "root": "बुध्",
                  "meaning": "to know; awaken"
              },
              {
                  "sanskrit": "आपेः",
                  "english": "for us",
                  "root": "आप",
                  "meaning": "to obtain"
              }
          ]
      },
      {
          "number": 7,
          "slug": "verse-7",
          "title": "Offering and Invocation",
          "text": "अभि प्रेहि दक्षिणतो भवामेऽधा वृत्राणि जङ्घनाभ भूरी ।\nजुहोमि ते धरुणम् मध्वो अग्रमुभा उपांशु प्रथमा पिबावा ॥ ७ ॥",
          "meaning": "Advance towards us from the right, O Manyu, and slay numerous obstacles. I offer you the foremost libation of honey; may we both silently partake of the first offerings.",
          "explanation": "In this verse, the devotee invites Manyu to approach from the auspicious right side to eliminate all impediments. The offering of the finest honey symbolizes pure devotion. Sharing the first oblation signifies a deep communion with the Divine. According to Madhwa-Shastra, this reflects the soul's sincere devotion and desire for God's grace to overcome challenges on the spiritual path.",
          "wordByWord": [
              {
                  "sanskrit": "अभि",
                  "english": "towards",
                  "root": "अभि",
                  "meaning": "towards; against"
              },
              {
                  "sanskrit": "प्रेहि",
                  "english": "advance",
                  "root": "प्र-इ",
                  "meaning": "to go forth"
              },
              {
                  "sanskrit": "दक्षिणतः",
                  "english": "from the right",
                  "root": "दक्षिण",
                  "meaning": "right; south"
              },
              {
                  "sanskrit": "भव",
                  "english": "become",
                  "root": "भू",
                  "meaning": "to be; to become"
              },
              {
                  "sanskrit": "अमे",
                  "english": "for us",
                  "root": "अस्मद्",
                  "meaning": "we; us"
              },
              {
                  "sanskrit": "अधा",
                  "english": "then",
                  "root": "अधा",
                  "meaning": "then; thereafter"
              },
              {
                  "sanskrit": "वृत्राणि",
                  "english": "obstacles",
                  "root": "वृत्र",
                  "meaning": "obstacle; demon"
              },
              {
                  "sanskrit": "जङ्घनाः",
                  "english": "slay",
                  "root": "हन्",
                  "meaning": "to kill; to slay"
              },
              {
                  "sanskrit": "भूरी",
                  "english": "many",
                  "root": "भूरि",
                  "meaning": "much; abundant"
              },
              {
                  "sanskrit": "जुहोमि",
                  "english": "I offer",
                  "root": "हु",
                  "meaning": "to offer; to sacrifice"
              },
              {
                  "sanskrit": "ते",
                  "english": "to you",
                  "root": "त्वद्",
                  "meaning": "you"
              },
              {
                  "sanskrit": "धरुणम्",
                  "english": "libation",
                  "root": "धरुण",
                  "meaning": "vessel; libation"
              },
              {
                  "sanskrit": "मध्वः",
                  "english": "of honey",
                  "root": "मधु",
                  "meaning": "honey; sweetness"
              },
              {
                  "sanskrit": "अग्रम्",
                  "english": "foremost",
                  "root": "अग्र",
                  "meaning": "first; foremost"
              },
              {
                  "sanskrit": "उभा",
                  "english": "both",
                  "root": "उभ",
                  "meaning": "both"
              },
              {
                  "sanskrit": "उपांशु",
                  "english": "silently",
                  "root": "उपांशु",
                  "meaning": "in a low voice; silently"
              },
              {
                  "sanskrit": "प्रथमा",
                  "english": "first",
                  "root": "प्रथम",
                  "meaning": "first; primary"
              },
              {
                  "sanskrit": "पिबावा",
                  "english": "may we drink",
                  "root": "पा",
                  "meaning": "to drink"
              }
          ]
      },
      {
          "number": 8,
          "slug": "verse-8",
          "title": "Marching with Divine Energy",
          "text": "त्वया मन्यो सरथमारुजन्तो हर्षमाणासो धृषिता मरुत्वः ।\nतिग्मेषव आयुधा संशिशानाः अभि प्रयन्तु नरः अग्निरूपाः ॥ ८ ॥",
          "meaning": "With you, O Manyu, mounting the chariot, rejoicing and emboldened like the Maruts; sharpening their sharp weapons, may the men, fiery in form, advance against the foes.",
          "explanation": "The devotee seeks to align with Manyu, gaining courage and joy akin to the Maruts (storm deities). The fiery form symbolizes spiritual zeal. According to Madhwa-Shastra, this represents the devotee's readiness to confront spiritual challenges with divine support, embodying strength and enthusiasm granted by the Supreme.",
          "wordByWord": [
              {
                  "sanskrit": "त्वया",
                  "english": "with you",
                  "root": "त्वद्",
                  "meaning": "you"
              },
              {
                  "sanskrit": "मन्यः",
                  "english": "O Manyu",
                  "root": "मन्यु",
                  "meaning": "divine wrath; righteous anger"
              },
              {
                  "sanskrit": "सरथम्",
                  "english": "with chariot",
                  "root": "स-रथ",
                  "meaning": "together with chariot"
              },
              {
                  "sanskrit": "आरुजन्तः",
                  "english": "mounting",
                  "root": "आ-रुह्",
                  "meaning": "to ascend; to mount"
              },
              {
                  "sanskrit": "हर्षमाणासः",
                  "english": "rejoicing",
                  "root": "हर्ष",
                  "meaning": "joy; delight"
              },
              {
                  "sanskrit": "धृषिताः",
                  "english": "emboldened",
                  "root": "धृष्",
                  "meaning": "to be bold; courageous"
              },
              {
                  "sanskrit": "मरुत्वः",
                  "english": "like Maruts",
                  "root": "मरुत्",
                  "meaning": "wind gods; storm deities"
              },
              {
                  "sanskrit": "तिग्मेषवः",
                  "english": "with sharp arrows",
                  "root": "तिग्म-इषु",
                  "meaning": "sharp arrows"
              },
              {
                  "sanskrit": "आयुधाः",
                  "english": "weapons",
                  "root": "आयुध",
                  "meaning": "weapon"
              },
              {
                  "sanskrit": "संशिशानाः",
                  "english": "sharpening",
                  "root": "सम्-शिष्",
                  "meaning": "to sharpen"
              },
              {
                  "sanskrit": "अभि",
                  "english": "against",
                  "root": "अभि",
                  "meaning": "towards; against"
              },
              {
                  "sanskrit": "प्रयन्तु",
                  "english": "may they advance",
                  "root": "प्र-इ",
                  "meaning": "to go forth; advance"
              },
              {
                  "sanskrit": "नरः",
                  "english": "men",
                  "root": "नर",
                  "meaning": "man"
              },
              {
                  "sanskrit": "अग्निरूपाः",
                  "english": "fiery in form",
                  "root": "अग्नि-रूप",
                  "meaning": "fire-form; fiery appearance"
              }
          ]
      },
      {
          "number": 9,
          "slug": "verse-9",
          "title": "Manyu as the Leader",
          "text": "अग्निरिव मन्यो त्विषितः सहस्व सेनानीर्नः सहुरे हूत एधि ।\nहत्वाय शत्रून् विबहस्व वेद ओजो मिमानो विमृधो नुदस्व ॥ ९ ॥",
          "meaning": "Blazing like fire, O Manyu, be strong. O mighty one, become our commander when invoked. To slay the enemies, bring forth your knowledge; expanding your strength, drive away the adversaries.",
          "explanation": "The devotee calls upon Manyu to lead them like a general leads an army, illuminating the path like fire. By invoking His wisdom and strength, the devotee seeks victory over enemies. In Madhwa philosophy, this signifies seeking divine leadership and knowledge to overcome ignorance and attain liberation.",
          "wordByWord": [
              {
                  "sanskrit": "अग्निः",
                  "english": "fire",
                  "root": "अग्नि",
                  "meaning": "fire"
              },
              {
                  "sanskrit": "इव",
                  "english": "like",
                  "root": "इव",
                  "meaning": "like; as"
              },
              {
                  "sanskrit": "मन्यः",
                  "english": "O Manyu",
                  "root": "मन्यु",
                  "meaning": "divine wrath; righteous anger"
              },
              {
                  "sanskrit": "त्विषितः",
                  "english": "brilliant",
                  "root": "त्विष्",
                  "meaning": "to shine; to blaze"
              },
              {
                  "sanskrit": "सहस्व",
                  "english": "be strong",
                  "root": "सहस्",
                  "meaning": "to endure; to be strong"
              },
              {
                  "sanskrit": "सेनानीः",
                  "english": "commander",
                  "root": "सेना-नी",
                  "meaning": "leader of the army"
              },
              {
                  "sanskrit": "नः",
                  "english": "our",
                  "root": "अस्मद्",
                  "meaning": "we; us"
              },
              {
                  "sanskrit": "सहुरे",
                  "english": "O mighty one",
                  "root": "सहुरि",
                  "meaning": "mighty"
              },
              {
                  "sanskrit": "हूतः",
                  "english": "invoked",
                  "root": "हू",
                  "meaning": "to call; to invoke"
              },
              {
                  "sanskrit": "एधि",
                  "english": "become",
                  "root": "एध्",
                  "meaning": "to grow; to become"
              },
              {
                  "sanskrit": "हत्वाय",
                  "english": "for slaying",
                  "root": "हन्",
                  "meaning": "to kill; to slay"
              },
              {
                  "sanskrit": "शत्रून्",
                  "english": "enemies",
                  "root": "शत्रु",
                  "meaning": "enemy"
              },
              {
                  "sanskrit": "विबहस्व",
                  "english": "bring forth",
                  "root": "वि-बह्",
                  "meaning": "to carry; to bring"
              },
              {
                  "sanskrit": "वेद",
                  "english": "knowledge",
                  "root": "विद्",
                  "meaning": "to know"
              },
              {
                  "sanskrit": "ओजः",
                  "english": "strength",
                  "root": "ओजस्",
                  "meaning": "vigor; energy"
              },
              {
                  "sanskrit": "मिमानः",
                  "english": "expanding",
                  "root": "मा",
                  "meaning": "to measure; to expand"
              },
              {
                  "sanskrit": "विमृधः",
                  "english": "adversaries",
                  "root": "वि-मृध्",
                  "meaning": "opponents"
              },
              {
                  "sanskrit": "नुदस्व",
                  "english": "drive away",
                  "root": "नुद्",
                  "meaning": "to push; to drive away"
              }
          ]
      },
      {
          "number": 10,
          "slug": "verse-10",
          "title": "Overcoming Obstacles",
          "text": "सहस्व मन्यो अभिमातिमस्मे रुजान् मृणन् प्रमृणन् प्रेहि शत्रून् ।\nउग्रन्ते पाजो नन्वा रुरुध्रे वशी वशं नयसे एकज त्वम् ॥ १० ॥",
          "meaning": "O Manyu, overcome the hostility for us, breaking and crushing, advance against the enemies. Fierce is your vigor, you have restrained them; you alone bring the foes under control, O sovereign.",
          "explanation": "The devotee implores Manyu to dismantle the antagonism they face, acknowledging His fierce power to subdue adversaries. Recognizing that only the Supreme can truly control and overcome obstacles, aligning with Madhwa-Shastra's emphasis on God's supremacy and the soul's reliance on divine aid.",
          "wordByWord": [
              {
                  "sanskrit": "सहस्व",
                  "english": "overcome",
                  "root": "सहस्",
                  "meaning": "to endure; to overcome"
              },
              {
                  "sanskrit": "मन्यः",
                  "english": "O Manyu",
                  "root": "मन्यु",
                  "meaning": "divine wrath; righteous anger"
              },
              {
                  "sanskrit": "अभिमातिम्",
                  "english": "hostility",
                  "root": "अभिमाति",
                  "meaning": "aggression; hostility"
              },
              {
                  "sanskrit": "अस्मे",
                  "english": "for us",
                  "root": "अस्मद्",
                  "meaning": "we; us"
              },
              {
                  "sanskrit": "रुजान्",
                  "english": "breaking",
                  "root": "रुज्",
                  "meaning": "to break"
              },
              {
                  "sanskrit": "मृणन्",
                  "english": "crushing",
                  "root": "मृद्",
                  "meaning": "to crush"
              },
              {
                  "sanskrit": "प्रमृणन्",
                  "english": "completely crushing",
                  "root": "प्र-मृद्",
                  "meaning": "to crush thoroughly"
              },
              {
                  "sanskrit": "प्रेहि",
                  "english": "advance",
                  "root": "प्र-इ",
                  "meaning": "to go forth"
              },
              {
                  "sanskrit": "शत्रून्",
                  "english": "enemies",
                  "root": "शत्रु",
                  "meaning": "enemy"
              },
              {
                  "sanskrit": "उग्र",
                  "english": "fierce",
                  "root": "उग्र",
                  "meaning": "mighty; fierce"
              },
              {
                  "sanskrit": "ते",
                  "english": "your",
                  "root": "त्वद्",
                  "meaning": "you"
              },
              {
                  "sanskrit": "पाजः",
                  "english": "vigor",
                  "root": "पाजस्",
                  "meaning": "strength; vigor"
              },
              {
                  "sanskrit": "नन्वा",
                  "english": "indeed",
                  "root": "ननु",
                  "meaning": "indeed; surely"
              },
              {
                  "sanskrit": "रुरुध्रे",
                  "english": "have restrained",
                  "root": "रुध्",
                  "meaning": "to obstruct; restrain"
              },
              {
                  "sanskrit": "वशी",
                  "english": "sovereign",
                  "root": "वशी",
                  "meaning": "controller; lord"
              },
              {
                  "sanskrit": "वशम्",
                  "english": "control",
                  "root": "वश",
                  "meaning": "control; dominion"
              },
              {
                  "sanskrit": "नयसे",
                  "english": "you lead",
                  "root": "नी",
                  "meaning": "to lead"
              },
              {
                  "sanskrit": "एकज",
                  "english": "O sole-born",
                  "root": "एकज",
                  "meaning": "only born; unique"
              },
              {
                  "sanskrit": "त्वम्",
                  "english": "you",
                  "root": "त्वद्",
                  "meaning": "you"
              }
          ]
      },
      {
          "number": 11,
          "slug": "verse-11",
          "title": "Victory through Manyu",
          "text": "एको बहूनामसि मन्यवीलितो विशं विशं युधये सं शिशाधि ।\nअकृत्तुन् त्वया युजा वयंद्युमन्तं घोषं विजयाय कृण्महे ॥ ११ ॥",
          "meaning": "You alone are esteemed among many, O Manyu; guide each tribe in battle. United with you, the undefeated, we raise a resounding shout for victory.",
          "explanation": "Acknowledging Manyu's unique supremacy, the devotee seeks His guidance for all people in conflict. By uniting with the invincible Manyu, they aspire to achieve victory. In Madhwa-Shastra, this emphasizes total dependence on God's unparalleled power for success in spiritual endeavors.",
          "wordByWord": [
              {
                  "sanskrit": "एकः",
                  "english": "one",
                  "root": "एक",
                  "meaning": "one; alone"
              },
              {
                  "sanskrit": "बहूनाम्",
                  "english": "among many",
                  "root": "बहु",
                  "meaning": "many"
              },
              {
                  "sanskrit": "असि",
                  "english": "you are",
                  "root": "अस्",
                  "meaning": "to be"
              },
              {
                  "sanskrit": "मन्यव्",
                  "english": "O Manyu",
                  "root": "मन्यु",
                  "meaning": "divine wrath"
              },
              {
                  "sanskrit": "ईलितः",
                  "english": "esteemed",
                  "root": "ईल्",
                  "meaning": "to praise; to seek"
              },
              {
                  "sanskrit": "विशम्",
                  "english": "people",
                  "root": "विश्",
                  "meaning": "people; tribe"
              },
              {
                  "sanskrit": "विशम्",
                  "english": "each tribe",
                  "root": "विश्",
                  "meaning": "people; tribe"
              },
              {
                  "sanskrit": "युधये",
                  "english": "for battle",
                  "root": "युध्",
                  "meaning": "to fight"
              },
              {
                  "sanskrit": "सं शिशाधि",
                  "english": "guide together",
                  "root": "सं-शिध्",
                  "meaning": "to accomplish together"
              },
              {
                  "sanskrit": "अकृत्तुम्",
                  "english": "undefeated",
                  "root": "अ-कृतु",
                  "meaning": "unbroken; undefeated"
              },
              {
                  "sanskrit": "त्वया",
                  "english": "with you",
                  "root": "त्वद्",
                  "meaning": "you"
              },
              {
                  "sanskrit": "युजा",
                  "english": "united",
                  "root": "युज्",
                  "meaning": "to join; to unite"
              },
              {
                  "sanskrit": "वयम्",
                  "english": "we",
                  "root": "अस्मद्",
                  "meaning": "we"
              },
              {
                  "sanskrit": "द्युमन्तम्",
                  "english": "resounding",
                  "root": "द्युमत्",
                  "meaning": "bright; resounding"
              },
              {
                  "sanskrit": "घोषम्",
                  "english": "shout",
                  "root": "घोष",
                  "meaning": "sound; shout"
              },
              {
                  "sanskrit": "विजयाय",
                  "english": "for victory",
                  "root": "विजय",
                  "meaning": "victory"
              },
              {
                  "sanskrit": "कृण्महे",
                  "english": "we make",
                  "root": "कृ",
                  "meaning": "to do; to make"
              }
          ]
      },
      {
          "number": 12,
          "slug": "verse-12",
          "title": "Desiring Divine Leadership",
          "text": "विजेषकृदिन्द्र इवानवब्रवोऽस्माकं मन्यो अधिपा भव ेह ।\nप्रियं ते नाम सहुरे गृणीमसि विद्मा तमुत्सं यत आयभूथ ॥ १२ ॥",
          "meaning": "Like Indra, the conqueror, O unfailing Manyu, be our leader here. O mighty one, we sing your beloved name; we know that source from which you have arisen.",
          "explanation": "The devotee requests Manyu to be their leader, akin to Indra who grants victory. By praising His dear name and recognizing His divine origin, they express devotion and understanding of His true nature. In Madhwa philosophy, this signifies the importance of knowing God's attributes and seeking His leadership for spiritual triumph.",
          "wordByWord": [
              {
                  "sanskrit": "विजेषकृत्",
                  "english": "conqueror",
                  "root": "वि-जेष्-कृत्",
                  "meaning": "one who makes victorious"
              },
              {
                  "sanskrit": "इन्द्र",
                  "english": "Indra",
                  "root": "इन्द्र",
                  "meaning": "king of gods"
              },
              {
                  "sanskrit": "इव",
                  "english": "like",
                  "root": "इव",
                  "meaning": "like; as"
              },
              {
                  "sanskrit": "अनवब्रवः",
                  "english": "unfailing",
                  "root": "अन-वब्रु",
                  "meaning": "not failing"
              },
              {
                  "sanskrit": "अस्माकम्",
                  "english": "our",
                  "root": "अस्मद्",
                  "meaning": "we; us"
              },
              {
                  "sanskrit": "मन्यः",
                  "english": "O Manyu",
                  "root": "मन्यु",
                  "meaning": "divine wrath"
              },
              {
                  "sanskrit": "अधिपा",
                  "english": "leader",
                  "root": "अधि-प",
                  "meaning": "overlord; master"
              },
              {
                  "sanskrit": "भव",
                  "english": "become",
                  "root": "भू",
                  "meaning": "to be; to become"
              },
              {
                  "sanskrit": "इह",
                  "english": "here",
                  "root": "इह",
                  "meaning": "here"
              },
              {
                  "sanskrit": "प्रियम्",
                  "english": "beloved",
                  "root": "प्रिय",
                  "meaning": "dear; beloved"
              },
              {
                  "sanskrit": "ते",
                  "english": "your",
                  "root": "त्वद्",
                  "meaning": "you"
              },
              {
                  "sanskrit": "नाम",
                  "english": "name",
                  "root": "नामन्",
                  "meaning": "name"
              },
              {
                  "sanskrit": "सहुरे",
                  "english": "O mighty one",
                  "root": "सहुरि",
                  "meaning": "mighty"
              },
              {
                  "sanskrit": "गृणीमसि",
                  "english": "we sing",
                  "root": "गृ",
                  "meaning": "to sing; to praise"
              },
              {
                  "sanskrit": "विद्मा",
                  "english": "we know",
                  "root": "विद्",
                  "meaning": "to know"
              },
              {
                  "sanskrit": "तम्",
                  "english": "that",
                  "root": "तद्",
                  "meaning": "that"
              },
              {
                  "sanskrit": "उत्सम्",
                  "english": "source",
                  "root": "उत्स",
                  "meaning": "source; spring"
              },
              {
                  "sanskrit": "यत्",
                  "english": "from which",
                  "root": "यद्",
                  "meaning": "which"
              },
              {
                  "sanskrit": "आयभूथ",
                  "english": "you have arisen",
                  "root": "आ-भू",
                  "meaning": "to come into being"
              }
          ]
      },
      {
          "number": 13,
          "slug": "verse-13",
          "title": "Bestowing Prosperity",
          "text": "आभूत्या सहजा वज्र सायक सहो बिभर्ष्यभिभूत उत्तमम् ।\nक्रत्वा नो मन्यो सहमेद्येदि महाधनस्य पुरुहूत संसृजि ॥ १३ ॥",
          "meaning": "Born of prosperity, wielding thunderbolt and arrows, you bear supreme strength, overcoming all. O Manyu, unite with us by your will; grant us great wealth, O much-invoked one.",
          "explanation": "The devotee acknowledges Manyu's supreme power and seeks His union through His divine will. By invoking His favor, they desire material and spiritual wealth. In Madhwa-Shastra, this reflects the belief that all prosperity comes from God's grace, and sincere devotion leads to both worldly and spiritual benefits.",
          "wordByWord": [
              {
                  "sanskrit": "आभूत्या",
                  "english": "born of prosperity",
                  "root": "आ-भूति",
                  "meaning": "prosperity; wealth"
              },
              {
                  "sanskrit": "सहजा",
                  "english": "born together",
                  "root": "सह-ज",
                  "meaning": "co-born; innate"
              },
              {
                  "sanskrit": "वज्र",
                  "english": "thunderbolt",
                  "root": "वज्र",
                  "meaning": "thunderbolt"
              },
              {
                  "sanskrit": "सायक",
                  "english": "arrows",
                  "root": "सायक",
                  "meaning": "arrow"
              },
              {
                  "sanskrit": "सहो",
                  "english": "strength",
                  "root": "सहस्",
                  "meaning": "might; strength"
              },
              {
                  "sanskrit": "बिभर्षि",
                  "english": "you bear",
                  "root": "भृ",
                  "meaning": "to bear; to carry"
              },
              {
                  "sanskrit": "अभिभूत",
                  "english": "overcoming",
                  "root": "अभि-भू",
                  "meaning": "to overcome"
              },
              {
                  "sanskrit": "उत्तमम्",
                  "english": "supreme",
                  "root": "उत्तम",
                  "meaning": "highest; best"
              },
              {
                  "sanskrit": "क्रत्वा",
                  "english": "by will",
                  "root": "क्रत्",
                  "meaning": "will; action"
              },
              {
                  "sanskrit": "नः",
                  "english": "with us",
                  "root": "अस्मद्",
                  "meaning": "we; us"
              },
              {
                  "sanskrit": "मन्यः",
                  "english": "O Manyu",
                  "root": "मन्यु",
                  "meaning": "divine wrath"
              },
              {
                  "sanskrit": "सहम",
                  "english": "together",
                  "root": "सहम्",
                  "meaning": "together; united"
              },
              {
                  "sanskrit": "एधि",
                  "english": "may you come",
                  "root": "इ",
                  "meaning": "to go; to come"
              },
              {
                  "sanskrit": "महाधनस्य",
                  "english": "of great wealth",
                  "root": "महा-धन",
                  "meaning": "great wealth"
              },
              {
                  "sanskrit": "पुरुहूत",
                  "english": "much-invoked",
                  "root": "पुरु-हूत",
                  "meaning": "often called upon"
              },
              {
                  "sanskrit": "संसृजि",
                  "english": "grant",
                  "root": "सं-सृज्",
                  "meaning": "to bestow; to grant"
              }
          ]
      },
      {
          "number": 14,
          "slug": "verse-14",
          "title": "Prayer for Protection",
          "text": "संसृष्टं धनमुभयं समाकृतमस्मभ्यंदत्तां वरुणश्च मन्युः ।\nभियंदधाना हृदयेषु शत्रवः पराजितासो अप निलयन्ताम् ॥ १४ ॥",
          "meaning": "May the combined wealth, well-gathered, be granted to us by Varuṇa and Manyu. Let the enemies, harboring fear in their hearts, be defeated and depart elsewhere.",
          "explanation": "The devotee prays for the blessings of both Varuṇa and Manyu to receive both material and spiritual wealth. They seek the defeat and departure of enemies, symbolizing the removal of obstacles. In Madhwa philosophy, this represents the devotee's reliance on divine grace for protection and prosperity, both worldly and spiritual.",
          "wordByWord": [
              {
                  "sanskrit": "संसृष्टम्",
                  "english": "combined",
                  "root": "सं-सृज्",
                  "meaning": "to combine; to join"
              },
              {
                  "sanskrit": "धनम्",
                  "english": "wealth",
                  "root": "धन",
                  "meaning": "wealth"
              },
              {
                  "sanskrit": "उभयम्",
                  "english": "both",
                  "root": "उभय",
                  "meaning": "both"
              },
              {
                  "sanskrit": "समाकृतम्",
                  "english": "well-gathered",
                  "root": "सम्-आ-कृ",
                  "meaning": "to gather together"
              },
              {
                  "sanskrit": "अस्मभ्यम्",
                  "english": "to us",
                  "root": "अस्मद्",
                  "meaning": "we; us"
              },
              {
                  "sanskrit": "दत्ताम्",
                  "english": "may it be given",
                  "root": "दा",
                  "meaning": "to give"
              },
              {
                  "sanskrit": "वरुणः",
                  "english": "Varuṇa",
                  "root": "वरुण",
                  "meaning": "god of water"
              },
              {
                  "sanskrit": "च",
                  "english": "and",
                  "root": "च",
                  "meaning": "and"
              },
              {
                  "sanskrit": "मन्युः",
                  "english": "Manyu",
                  "root": "मन्यु",
                  "meaning": "divine wrath"
              },
              {
                  "sanskrit": "भियम्",
                  "english": "fear",
                  "root": "भिया",
                  "meaning": "fear"
              },
              {
                  "sanskrit": "दधाना",
                  "english": "holding",
                  "root": "धा",
                  "meaning": "to hold; to bear"
              },
              {
                  "sanskrit": "हृदयेषु",
                  "english": "in hearts",
                  "root": "हृदय",
                  "meaning": "heart"
              },
              {
                  "sanskrit": "शत्रवः",
                  "english": "enemies",
                  "root": "शत्रु",
                  "meaning": "enemy"
              },
              {
                  "sanskrit": "पराजितासः",
                  "english": "defeated",
                  "root": "पराजित",
                  "meaning": "defeated"
              },
              {
                  "sanskrit": "अप",
                  "english": "away",
                  "root": "अप",
                  "meaning": "away"
              },
              {
                  "sanskrit": "निलयन्ताम्",
                  "english": "may they depart",
                  "root": "नि-ली",
                  "meaning": "to depart; to disappear"
              }
          ]
      }
  ]
}, {
  "id": 29,
  "slug": "balitha-suktam",
  "title": "बळित्थ सूक्तम् (Balitha Suktam)",
  "verses": [
      {
          "number": 1,
          "slug": "verse-1",
          "title": "Praise of the Divine Radiance",
          "text": "बळित्था तद्वपुषे धायि दर्शतं देवस्य भर्गः सहसो यतो जनि ।\nयदीमुप ह्वरते साधते मतिर्ऋतस्य॒ धेना अनयन्त सस्रुतः ॥१॥",
          "meaning": "The most splendid form has been established for His manifestation; the radiant brilliance of the mighty God, from whom creation arose. When the intellect turns towards Him and becomes perfect, the cows of truth lead us to the ever-flowing (nectar).",
          "explanation": "In this verse, the sage glorifies the supreme radiance of the Divine (interpreted as Lord Vishnu in Madhwa philosophy). The 'splendid form' refers to the divine manifestation that is the source of creation. By aligning one's mind with the eternal truth (ऋत), one is led towards enlightenment and eternal bliss. The 'cows of truth' symbolize the Vedas or divine knowledge that guide seekers on the spiritual path.",
          "wordByWord": [
              {
                  "sanskrit": "बळित्था",
                  "english": "most splendid",
                  "root": "बृहत्",
                  "meaning": "great; splendid"
              },
              {
                  "sanskrit": "तत्",
                  "english": "that",
                  "root": "तद्",
                  "meaning": "that"
              },
              {
                  "sanskrit": "वपुषे",
                  "english": "for manifestation",
                  "root": "वपुस्",
                  "meaning": "form; body"
              },
              {
                  "sanskrit": "धायि",
                  "english": "has been established",
                  "root": "धा",
                  "meaning": "to place; to establish"
              },
              {
                  "sanskrit": "दर्शतम्",
                  "english": "most visible",
                  "root": "दर्श",
                  "meaning": "to see"
              },
              {
                  "sanskrit": "देवस्य",
                  "english": "of the God",
                  "root": "देव",
                  "meaning": "god; divine"
              },
              {
                  "sanskrit": "भर्गः",
                  "english": "radiance",
                  "root": "भर्ग",
                  "meaning": "splendor; brilliance"
              },
              {
                  "sanskrit": "सहसः",
                  "english": "of the mighty",
                  "root": "सहस्",
                  "meaning": "might; strength"
              },
              {
                  "sanskrit": "यतः",
                  "english": "from whom",
                  "root": "यत्",
                  "meaning": "which; who"
              },
              {
                  "sanskrit": "जनि",
                  "english": "creation arose",
                  "root": "जन्",
                  "meaning": "to be born; creation"
              },
              {
                  "sanskrit": "यदा",
                  "english": "when",
                  "root": "यदा",
                  "meaning": "when"
              },
              {
                  "sanskrit": "ईम्",
                  "english": "towards Him",
                  "root": "ईम्",
                  "meaning": "this; him"
              },
              {
                  "sanskrit": "उप",
                  "english": "towards",
                  "root": "उप",
                  "meaning": "towards; near"
              },
              {
                  "sanskrit": "ह्वरते",
                  "english": "turns",
                  "root": "ह्वृ",
                  "meaning": "to turn; to bend"
              },
              {
                  "sanskrit": "साधते",
                  "english": "becomes perfect",
                  "root": "साध्",
                  "meaning": "to accomplish; perfect"
              },
              {
                  "sanskrit": "मतिः",
                  "english": "intellect",
                  "root": "मति",
                  "meaning": "mind; intellect"
              },
              {
                  "sanskrit": "ऋतस्य",
                  "english": "of truth",
                  "root": "ऋत",
                  "meaning": "cosmic order; truth"
              },
              {
                  "sanskrit": "धेना",
                  "english": "cows",
                  "root": "धेनु",
                  "meaning": "cow"
              },
              {
                  "sanskrit": "अनयन्त",
                  "english": "lead",
                  "root": "नी",
                  "meaning": "to lead"
              },
              {
                  "sanskrit": "सस्रुतः",
                  "english": "ever-flowing",
                  "root": "स्रु",
                  "meaning": "to flow"
              }
          ]
      },
      {
          "number": 2,
          "slug": "verse-2",
          "title": "Manifestations of the Divine",
          "text": "पृक्षो वपुः पितुमान् नित्य आ शये द्वितीयमा सप्तर्षिवासु मातृषु ।\nतृतीयमस्य वृषभस्य दोहसे दमतिं जनयन्त योषणः ॥२॥",
          "meaning": "The form of the nourisher lies eternally in the heaven; the second manifestation is in the seven mothers (earthly realms). For the milking of this bull (the Divine), the maidens (energies) produce the third form.",
          "explanation": "This verse describes the multiple manifestations of the Divine. The 'nourisher' in heaven represents the transcendental aspect of God. The 'seven mothers' symbolize the seven earthly realms or energies where the Divine manifests. The 'bull' signifies strength and fertility, and the 'maidens' producing the third form represent the dynamic energies that facilitate divine expression in the material world. Madhwa philosophy emphasizes the omnipresence of the Lord in all realms.",
          "wordByWord": [
              {
                  "sanskrit": "पृक्षः",
                  "english": "nourisher",
                  "root": "पृक्ष",
                  "meaning": "to nourish"
              },
              {
                  "sanskrit": "वपुः",
                  "english": "form",
                  "root": "वपुस्",
                  "meaning": "form; body"
              },
              {
                  "sanskrit": "पितुमान्",
                  "english": "possessor of drink",
                  "root": "पितुमत्",
                  "meaning": "having drink; nourishing"
              },
              {
                  "sanskrit": "नित्यः",
                  "english": "eternal",
                  "root": "नित्य",
                  "meaning": "eternal"
              },
              {
                  "sanskrit": "आ शये",
                  "english": "lies",
                  "root": "शि",
                  "meaning": "to lie down; to rest"
              },
              {
                  "sanskrit": "द्वितीयम्",
                  "english": "second",
                  "root": "द्वितीय",
                  "meaning": "second"
              },
              {
                  "sanskrit": "आ",
                  "english": "in",
                  "root": "आ",
                  "meaning": "towards; in"
              },
              {
                  "sanskrit": "सप्त",
                  "english": "seven",
                  "root": "सप्त",
                  "meaning": "seven"
              },
              {
                  "sanskrit": "ऋशि",
                  "english": "sages",
                  "root": "ऋषि",
                  "meaning": "sage; seer"
              },
              {
                  "sanskrit": "वासु",
                  "english": "abode",
                  "root": "वास",
                  "meaning": "dwelling; abode"
              },
              {
                  "sanskrit": "मातृषु",
                  "english": "in mothers",
                  "root": "मातृ",
                  "meaning": "mother"
              },
              {
                  "sanskrit": "तृतीयम्",
                  "english": "third",
                  "root": "तृतीय",
                  "meaning": "third"
              },
              {
                  "sanskrit": "अस्य",
                  "english": "of this",
                  "root": "अस्य",
                  "meaning": "of this"
              },
              {
                  "sanskrit": "वृषभस्य",
                  "english": "of the bull",
                  "root": "वृषभ",
                  "meaning": "bull"
              },
              {
                  "sanskrit": "दोहसे",
                  "english": "for milking",
                  "root": "दुह्",
                  "meaning": "to milk"
              },
              {
                  "sanskrit": "दमतिम्",
                  "english": "abundant",
                  "root": "दमति",
                  "meaning": "abundance"
              },
              {
                  "sanskrit": "जनयन्त",
                  "english": "produce",
                  "root": "जन्",
                  "meaning": "to produce; to generate"
              },
              {
                  "sanskrit": "योषणः",
                  "english": "maidens",
                  "root": "योषा",
                  "meaning": "woman; maiden"
              }
          ]
      },
      {
          "number": 3,
          "slug": "verse-3",
          "title": "Divine Manifestation and Guidance",
          "text": "निर्यदर्दी बुध्नान्महिषस्य वर्पस ईशानासः शवसा क्रन्त सूरयः ।\nयदीमर्नु प्रदिवो मध्वं आधवे गुहा सन्तं मातरिश्वा मायति ॥३॥",
          "meaning": "When the leaders, possessing strength, move forth from the depth of the buffalo's form, they, the lords, traverse with might. When for the seeker, the hidden honey is brought from heaven by Mātariśvan (the wind god).",
          "explanation": "This verse symbolizes the emergence of divine knowledge from hidden realms. The 'buffalo's form' represents the deep or obscure aspects of the cosmos. The 'leaders' are enlightened beings who, with divine strength, bring forth this knowledge. Mātariśvan (wind) bringing the 'hidden honey' signifies the transmission of divine wisdom to seekers. In Madhwa philosophy, this reflects the grace of God in revealing spiritual truths to sincere devotees.",
          "wordByWord": [
              {
                  "sanskrit": "निः",
                  "english": "out",
                  "root": "निस्",
                  "meaning": "out"
              },
              {
                  "sanskrit": "यत्",
                  "english": "when",
                  "root": "यद्",
                  "meaning": "which; when"
              },
              {
                  "sanskrit": "अर्दी",
                  "english": "move forth",
                  "root": "अर्द्",
                  "meaning": "to move; to go"
              },
              {
                  "sanskrit": "बुध्नात्",
                  "english": "from the depth",
                  "root": "बुध्न",
                  "meaning": "bottom; depth"
              },
              {
                  "sanskrit": "महिषस्य",
                  "english": "of the buffalo",
                  "root": "महिष",
                  "meaning": "buffalo"
              },
              {
                  "sanskrit": "वर्पसः",
                  "english": "form",
                  "root": "वर्पस्",
                  "meaning": "form; appearance"
              },
              {
                  "sanskrit": "ईशानासः",
                  "english": "lords",
                  "root": "ईशान",
                  "meaning": "lord; master"
              },
              {
                  "sanskrit": "शवसा",
                  "english": "with might",
                  "root": "शवस्",
                  "meaning": "might; power"
              },
              {
                  "sanskrit": "क्रन्त",
                  "english": "traverse",
                  "root": "क्रम्",
                  "meaning": "to step; to traverse"
              },
              {
                  "sanskrit": "सूरयः",
                  "english": "leaders",
                  "root": "सूर",
                  "meaning": "sun; leader"
              },
              {
                  "sanskrit": "यदा",
                  "english": "when",
                  "root": "यदा",
                  "meaning": "when"
              },
              {
                  "sanskrit": "ईम्",
                  "english": "for him",
                  "root": "ईम्",
                  "meaning": "him"
              },
              {
                  "sanskrit": "अर्नु",
                  "english": "seeker",
                  "root": "अर्नु",
                  "meaning": "desirous; seeker"
              },
              {
                  "sanskrit": "प्र",
                  "english": "from",
                  "root": "प्र",
                  "meaning": "forth; from"
              },
              {
                  "sanskrit": "दिवः",
                  "english": "heaven",
                  "root": "दिव्",
                  "meaning": "sky; heaven"
              },
              {
                  "sanskrit": "मध्वम्",
                  "english": "honey",
                  "root": "मधु",
                  "meaning": "honey; sweetness"
              },
              {
                  "sanskrit": "आधवे",
                  "english": "for bringing",
                  "root": "आ-धाव्",
                  "meaning": "to bring"
              },
              {
                  "sanskrit": "गुहा",
                  "english": "hidden",
                  "root": "गुह",
                  "meaning": "secret; hidden"
              },
              {
                  "sanskrit": "सन्तम्",
                  "english": "being",
                  "root": "सत्",
                  "meaning": "being; existing"
              },
              {
                  "sanskrit": "मातरिश्वा",
                  "english": "Mātariśvan",
                  "root": "मातरि-श्वन्",
                  "meaning": "wind god"
              },
              {
                  "sanskrit": "मायति",
                  "english": "brings",
                  "root": "मा",
                  "meaning": "to measure; to bring"
              }
          ]
      },
      {
          "number": 4,
          "slug": "verse-4",
          "title": "Growth through Divine Grace",
          "text": "प्र यत् पितुः परमान्नीयते पर्या पृक्षुधौ वीरुधो दंसु रोहति ।\nउभा यदस्य जनुषं यदिन्वत आदिद् यविष्ठो अभवद् घृणा शुचिः ॥४॥",
          "meaning": "When from the supreme father it is led forth, the plants grow mightily upon the earth. When both his births were infused, then the youngest (fire) became the glowing and pure one.",
          "explanation": "This verse illustrates the proliferation of life through divine intervention. The 'supreme father' refers to the ultimate reality or God. The 'plants growing' symbolize life flourishing due to divine grace. The 'two births' indicate the material and spiritual emergence, and the 'youngest' as 'fire' represents Agni, the purifier, who becomes radiant and pure. In Madhwa philosophy, this underscores the role of the Divine in fostering both material and spiritual growth.",
          "wordByWord": [
              {
                  "sanskrit": "प्र",
                  "english": "forth",
                  "root": "प्र",
                  "meaning": "forth"
              },
              {
                  "sanskrit": "यत्",
                  "english": "when",
                  "root": "यद्",
                  "meaning": "which; when"
              },
              {
                  "sanskrit": "पितुः",
                  "english": "of the father",
                  "root": "पितृ",
                  "meaning": "father"
              },
              {
                  "sanskrit": "परमान्",
                  "english": "supreme",
                  "root": "परम",
                  "meaning": "highest; supreme"
              },
              {
                  "sanskrit": "नीयते",
                  "english": "is led",
                  "root": "नी",
                  "meaning": "to lead"
              },
              {
                  "sanskrit": "परि",
                  "english": "around",
                  "root": "परि",
                  "meaning": "around"
              },
              {
                  "sanskrit": "आ",
                  "english": "upon",
                  "root": "आ",
                  "meaning": "towards; upon"
              },
              {
                  "sanskrit": "पृक्षुधौ",
                  "english": "earth",
                  "root": "पृक्षुध्",
                  "meaning": "earth"
              },
              {
                  "sanskrit": "वीरुधः",
                  "english": "plants",
                  "root": "वीरुध्",
                  "meaning": "plant"
              },
              {
                  "sanskrit": "दंसु",
                  "english": "mightily",
                  "root": "दंसु",
                  "meaning": "strong; mighty"
              },
              {
                  "sanskrit": "रोहति",
                  "english": "grows",
                  "root": "रुह्",
                  "meaning": "to grow"
              },
              {
                  "sanskrit": "उभा",
                  "english": "both",
                  "root": "उभ",
                  "meaning": "both"
              },
              {
                  "sanskrit": "यत्",
                  "english": "when",
                  "root": "यद्",
                  "meaning": "which; when"
              },
              {
                  "sanskrit": "अस्य",
                  "english": "his",
                  "root": "अस्य",
                  "meaning": "of this"
              },
              {
                  "sanskrit": "जनुषम्",
                  "english": "births",
                  "root": "जनुष",
                  "meaning": "birth"
              },
              {
                  "sanskrit": "यत्",
                  "english": "when",
                  "root": "यद्",
                  "meaning": "which; when"
              },
              {
                  "sanskrit": "इन्वत्",
                  "english": "was infused",
                  "root": "इन्",
                  "meaning": "to invigorate; infuse"
              },
              {
                  "sanskrit": "आदित्",
                  "english": "then",
                  "root": "आदि",
                  "meaning": "beginning; then"
              },
              {
                  "sanskrit": "यविष्ठः",
                  "english": "youngest",
                  "root": "यविष्ठ",
                  "meaning": "youngest"
              },
              {
                  "sanskrit": "अभवत्",
                  "english": "became",
                  "root": "भू",
                  "meaning": "to be; to become"
              },
              {
                  "sanskrit": "घृणा",
                  "english": "glowing",
                  "root": "घृण",
                  "meaning": "to shine; glow"
              },
              {
                  "sanskrit": "शुचिः",
                  "english": "pure",
                  "root": "शुचि",
                  "meaning": "pure"
              }
          ]
      },
      {
          "number": 5,
          "slug": "verse-5",
          "title": "Expansion and Continuity",
          "text": "आदिन्मातृराविशद्यावाशुचिरहिंस्यमान उर्विया वि वावृधे ।\nअनु यत् पूर्वा अरुहत् सनाजुवो नि नव्यसीष्ववरासु धावते ॥५॥",
          "meaning": "He first entered the two mothers (heaven and earth), pure and unharmed, expanding widely through the vast space. Following the paths of the earlier ones, the ever-young moves among the newer ones below.",
          "explanation": "This verse describes the pervasive nature of the Divine, entering both heaven and earth, signifying omnipresence. The 'two mothers' symbolize the celestial and terrestrial realms. The ever-young deity represents the eternal, unaging nature of God, continuously engaging with creation. In Madhwa philosophy, this highlights the constant presence and involvement of the Supreme Being in the cosmos, guiding and sustaining it.",
          "wordByWord": [
              {
                  "sanskrit": "आदित्",
                  "english": "first",
                  "root": "आदि",
                  "meaning": "beginning; first"
              },
              {
                  "sanskrit": "मातृरा",
                  "english": "two mothers",
                  "root": "मातृ",
                  "meaning": "mother"
              },
              {
                  "sanskrit": "अविशत्",
                  "english": "entered",
                  "root": "विश्",
                  "meaning": "to enter"
              },
              {
                  "sanskrit": "यावा",
                  "english": "both",
                  "root": "यावन्",
                  "meaning": "both"
              },
              {
                  "sanskrit": "शुचिः",
                  "english": "pure",
                  "root": "शुचि",
                  "meaning": "pure"
              },
              {
                  "sanskrit": "अहिंस्यमानः",
                  "english": "unharmed",
                  "root": "अहिंस्य",
                  "meaning": "non-violence; unharmed"
              },
              {
                  "sanskrit": "उर्विया",
                  "english": "through the vast space",
                  "root": "उर्वी",
                  "meaning": "earth; wide"
              },
              {
                  "sanskrit": "वि वावृधे",
                  "english": "expanded",
                  "root": "वि-वृध्",
                  "meaning": "to grow; to expand"
              },
              {
                  "sanskrit": "अनु",
                  "english": "following",
                  "root": "अनु",
                  "meaning": "after; following"
              },
              {
                  "sanskrit": "यत्",
                  "english": "which",
                  "root": "यद्",
                  "meaning": "which"
              },
              {
                  "sanskrit": "पूर्वाः",
                  "english": "earlier ones",
                  "root": "पूर्व",
                  "meaning": "former; earlier"
              },
              {
                  "sanskrit": "अरुहत्",
                  "english": "ascended",
                  "root": "रुह्",
                  "meaning": "to ascend; to grow"
              },
              {
                  "sanskrit": "सनाजुवः",
                  "english": "ever-young",
                  "root": "सन-अजुव",
                  "meaning": "eternally youthful"
              },
              {
                  "sanskrit": "नि",
                  "english": "down",
                  "root": "नि",
                  "meaning": "down"
              },
              {
                  "sanskrit": "नव्यसीषु",
                  "english": "among newer ones",
                  "root": "नव्य",
                  "meaning": "new"
              },
              {
                  "sanskrit": "अवरासु",
                  "english": "below",
                  "root": "अवर",
                  "meaning": "lower; below"
              },
              {
                  "sanskrit": "धावते",
                  "english": "moves",
                  "root": "धाव्",
                  "meaning": "to run; to move"
              }
          ]
      }
  ]
}, {
    "id": 30,
    "slug": "sarva-mangala-mangalye",
    "title": "Sarva Mangala Mangalye",
    "verses": [
        {
            "number": 1,
            "slug": "sarva-mangala-mangalye",
            "title": "Sarva Mangala Mangalye",
            "text": "सर्वमङ्गलमाङ्गल्ये शिवे सर्वार्थसाधिके।\nशरण्ये त्र्यम्बके गौरी नारायणी नमोऽस्तु ते॥",
            "meaning": "Salutations to the most auspicious one, who is the embodiment of all auspiciousness, who fulfills all desires, who is the refuge of all, who has three eyes, and who is Gauri (the fair one) and Narayani (the consort of Narayana).",
            "explanation": "This verse is a prayer to Goddess Parvati, also known as Gauri and Narayani, acknowledging her as the source of all auspiciousness and well-being. She is the one who fulfills all righteous desires and protects her devotees. The reference to 'Trayambake' (one with three eyes) highlights her divinity and connection with Lord Shiva.",
            "wordByWord": [
                {
                    "sanskrit": "सर्वमङ्गल",
                    "english": "sarva mangala",
                    "root": "सर्व + मङ्गल",
                    "meaning": "all auspiciousness"
                },
                {
                    "sanskrit": "माङ्गल्ये",
                    "english": "maangalye",
                    "root": "माङ्गल्य",
                    "meaning": "most auspicious one"
                },
                {
                    "sanskrit": "शिवे",
                    "english": "shive",
                    "root": "शिव",
                    "meaning": "the benevolent"
                },
                {
                    "sanskrit": "सर्वार्थ",
                    "english": "sarvartha",
                    "root": "सर्व + अर्थ",
                    "meaning": "fulfiller of all objectives"
                },
                {
                    "sanskrit": "साधिके",
                    "english": "saadhike",
                    "root": "साधिका",
                    "meaning": "accomplisher"
                },
                {
                    "sanskrit": "शरण्ये",
                    "english": "sharanye",
                    "root": "शरण्य",
                    "meaning": "the refuge"
                },
                {
                    "sanskrit": "त्र्यम्बके",
                    "english": "trayambake",
                    "root": "त्रि + अम्बक",
                    "meaning": "the one with three eyes"
                },
                {
                    "sanskrit": "गौरी",
                    "english": "gauri",
                    "root": "गौरी",
                    "meaning": "fair one (Parvati)"
                },
                {
                    "sanskrit": "नारायणी",
                    "english": "narayani",
                    "root": "नारायणी",
                    "meaning": "consort of Narayana"
                },
                {
                    "sanskrit": "नमोऽस्तु",
                    "english": "namostu",
                    "root": "नमः",
                    "meaning": "salutations"
                },
                {
                    "sanskrit": "ते",
                    "english": "te",
                    "root": "त्वम्",
                    "meaning": "to you"
                }
            ]
        },
        {
            "number": 2,
            "slug": "ya-devi-sarva-bhutesu",
            "title": "Ya Devi Sarva Bhuteshu",
            "text": "या देवी सर्वभूतेषु शान्तिरूपेण संस्थिता।\nया देवी सर्वभूतेषु शक्तिरूपेण संस्थिता।\nया देवी सर्वभूतेषु मातृरूपेण संस्थिता।\nनमस्तस्यै, नमस्तस्यै, नमस्तस्यै, नमो नमः॥",
            "meaning": "Salutations to the Goddess who abides in all beings in the form of peace, who resides in all beings in the form of strength, and who is present in all beings as the mother. Salutations to her, again and again.",
            "explanation": "This verse recognizes the omnipresence of the Goddess, manifesting in all beings in various forms—peace, strength, and as the nurturing mother. It is a powerful acknowledgment of the divine feminine force (Shakti) that sustains all life and existence, highlighting the nurturing and protective aspects of the Goddess.",
            "wordByWord": [
                {
                    "sanskrit": "या",
                    "english": "ya",
                    "root": "य",
                    "meaning": "who"
                },
                {
                    "sanskrit": "देवी",
                    "english": "devi",
                    "root": "देवी",
                    "meaning": "Goddess"
                },
                {
                    "sanskrit": "सर्वभूतेषु",
                    "english": "sarva bhuteshu",
                    "root": "सर्व + भूतेषु",
                    "meaning": "in all beings"
                },
                {
                    "sanskrit": "शान्तिरूपेण",
                    "english": "shanti rupena",
                    "root": "शान्ति + रूप",
                    "meaning": "in the form of peace"
                },
                {
                    "sanskrit": "संस्थिता",
                    "english": "sansthita",
                    "root": "संस्थित",
                    "meaning": "abides"
                },
                {
                    "sanskrit": "शक्तिरूपेण",
                    "english": "shakti rupena",
                    "root": "शक्ति + रूप",
                    "meaning": "in the form of strength"
                },
                {
                    "sanskrit": "मातृरूपेण",
                    "english": "matru rupena",
                    "root": "मातृ + रूप",
                    "meaning": "in the form of the mother"
                },
                {
                    "sanskrit": "नमस्तस्यै",
                    "english": "namastasyai",
                    "root": "नमः + तस्यै",
                    "meaning": "salutations to her"
                },
                {
                    "sanskrit": "नमो नमः",
                    "english": "namo namah",
                    "root": "नमः",
                    "meaning": "salutations, again and again"
                }
            ]
        },
        {
            "number": 3,
            "slug": "namoh-devyai",
            "title": "Salutations to the Goddess",
            "text": "नमो देव्यै महादेव्यै शिवायै सततं नमः।\nनमः प्रकृत्यै भद्रायै नियताः प्रणताः स्म ताम्॥",
            "meaning": "Salutations to the Goddess, the great Goddess, to Shiva (the auspicious one). Salutations to Prakriti (Nature) and to Bhadra (the benevolent one), to whom we are always bowed down.",
            "explanation": "This verse is a reverential prayer to the Goddess, acknowledging her various forms as the supreme Mother, the source of creation (Prakriti), and the auspicious one (Shiva). It expresses deep devotion and surrender to the divine feminine energy that pervades all creation, sustains it, and blesses all beings.",
            "wordByWord": [
                {
                    "sanskrit": "नमो",
                    "english": "namo",
                    "root": "नमः",
                    "meaning": "salutations"
                },
                {
                    "sanskrit": "देव्यै",
                    "english": "devyai",
                    "root": "देवी",
                    "meaning": "to the Goddess"
                },
                {
                    "sanskrit": "महादेव्यै",
                    "english": "mahadevyai",
                    "root": "मह + देवी",
                    "meaning": "to the great Goddess"
                },
                {
                    "sanskrit": "शिवायै",
                    "english": "shivayai",
                    "root": "शिवा",
                    "meaning": "to the auspicious one"
                },
                {
                    "sanskrit": "सततम्",
                    "english": "satatam",
                    "root": "सततम्",
                    "meaning": "always"
                },
                {
                    "sanskrit": "प्रकृत्यै",
                    "english": "prakrtyai",
                    "root": "प्रकृति",
                    "meaning": "to Nature"
                },
                {
                    "sanskrit": "भद्रायै",
                    "english": "bhadrayai",
                    "root": "भद्रा",
                    "meaning": "to the benevolent one"
                },
                {
                    "sanskrit": "नियताः",
                    "english": "niyatah",
                    "root": "नियता",
                    "meaning": "always devoted"
                },
                {
                    "sanskrit": "प्रणताः",
                    "english": "pranatah",
                    "root": "प्रणत",
                    "meaning": "bowed down"
                },
                {
                    "sanskrit": "स्म",
                    "english": "sma",
                    "root": "स्म",
                    "meaning": "we are"
                },
                {
                    "sanskrit": "ताम्",
                    "english": "tam",
                    "root": "तत्",
                    "meaning": "to her"
                }
            ]
        }
    ]
}, {
    "id": 31,
    "slug": "namaste-pranesha",
    "title": "Namaste Pranesha",
    "verses": [
        {
            "number": 1,
            "slug": "namaste-pranesha",
            "title": "Salutations to Pranesha (Hanuman), Bhima, and Madhvacharya",
            "text": "नमस्ते प्राणेश प्रणत विभवाय निगमाः।\nनमः स्वामिन् रामप्रियतम हनूमा अंगुरुगुण ।\nनमस्तुभ्यं भीम प्रबलतम कृष्णेष्ट भगवन् ।\nनमः श्रीमन्मध्व प्रदिश सुदृशं नो जय जय ॥",
            "meaning": "Salutations to you, O Lord of life (Pranesha), revered by all the Vedas. Salutations to you, O Hanuman, dearest to Lord Rama, endowed with great qualities. Salutations to you, O mighty Bhima, the most powerful devotee of Lord Krishna. Salutations to you, O Shri Madhvacharya, guide us with your divine vision. Victory to you!",
            "explanation": "This verse is a prayer and salutation to three great personalities: Hanuman (Pranesha), Bhima, and Madhvacharya, who are considered incarnations in the Dvaita tradition. The verse acknowledges their devotion to Lord Rama and Lord Krishna and seeks their guidance and blessings. It highlights Hanuman’s devotion, Bhima’s power, and Madhvacharya’s wisdom as the path to victory and spiritual clarity.",
            "wordByWord": [
                {
                    "sanskrit": "नमस्ते",
                    "english": "namaste",
                    "root": "नमः",
                    "meaning": "salutations"
                },
                {
                    "sanskrit": "प्राणेश",
                    "english": "pranesha",
                    "root": "प्राण + ईश",
                    "meaning": "Lord of life"
                },
                {
                    "sanskrit": "प्रणत",
                    "english": "pranata",
                    "root": "प्रणत",
                    "meaning": "revered by"
                },
                {
                    "sanskrit": "विभवाय",
                    "english": "vibhavaya",
                    "root": "विभव",
                    "meaning": "to the greatness"
                },
                {
                    "sanskrit": "निगमाः",
                    "english": "nigamah",
                    "root": "निगम",
                    "meaning": "the Vedas"
                },
                {
                    "sanskrit": "नमः",
                    "english": "namah",
                    "root": "नमः",
                    "meaning": "salutations"
                },
                {
                    "sanskrit": "स्वामिन्",
                    "english": "swamin",
                    "root": "स्वामिन्",
                    "meaning": "O Lord"
                },
                {
                    "sanskrit": "रामप्रियतम",
                    "english": "rama priyatama",
                    "root": "राम + प्रियतम",
                    "meaning": "dearest to Rama"
                },
                {
                    "sanskrit": "हनूमा",
                    "english": "hanuma",
                    "root": "हनुमान्",
                    "meaning": "Hanuman"
                },
                {
                    "sanskrit": "अंगुरुगुण",
                    "english": "anguruguna",
                    "root": "अंग + गुरु + गुण",
                    "meaning": "endowed with great qualities"
                },
                {
                    "sanskrit": "नमस्तुभ्यं",
                    "english": "namastubhyam",
                    "root": "नमः + तुभ्यम्",
                    "meaning": "salutations to you"
                },
                {
                    "sanskrit": "भीम",
                    "english": "bhima",
                    "root": "भीम",
                    "meaning": "Bhima"
                },
                {
                    "sanskrit": "प्रबलतम",
                    "english": "prabalatama",
                    "root": "प्रबल + तम",
                    "meaning": "the most powerful"
                },
                {
                    "sanskrit": "कृष्णेष्ट",
                    "english": "krishneshta",
                    "root": "कृष्ण + इष्ट",
                    "meaning": "beloved of Krishna"
                },
                {
                    "sanskrit": "भगवन्",
                    "english": "bhagavan",
                    "root": "भगवत्",
                    "meaning": "O Lord"
                },
                {
                    "sanskrit": "नमः",
                    "english": "namah",
                    "root": "नमः",
                    "meaning": "salutations"
                },
                {
                    "sanskrit": "श्रीमन्मध्व",
                    "english": "shrimad madhva",
                    "root": "श्रीमत् + मध्व",
                    "meaning": "O venerable Madhva"
                },
                {
                    "sanskrit": "प्रदिश",
                    "english": "pradisha",
                    "root": "प्र + दिश",
                    "meaning": "guide"
                },
                {
                    "sanskrit": "सुदृशं",
                    "english": "sudrusham",
                    "root": "सु + दृश",
                    "meaning": "with clear vision"
                },
                {
                    "sanskrit": "नो",
                    "english": "no",
                    "root": "नः",
                    "meaning": "to us"
                },
                {
                    "sanskrit": "जय जय",
                    "english": "jaya jaya",
                    "root": "जय",
                    "meaning": "victory, victory"
                }
            ]
        }
    ]
}, {
    "id": 32,
    "slug": "eka-sloki-mahabharatam",
    "title": "Eka sloki Mahabharatam",
    "verses": [
        {
            "number": 1,
            "slug": "adhau-pandava-dhartarashtra-jananam",
            "title": "Eka sloki Mahabharatam",
            "text": "आधौ पाण्डव-धार्तराष्ट्र-जनानां लाक्षागृहे दाहनं ।\nद्यूतं श्रीहरणं वने विहरणं मत्स्यालये वर्तनम् ।\nलीलागोग्रहणं रणे विहरणं सन्धिक्रियाजृम्भणं ।\nपश्चात् भीष्मद्रोण-दुर्योधनादि निधनं एतत् महाभारतम् ॥",
            "meaning": "In the beginning, the burning of the house of lac, the conflict between the Pandavas and Dhartarashtras, the game of dice, Draupadi’s humiliation, the Pandavas’ exile and their life in the Matsya kingdom, the playful capturing of cows, the many battles, the breaking of peace negotiations, and finally, the deaths of Bhishma, Drona, Duryodhana, and others—this is the great Mahabharata.",
            "explanation": "This verse presents a condensed summary of the events in the Mahabharata. It begins with the rivalry between the Pandavas and the Kauravas, marked by incidents like the burning of the house of lac, the game of dice that led to Draupadi’s humiliation, and the Pandavas’ exile. It further highlights key episodes like their time spent in hiding in the Matsya kingdom, the battles, and the final deaths of the key warriors such as Bhishma, Drona, and Duryodhana. This encapsulates the essence of the grand epic, Mahabharata.",
            "wordByWord": [
                {
                    "sanskrit": "आधौ",
                    "english": "adhau",
                    "root": "आधौ",
                    "meaning": "in the beginning"
                },
                {
                    "sanskrit": "पाण्डव",
                    "english": "pandava",
                    "root": "पाण्डव",
                    "meaning": "of the Pandavas"
                },
                {
                    "sanskrit": "धार्तराष्ट्र",
                    "english": "dhartarashtra",
                    "root": "धार्तराष्ट्र",
                    "meaning": "of the sons of Dhritarashtra (Kauravas)"
                },
                {
                    "sanskrit": "जनानाम्",
                    "english": "jananam",
                    "root": "जन",
                    "meaning": "the people"
                },
                {
                    "sanskrit": "लाक्षागृहे",
                    "english": "lakshagrihe",
                    "root": "लाक्षा + गृह",
                    "meaning": "the house of lac"
                },
                {
                    "sanskrit": "दाहनं",
                    "english": "dahanam",
                    "root": "दाह",
                    "meaning": "burning"
                },
                {
                    "sanskrit": "द्यूतं",
                    "english": "dyutam",
                    "root": "द्यूत",
                    "meaning": "the game of dice"
                },
                {
                    "sanskrit": "श्रीहरणं",
                    "english": "sri-haranam",
                    "root": "श्री + हरण",
                    "meaning": "Draupadi’s humiliation (stealing of dignity)"
                },
                {
                    "sanskrit": "वने",
                    "english": "vane",
                    "root": "वन",
                    "meaning": "in the forest"
                },
                {
                    "sanskrit": "विहरणं",
                    "english": "viharanam",
                    "root": "विहर",
                    "meaning": "wandering"
                },
                {
                    "sanskrit": "मत्स्यालये",
                    "english": "matsyalaye",
                    "root": "मत्स्य + आलय",
                    "meaning": "in the Matsya kingdom"
                },
                {
                    "sanskrit": "वर्तनं",
                    "english": "vartanam",
                    "root": "वर्त",
                    "meaning": "living"
                },
                {
                    "sanskrit": "लीलागोग्रहणं",
                    "english": "leela-go-grahanam",
                    "root": "लीला + गो + ग्रहण",
                    "meaning": "the playful capturing of cows"
                },
                {
                    "sanskrit": "रणे",
                    "english": "rane",
                    "root": "रण",
                    "meaning": "in the battle"
                },
                {
                    "sanskrit": "विहरणं",
                    "english": "viharanam",
                    "root": "विहर",
                    "meaning": "moving about (battling)"
                },
                {
                    "sanskrit": "सन्धिक्रियाजृम्भणं",
                    "english": "sandhi kriya jrumbhanam",
                    "root": "सन्धि + क्रिया + जृम्भण",
                    "meaning": "breaking of peace negotiations"
                },
                {
                    "sanskrit": "पश्चात्",
                    "english": "pashchat",
                    "root": "पश्चात्",
                    "meaning": "afterward"
                },
                {
                    "sanskrit": "भीष्मद्रोण",
                    "english": "bhishma-drona",
                    "root": "भीष्म + द्रोण",
                    "meaning": "Bhishma and Drona"
                },
                {
                    "sanskrit": "दुर्योधनादि",
                    "english": "duryodhanadi",
                    "root": "दुर्योधन + आदि",
                    "meaning": "Duryodhana and others"
                },
                {
                    "sanskrit": "निधानं",
                    "english": "nidhanam",
                    "root": "निधन",
                    "meaning": "death"
                },
                {
                    "sanskrit": "एतत्",
                    "english": "etat",
                    "root": "एतत्",
                    "meaning": "this"
                },
                {
                    "sanskrit": "महाभारतम्",
                    "english": "mahabharatam",
                    "root": "महाभारत",
                    "meaning": "Mahabharata"
                }
            ]
        }
    ]
}
]

const SearchBar = ({ onSearch }) => (
  <div className="relative mb-4">
    <input
      type="text"
      placeholder="Search shlokas..."
      className="w-full p-2 pl-10 pr-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      onChange={(e) => onSearch(e.target.value)}
    />
    <Search
      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
      size={20}
    />
  </div>
);

const ShlokaCard = ({ shloka, onClick }) => (
  <div
    className="bg-white shadow-md rounded-lg mb-4 p-4 cursor-pointer hover:bg-gray-50 transition duration-150 ease-in-out"
    onClick={() => onClick(shloka)}
  >
    <h2 className="text-lg font-semibold text-gray-800">{shloka.title}</h2>
    <p className="text-sm text-gray-600">{shloka.verses.length} {shloka.verses.length > 1 ? "verses" : "verse"}</p>
  </div>
);

const WordByWordTable = ({ words }) => (
  <div className="overflow-x-auto mt-4">
    <table className="min-w-full bg-white border border-gray-300">
      <thead>
        <tr>
          <th className="px-4 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider border-b">
            Sanskrit
          </th>
          <th className="px-4 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider border-b">
            Root
          </th>
          <th className="px-4 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider border-b">
            English
          </th>
          <th className="px-4 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider border-b">
            Meaning
          </th>
        </tr>
      </thead>
      <tbody>
        {words.map((word, index) => (
          <tr
            key={index}
            className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
          >
            <td className="px-4 py-2 text-sm border-b">{word.sanskrit}</td>
            <td className="px-4 py-2 text-sm border-b">{word.root}</td>
            <td className="px-4 py-2 text-sm border-b">{word.english}</td>
            <td className="px-4 py-2 text-sm border-b">{word.meaning}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const VerseAccordion = ({ verse, shlokaSlug, versesCount }) => {
  const [isOpen, setIsOpen] = useState(false);

  const shareUrl = `${window.location.origin}/shloka/${shlokaSlug}/${verse.slug}`;

  const handleShare = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert("Shareable link copied to clipboard!");
    });
  };

  return (
    <div className="border-b border-gray-200 py-4">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-medium">
          Verse {verse.number}: {verse.title}
        </h3>
        <div className="flex items-center">
          <button
            onClick={handleShare}
            className="mr-2 p-1 rounded-full hover:bg-gray-200 transition duration-150 ease-in-out"
          >
            <Share2 size={20} />
          </button>
          {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </div>
      </div>
      {isOpen && (
        <div className="mt-4">
          <div className="text-gray-700 mb-2">
            <strong>Sholka:</strong> <p>{verse.text.split("।").map(t => (<p>{t} ।</p>))}</p>
          </div>
          <p className="text-gray-700 mb-2">
            <strong>Meaning:</strong> {verse.meaning}
          </p>
          <p className="text-gray-700 mb-4">
            <strong>Explanation:</strong> {verse.explanation}
          </p>
          <h4 className="font-semibold text-gray-800 mb-2">
            Word-by-Word Meaning:
          </h4>
          <WordByWordTable words={verse.wordByWord} />
        </div>
      )}
    </div>
  );
};

const ShlokaDetail = ({ shloka, onBack }) => (
  <div className="bg-white shadow-md rounded-lg p-4">
    <button
      onClick={onBack}
      className="mb-4 text-blue-500 hover:text-blue-700 transition duration-150 ease-in-out"
    >
      ← Back to list
    </button>
    <h2 className="text-2xl font-bold mb-4">{shloka.title}</h2>
    {shloka.verses.map((verse) => (
      <VerseAccordion
        versesCount={shloka.verses.length}
        key={verse.number}
        verse={verse}
        shlokaSlug={shloka.slug}
      />
    ))}
  </div>
);

const Footer = () => (
  <footer className="text-gray py-4 mt-8">
    <div className="container mx-auto text-center">
      <p>© 2024 Shloka Artha. All rights reserved.</p>
      <p>Made with ❤️ <a className="text-blue-800 hover:text-blue-100 transition duration-150 ease-in-out" href="https://h3manth.com">Hemanth HM</a></p>
      <a
        href="https://forms.gle/Jqm4H4eetkwoHUsp9"
        className="text-blue-300 hover:text-blue-100 transition duration-150 ease-in-out"
      >
        Request a Shloka
      </a>
    </div>
  </footer>
);

const App = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedShloka, setSelectedShloka] = useState<any | null>(null);
  const [selectedVerse, setSelectedVerse] = useState<any | null>(null);

  useEffect(() => {
    const path = window.location.pathname;
    const parts = path.split("/").filter(Boolean);

    if (parts[0] === "shloka" && parts.length > 1) {
      const shlokaSlug = parts[1];
      const verseSlug = parts[2];

      const shloka = shlokas.find((s) => s.slug === shlokaSlug);
      if (shloka) {
        setSelectedShloka(shloka);
        if (verseSlug) {
          const verse = shloka.verses.find((v) => v.slug === verseSlug);
          if (verse) {
            setSelectedVerse(verse);
          }
        }
      }
    }
  }, []);

  const filteredShlokas = shlokas.filter((shloka) =>
    shloka.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleShlokaSelect = (shloka) => {
    setSelectedShloka(shloka);
    setSelectedVerse(null);
    window.history.pushState(null, "", `/shloka/${shloka.slug}`);
  };

  const handleBack = () => {
    setSelectedShloka(null);
    setSelectedVerse(null);
    window.history.pushState(null, "", "/");
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <div className="flex-grow py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">
            Shloka Artha
          </h1>
          {!selectedShloka ? (
            <>
              <SearchBar onSearch={setSearchTerm} />
              {filteredShlokas.map((shloka) => (
                <ShlokaCard
                  key={shloka.id}
                  shloka={shloka}
                  onClick={handleShlokaSelect}
                />
              ))}
            </>
          ) : (
            <ShlokaDetail shloka={selectedShloka} onBack={handleBack} />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
